export const GOOGLE_CAPTCHA_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
export const USER_ROLES = {
  ADMIN: "admin",
  USER: "user",
};
export const RELEASE_NOTES_CATEGORIES = {
  FEATURE: "Feature",
  BUGFIX: "Bug ",
  IMPROVEMENT: "Improvement",
};
export const STATES = [
  {
    label: "Andaman and Nicobar Islands",
    key: "Andaman and Nicobar Islands",
  },
  { label: "Andhra Pradesh", key: "Andhra Pradesh" },
  { label: "Arunachal Pradesh", key: "Arunachal Pradesh" },
  { label: "Assam", key: "Assam" },
  { label: "Bihar", key: "Bihar" },
  { label: "Chandigarh", key: "Chandigarh" },
  { label: "Chhattisgarh", key: "Chhattisgarh" },
  {
    label: "Dadra and Nagar Haveli and Daman and Diu",
    key: "Dadra and Nagar Haveli and Daman and Diu",
  },
  { label: "Delhi", key: "Delhi" },
  { label: "Goa", key: "Goa" },
  { label: "Gujarat", key: "Gujarat" },
  { label: "Haryana", key: "Haryana" },
  { label: "Himachal Pradesh", key: "Himachal Pradesh" },
  { label: "Jammu and Kashmir", key: "Jammu and Kashmir" },
  { label: "Jharkhand", key: "Jharkhand" },
  { label: "Karnataka", key: "Karnataka" },
  { label: "Kerala", key: "Kerala" },
  { label: "Ladakh", key: "Ladakh" },
  { label: "Lakshadweep", key: "Lakshadweep" },
  { label: "Madhya Pradesh", key: "Madhya Pradesh" },
  { label: "Maharashtra", key: "Maharashtra" },
  { label: "Manipur", key: "Manipur" },
  { label: "Meghalaya", key: "Meghalaya" },
  { label: "Mizoram", key: "Mizoram" },
  { label: "Nagaland", key: "Nagaland" },
  { label: "Odisha", key: "Odisha" },
  { label: "Puducherry", key: "Puducherry" },
  { label: "Punjab", key: "Punjab" },
  { label: "Rajasthan", key: "Rajasthan" },
  { label: "Sikkim", key: "Sikkim" },
  { label: "Tamil Nadu", key: "Tamil Nadu" },
  { label: "Telangana", key: "Telangana" },
  { label: "Tripura", key: "Tripura" },
  { label: "Uttar Pradesh", key: "Uttar Pradesh" },
  { label: "Uttarakhand", key: "Uttarakhand" },
  { label: "West Bengal", key: "West Bengal" },
];
