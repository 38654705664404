import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import SideContent from "../info-for-shareholder/side-content";
import SPARE_PART_IMG from "../../resources/simpsons_parts2.jpg";
import Footer from "../../component/footer";
const SpareParts = () => {
  return (
    <div>
      <div className="shareholder-info-con">
        <Grid container spacing={7}>
          <Grid item md={8} sx={{ gridGap: 10 }}>
            <Typography
              variant="h4"
              sx={{
                borderBottom: "1px solid #ded8d7",
                paddingBottom: 2,

                mb: 3,
              }}
              className="primary-text"
            >
              Engine Parts
            </Typography>
            <Typography variant="body1">
              Simpsons engine parts enjoys a very strong brand image and is the
              preferred choice of majority of manufacturers of tractors, combine
              harvesters, material handling and construction equipment and
              gensets.
            </Typography>
            <Typography variant="body1" sx={{ mt: 3 }}>
              The Simpsons range of engine parts is diverse and covers cylinder
              heads, crankshafts, cylinder blocks, connecting rod assembly,
              water pump and lube oil pump assembly, cylinder liners, rocker
              assembly, pulley, inlet & exhaust valves.
            </Typography>
            <Box sx={{ mt: 5 }}>
              <img src={SPARE_PART_IMG} alt="spare-parts" width="100%" />
            </Box>
            <Card sx={{ mt: 5 }}>
              <CardContent>
                <Grid container>
                  <Grid
                    item
                    md={6}
                    sx={{
                      borderRight: { md: "1px solid lightGray", xs: "none" },
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Engine Parts - Contact Details
                    </Typography>
                    <Typography variant="body2">
                      Merchandising Division, Simpson & Co Ltd.,
                    </Typography>
                    <Typography variant="body2">861/862 Anna Salai,</Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Chennai 600 002
                    </Typography>
                    <Typography variant="body2">Tel: 044-28577246</Typography>
                    <Typography variant="body2">
                      E.mail: sraghothaman@simpson.co.in
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2" className="file-link">
                      Branches and RO Contacts
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={2}>
            <SideContent />
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default SpareParts;
