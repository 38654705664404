export const ENGINE_LIST = [
  {
    label: "SJV436",
    value: "engine1",
    listNo: [
      {
        label: "7260",
        value: "7260"
      }
    ]
  },
  {
    label: "SJV3259",
    value: "engine2",
    listNo: [
      {
        label: "4221",
        value: "4221"
      }
    ]
  }
];

export const ACTIVE_BUTTON_CONFIG = {
  "Select Engine": [
    {
      label: "Engine 1",
      info: "Engine 1 Description"
    },
    {
      label: "Engine 2",
      info: "Engine 2 Description"
    }
  ],
  "List No.": []
};

export const showClickedDetails = (objectName, list) => {
  if (list?.length > 0) {
    return list.find(ob => ob.objectName === objectName) || {};
  }
  return {};
};

export const findInChildParts = (childList, selectParts) => {
  if (selectParts && selectParts.objectName && selectParts.parent) {
    const found = childList.find(p => p.partNo === selectParts.objectName);
    if (found) {
      return found;
    } else if (selectParts.parent) {
      const parentsParts = selectParts.parent.split(".");
      const newFound = childList.find(
        p => p.partNo?.replace(/^0+/, "") === parentsParts[0]?.replace(/^0+/, "")
      );
      if (newFound) {
        return newFound;
      } else {
        const _Parts = selectParts.parent.split("_");
        const _Found = childList.find(
          p => p.partNo?.replace(/^0+/, "") === _Parts[0]?.replace(/^0+/, "")
        );
        if (_Found) {
          return _Found;
        }
      }
    }
  }
};
