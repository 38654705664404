// Sample list of objects
export const MAIN_PART_LIST = [
  {
    sno: "1",
    label: "Cylinder Block Assembly",
    id: "CRSJ4CBK03",
  },
  {
    sno: "2",
    label: "Crank shaft",
    id: "CRSJ4CRS01",
  },
  {
    sno: "3",
    label: "Lube Oil Pump",
    id: "CRSJ4LOP01",
  },
  {
    sno: "4",
    label: "Timing Case",
    id: "CRSJ4TMC02",
  },
  {
    sno: "5",
    label: "Idler Gear",
    id: "CRSJ4TGR01",
  },
  {
    sno: "6",
    label: "Rear End Oil Seal",
    id: "CRSJ4ROS01",
  },
  {
    sno: "7",
    label: "Water Inlet Connection",
    id: "CRSJ4WIC01",
  },
  {
    sno: "8",
    label: "Connecting Rod Assembly",
    id: "CRSJ4CRD01",
  },
  {
    sno: "9",
    label: "Engine Piston",
    id: "CRSJ4PSN01",
  },
  {
    sno: "10",
    label: "Lube Oil Pan",
    id: "CRSJ4OPN01",
  },
  {
    sno: "11",
    label: "Cam Shaft",
    id: "CRSJ4CAM01",
  },
  {
    sno: "12",
    label: "Fuel Injection Pump",
    id: "CRSJ4FIP01",
  },
  {
    sno: "13",
    label: "Cylinder Head",
    id: "CRSJ4CHD02",
  },
  {
    sno: "14",
    label: "Engine Lifting",
    id: "CRSJ4LHK01",
  },
  {
    sno: "15",
    label: "Valve Mechanism",
    id: "CRSJ4VTR01",
  },
  {
    sno: "16",
    label: "Exhaust Manifold",
    id: "CRSJ4EMF01",
  },
  {
    sno: "17",
    label: "Water Outlet Body",
    id: "CRSJ4WOB01",
  },
  {
    sno: "18",
    label: "Valve Cover",
    id: "CRSJ4CHC02",
  },
  {
    sno: "19",
    label: "Timing Case Cover",
    id: "CRSJ4TCC02",
  },
  {
    sno: "20",
    label: "Crankshaft Pulley",
    id: "CRSJ4CRP01",
  },
  {
    sno: "21",
    label: "Water Pump",
    id: "CRSJ4WTP03",
  },
  {
    sno: "22",
    label: "Water Outlet Connection",
    id: "CRSJ4WOC01",
  },
  {
    sno: "23",
    label: "Lube Oil Filler",
    id: "CRSJ4LOF03",
  },
  {
    sno: "24",
    label: "Air Intake Manifold",
    id: "CRSJ4IMF03",
  },
  {
    sno: "25",
    label: "Injector Assembly",
    id: "CRSJ4INJ01",
  },
  {
    sno: "26",
    label: "High Pressure Fuel Pipe",
    id: "CRSJ4HPP01",
  },
  {
    sno: "27",
    label: "Low Pressure Circuit",
    id: "CRSJ4LPC02",
  },
  {
    sno: "28",
    label: "Lube oil Filter",
    id: "CRSJ4LFT06",
  },
  {
    sno: "29",
    label: "Breather Engine",
    id: "CRSJ4BRT01",
  },
  {
    sno: "30",
    label: "Turbo Charger",
    id: "CRSJ4TUR04",
  },
  {
    sno: "31",
    label: "Electrical Parts",
    id: "CRSJ4ELT02",
  },
  {
    sno: "32",
    label: "Mounting Bracket",
    id: "CRSJ4MBT01",
  },
  {
    sno: "33",
    label: "Engine Backends",
    id: "CRSJ4BEN03",
  },
  {
    sno: "34",
    label: "EGR",
    id: "CRSJ4EGR01",
  },
  {
    sno: "35",
    label: "Air Compressor",
    id: "CRSJ4ACP01",
  },
  {
    sno: "36",
    label: "Name Plate",
    id: "CRSJ4NAM03",
  },
  {
    sno: "37",
    label: "Lube Oil Dipstick",
    id: "CRSJ4DIP01",
  },
  {
    sno: "38",
    label: "Fan Extension",
    id: "CRSJ4EXT03",
  },
  {
    sno: "39",
    label: "Sensors & ECU Unit Group",
    id: "CRSJ4SER01",
  },
];

export const CHILD_PART_LIST = [
  {
    sno: "1",
    partNo: "350016",
    label: "DOWEL",
    qty: "2",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "2",
    partNo: "650566",
    label: "PLUG",
    qty: "1",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "3",
    partNo: "650710",
    label: "PLUG",
    qty: "5",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "4",
    partNo: "62SG3001",
    label: "PIPE_LUB_OIL_SEPERATOR",
    qty: "1",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "5",
    partNo: "62SG3022",
    label: "CONNECTION_WATERLINE",
    qty: "2",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "6",
    partNo: "65DE0036",
    label: "PLUG",
    qty: "1",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "7",
    partNo: "65SG3001",
    label: "PLUG",
    qty: "1",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "8",
    partNo: "65SG3009",
    label: "PLUG_COOLANT_CONNECTION",
    qty: "2",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "9",
    partNo: "74DE0220",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "10",
    partNo: "74SG3029",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "11",
    partNo: "92DE0009",
    label: "WASHER",
    qty: "2",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "12",
    partNo: "99SG4660",
    label: "BLOCK_CYLINDER_ASSEMBLY",
    qty: "1",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "13",
    partNo: "DE006124",
    label: "CAM/PHASE_SENSOR",
    qty: "1",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "14",
    partNo: "DE006804",
    label: "COMBINED_OIL_PRESSURE_TEMPERTAURE_SENSOR",
    qty: "1",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "1",
    partNo: "650710_1",
    label: "PLUG",
    qty: "8",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "2",
    partNo: "870001",
    label: "THIMBLE",
    qty: "10",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "3",
    partNo: "15SG453S",
    label: "CAP_BEARING_FRONT_SEMIFINISHED",
    qty: "1",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "4",
    partNo: "15SG454S",
    label: "CAP_BEARING_REAR_SEMIFINISHED",
    qty: "1",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "5",
    partNo: "15SG455S",
    label: "CAP_BEARING_INTERMEDIATE_SEMI_FINISHED",
    qty: "2",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "6",
    partNo: "15SG456S",
    label: "CAP_BEARING_MIDDLE_SEMI_FINISHED",
    qty: "1",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "7",
    partNo: "28SG4071",
    label: "CYLINDER_BLOCK_INDUSTRIAL",
    qty: "1",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "8",
    partNo: "53SG3020",
    label: "CYLINDER_LINER_PRODUCTION_PART_FINISHED",
    qty: "4",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "9",
    partNo: "S4007428",
    label: "BOLT",
    qty: "8",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "10",
    partNo: "S4007429",
    label: "BOLT",
    qty: "2",
    parent: "CRSJ4CBK03",
  },
  {
    sno: "1",
    partNo: "500168",
    label: "KEY_CRANKSHAFT",
    qty: "1",
    parent: "CRSJ4CRS01",
  },
  {
    sno: "2",
    partNo: "2415608",
    label: "O_RING",
    qty: "2",
    parent: "CRSJ4CRS01",
  },
  {
    sno: "3",
    partNo: "31121261",
    label: "BEARING_MAIN",
    qty: "4",
    parent: "CRSJ4CRS01",
  },
  {
    sno: "4",
    partNo: "31122411",
    label: "BEARING_MAIN",
    qty: "1",
    parent: "CRSJ4CRS01",
  },
  {
    sno: "5",
    partNo: "31122421",
    label: "BEARING_MAIN",
    qty: "1",
    parent: "CRSJ4CRS01",
  },
  {
    sno: "6",
    partNo: "31123331",
    label: "BEARING_MAIN",
    qty: "4",
    parent: "CRSJ4CRS01",
  },
  {
    sno: "7",
    partNo: "31137211",
    label: "WASHER_THRUST",
    qty: "2",
    parent: "CRSJ4CRS01",
  },
  {
    sno: "8",
    partNo: "31137221",
    label: "WASHER_THRUST",
    qty: "2",
    parent: "CRSJ4CRS01",
  },
  {
    sno: "9",
    partNo: "26SG4012",
    label: "CRANKSHAFT_SERRATED",
    qty: "1",
    parent: "CRSJ4CRS01",
  },
  {
    sno: "10",
    partNo: "S4004101",
    label: "GEAR_CRANK_SHAFT",
    qty: "1",
    parent: "CRSJ4CRS01",
  },
  {
    sno: "1",
    partNo: "180831",
    label: "CIRCLIP",
    qty: "1",
    parent: "CRSJ4LOP01",
  },
  {
    sno: "2",
    partNo: "74SG3079",
    label: "SETSCREW",
    qty: "3",
    parent: "CRSJ4LOP01",
  },
  {
    sno: "3",
    partNo: "99SG4100",
    label: "PUMP_OIL_LUBRICATING_ASSEMBLY",
    qty: "1",
    parent: "CRSJ4LOP01",
  },
  {
    sno: "1",
    partNo: "350016_1",
    label: "DOWEL",
    qty: "2",
    parent: "CRSJ4TMC02",
  },
  {
    sno: "2",
    partNo: "920299",
    label: "WASHER",
    qty: "1",
    parent: "CRSJ4TMC02",
  },
  {
    sno: "3",
    partNo: "2316T050",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ4TMC02",
  },
  {
    sno: "4",
    partNo: "24S30111",
    label: "BRIDGE_PIECE",
    qty: "1",
    parent: "CRSJ4TMC02",
  },
  {
    sno: "5",
    partNo: "44SG4085",
    label: "TIMING_CASE_FOR _KION_WITH_AIR_COMPRESSOR_MOUNTING",
    qty: "1",
    parent: "CRSJ4TMC02",
  },
  {
    sno: "6",
    partNo: "49SG3073",
    label: "JOINT_TIMING_CASE",
    qty: "1",
    parent: "CRSJ4TMC02",
  },
  {
    sno: "7",
    partNo: "74S30121",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ4TMC02",
  },
  {
    sno: "8",
    partNo: "74SG3071",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ4TMC02",
  },
  {
    sno: "9",
    partNo: "74SG3073",
    label: "SETSCREW",
    qty: "3",
    parent: "CRSJ4TMC02",
  },
  {
    sno: "10",
    partNo: "74SG3074",
    label: "SETSCREW",
    qty: "7",
    parent: "CRSJ4TMC02",
  },
  {
    sno: "1",
    partNo: "92DE0003",
    label: "WASHER",
    qty: "2",
    parent: "CRSJ4TGR01",
  },
  {
    sno: "2",
    partNo: "S4000901",
    label: "BOLT_BANJO",
    qty: "1",
    parent: "CRSJ4TGR01",
  },
  {
    sno: "3",
    partNo: "S4004126",
    label: "GEAR_IDLER",
    qty: "1",
    parent: "CRSJ4TGR01",
  },
  {
    sno: "4",
    partNo: "S4004501",
    label: "HUB_IDLER_GEAR",
    qty: "1",
    parent: "CRSJ4TGR01",
  },
  {
    sno: "5",
    partNo: "S4005703",
    label: "NUT",
    qty: "6",
    parent: "CRSJ4TGR01",
  },
  {
    sno: "6",
    partNo: "S4008206",
    label: "STUD",
    qty: "6",
    parent: "CRSJ4TGR01",
  },
  {
    sno: "7",
    partNo: "S4009201",
    label: "WASHER_TAB",
    qty: "2",
    parent: "CRSJ4TGR01",
  },
  {
    sno: "8",
    partNo: "S4009401",
    label: "PLATE_IDLER_GEAR",
    qty: "1",
    parent: "CRSJ4TGR01",
  },
  {
    sno: "9",
    partNo: "S4009404",
    label: "PLATE_IDLER_GEAR",
    qty: "1",
    parent: "CRSJ4TGR01",
  },
  {
    sno: "10",
    partNo: "S4009906",
    label: "PIPE",
    qty: "1",
    parent: "CRSJ4TGR01",
  },
  {
    sno: "11",
    partNo: "S4009937",
    label: "GEAR_IDLER",
    qty: "1",
    parent: "CRSJ4TGR01",
  },
  {
    sno: "12",
    partNo: "SK004015",
    label: "HUB_IDLER_GEAR",
    qty: "1",
    parent: "CRSJ4TGR01",
  },
  {
    sno: "1",
    partNo: "37424611",
    label: "HOUSING_REAR_END_OIL_SEAL_ASSEMBLY",
    qty: "1",
    parent: "CRSJ4ROS01",
  },
  {
    sno: "2",
    partNo: "49S30190",
    label: "JOINT_REAR_END_OIL_SEAL",
    qty: "1",
    parent: "CRSJ4ROS01",
  },
  {
    sno: "3",
    partNo: "73DE0036",
    label: "SEAL_REAR_END_HOUSING",
    qty: "1",
    parent: "CRSJ4ROS01",
  },
  {
    sno: "4",
    partNo: "74SG3074_1",
    label: "SETSCREW",
    qty: "9",
    parent: "CRSJ4ROS01",
  },
  {
    sno: "1",
    partNo: "20SG3014",
    label: "CONNECTION_WATER_INLET",
    qty: "1",
    parent: "CRSJ4WIC01",
  },
  {
    sno: "2",
    partNo: "49SG3074",
    label: "JOINT_WATER_INLET_CONNECTION",
    qty: "1",
    parent: "CRSJ4WIC01",
  },
  {
    sno: "3",
    partNo: "74SG3074_2",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ4WIC01",
  },
  {
    sno: "4",
    partNo: "74SG3076",
    label: "BOLT",
    qty: "1",
    parent: "CRSJ4WIC01",
  },
  {
    sno: "1",
    partNo: "04SG3004",
    label: "CONNECTING_ROD_BEARING_UPPER",
    qty: "4",
    parent: "CRSJ4CRD01",
  },
  {
    sno: "2",
    partNo: "04SG3005",
    label: "CONNECTING_ROD_BEARING_LOWER",
    qty: "4",
    parent: "CRSJ4CRD01",
  },
  {
    sno: "3",
    partNo: "99SG3239",
    label: "CON ROD ASSEMBLY",
    qty: "4",
    parent: "CRSJ4CRD01",
  },
  {
    sno: "4",
    partNo: "SG990265",
    label: "CONNECTING ROD BEARING KIT",
    qty: "1",
    parent: "CRSJ4CRD01",
  },
  {
    sno: "1",
    partNo: "17SG3008",
    label: "CIRCLIP_GUDGEON_PIN",
    qty: "8",
    parent: "CRSJ4PSN01",
  },
  {
    sno: "2",
    partNo: "61SG3002",
    label: "GUDGEON_PIN",
    qty: "4",
    parent: "CRSJ4PSN01",
  },
  {
    sno: "3",
    partNo: "63SG4032",
    label: "PISTON_HIGH_GRADE",
    qty: "4",
    parent: "CRSJ4PSN01",
  },
  {
    sno: "4",
    partNo: "64SG3015",
    label: "RING_PISTON_COMPRESSION",
    qty: "4",
    parent: "CRSJ4PSN01",
  },
  {
    sno: "5",
    partNo: "64SG3016",
    label: "RING_PISTON_CONFORMABLE_OIL",
    qty: "4",
    parent: "CRSJ4PSN01",
  },
  {
    sno: "6",
    partNo: "64SG4017",
    label: "TOP_COMPRESSION_RING",
    qty: "4",
    parent: "CRSJ4PSN01",
  },
  {
    sno: "7",
    partNo: "SG990270",
    label: "PISTON RINGS KIT",
    qty: "4",
    parent: "CRSJ4PSN01",
  },
  {
    sno: "8",
    partNo: "SG990271",
    label: "PISTON ASSEMBLY KIT",
    qty: "4",
    parent: "CRSJ4PSN01",
  },
  {
    sno: "1",
    partNo: "490016",
    label: "JOINT",
    qty: "1",
    parent: "CRSJ4OPN01",
  },
  {
    sno: "2",
    partNo: "560244",
    label: "OLIVE",
    qty: "2",
    parent: "CRSJ4OPN01",
  },
  {
    sno: "3",
    partNo: "920798",
    label: "WASHER_TAB",
    qty: "2",
    parent: "CRSJ4OPN01",
  },
  {
    sno: "4",
    partNo: "49SG4181",
    label: "INTEGRAL_SUMP_JOINT",
    qty: "1",
    parent: "CRSJ4OPN01",
  },
  {
    sno: "5",
    partNo: "57SG3013",
    label: "NUT",
    qty: "5",
    parent: "CRSJ4OPN01",
  },
  {
    sno: "6",
    partNo: "65DE0051",
    label: "PLUG_LUB_OIL_PAN_DRAIN",
    qty: "1",
    parent: "CRSJ4OPN01",
  },
  {
    sno: "7",
    partNo: "73S30025",
    label: "O_RING",
    qty: "1",
    parent: "CRSJ4OPN01",
  },
  {
    sno: "8",
    partNo: "74SG3071_1",
    label: "SETSCREW",
    qty: "6",
    parent: "CRSJ4OPN01",
  },
  {
    sno: "9",
    partNo: "74SG3072",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ4OPN01",
  },
  {
    sno: "10",
    partNo: "74SG3079_1",
    label: "SETSCREW",
    qty: "4",
    parent: "CRSJ4OPN01",
  },
  {
    sno: "11",
    partNo: "74SG3081",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ4OPN01",
  },
  {
    sno: "12",
    partNo: "83SG4028",
    label: "PAN_LUB_OIL",
    qty: "1",
    parent: "CRSJ4OPN01",
  },
  {
    sno: "13",
    partNo: "99SG4186",
    label: "PIPE_LUB_OIL_SUCTION",
    qty: "1",
    parent: "CRSJ4OPN01",
  },
  {
    sno: "14",
    partNo: "S4005711",
    label: "NUT",
    qty: "2",
    parent: "CRSJ4OPN01",
  },
  {
    sno: "15",
    partNo: "S4006202",
    label: "PIPE_LUB_OIL_DELIVERY",
    qty: "1",
    parent: "CRSJ4OPN01",
  },
  {
    sno: "16",
    partNo: "S4007403",
    label: "BOLT",
    qty: "2",
    parent: "CRSJ4OPN01",
  },
  {
    sno: "17",
    partNo: "S4008243",
    label: "STUD",
    qty: "5",
    parent: "CRSJ4OPN01",
  },
  {
    sno: "1",
    partNo: "31SG3001",
    label: "WASHER_THRUST",
    qty: "1",
    parent: "CRSJ4CAM01",
  },
  {
    sno: "2",
    partNo: "41SG3001",
    label: "GEAR_CAMSHAFT",
    qty: "1",
    parent: "CRSJ4CAM01",
  },
  {
    sno: "3",
    partNo: "49S30199",
    label: "JOINT_TACHO_DRIVE",
    qty: "1",
    parent: "CRSJ4CAM01",
  },
  {
    sno: "4",
    partNo: "74SG3074_3",
    label: "SETSCREW",
    qty: "3",
    parent: "CRSJ4CAM01",
  },
  {
    sno: "5",
    partNo: "74SG3082",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ4CAM01",
  },
  {
    sno: "6",
    partNo: "74SG3087",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ4CAM01",
  },
  {
    sno: "7",
    partNo: "94S30406",
    label: "PLATE_TACHO_DRIVE_BLANKING",
    qty: "1",
    parent: "CRSJ4CAM01",
  },
  {
    sno: "8",
    partNo: "SG990041",
    label: "CAMSHAFT_ASSEMBLY",
    qty: "1",
    parent: "CRSJ4CAM01",
  },
  {
    sno: "1",
    partNo: "09DE0006",
    label: "BOLT_BANJO",
    qty: "1",
    parent: "CRSJ4FIP01",
  },
  {
    sno: "2",
    partNo: "41SG4034",
    label: "GEAR_FUEL_INJECTION_PUMP",
    qty: "1",
    parent: "CRSJ4FIP01",
  },
  {
    sno: "3",
    partNo: "57SG3013_1",
    label: "NUT",
    qty: "5",
    parent: "CRSJ4FIP01",
  },
  {
    sno: "4",
    partNo: "82SG3017",
    label: "SPECIAL_STUD_EXHAUST_TO_TURBO",
    qty: "3",
    parent: "CRSJ4FIP01",
  },
  {
    sno: "5",
    partNo: "92DE0009_1",
    label: "WASHER",
    qty: "2",
    parent: "CRSJ4FIP01",
  },
  {
    sno: "6",
    partNo: "99SG4844",
    label: "PIPE_ASSY_BLOCK_TO_FIP_OIL_FEED",
    qty: "1",
    parent: "CRSJ4FIP01",
  },
  {
    sno: "7",
    partNo: "DE006115",
    label: "CB_18_PUMP",
    qty: "1",
    parent: "CRSJ4FIP01",
  },
  {
    sno: "8",
    partNo: "SG990038",
    label: "COMMON_FUEL_RETURN_LINE_ASSEMBLY",
    qty: "1",
    parent: "CRSJ4FIP01",
  },
  {
    sno: "7A",
    partNo: "DE006481",
    label: "MPROP SENSOR",
    qty: "1",
    parent: "CRSJ4FIP01",
  },
  {
    sno: "1",
    partNo: "2114A046",
    label: "DOWEL",
    qty: "2",
    parent: "CRSJ4CHD02",
  },
  {
    sno: "2",
    partNo: "29SG4021",
    label: "CYLINDER_HEAD",
    qty: "1",
    parent: "CRSJ4CHD02",
  },
  {
    sno: "3",
    partNo: "49SG4151",
    label: "CYLINDER HEAD GASKET_MLS",
    qty: "1",
    parent: "CRSJ4CHD02",
  },
  {
    sno: "4",
    partNo: "74SG3001",
    label: "BOLT",
    qty: "10",
    parent: "CRSJ4CHD02",
  },
  {
    sno: "5",
    partNo: "74SG3002",
    label: "BOLT",
    qty: "5",
    parent: "CRSJ4CHD02",
  },
  {
    sno: "1",
    partNo: "10SG4152",
    label: "LIFTING_HOOK_REAR",
    qty: "1",
    parent: "CRSJ4LHK01",
  },
  {
    sno: "2",
    partNo: "57DE0103",
    label: "NUT",
    qty: "4",
    parent: "CRSJ4LHK01",
  },
  {
    sno: "3",
    partNo: "94SG3039",
    label: "HOOK_ENGINE_LIFTING",
    qty: "1",
    parent: "CRSJ4LHK01",
  },
  {
    sno: "4",
    partNo: "S4008209",
    label: "STUD",
    qty: "4",
    parent: "CRSJ4LHK01",
  },
  {
    sno: "1",
    partNo: "230001",
    label: "COTTER_VALVE_SPRING",
    qty: "16",
    parent: "CRSJ4VTR01",
  },
  {
    sno: "2",
    partNo: "33177518",
    label: "WASHER",
    qty: "8",
    parent: "CRSJ4VTR01",
  },
  {
    sno: "3",
    partNo: "33423148",
    label: "CAP_VALVE_SPRING",
    qty: "8",
    parent: "CRSJ4VTR01",
  },
  {
    sno: "4",
    partNo: "70SG3001",
    label: "ROD_PUSH",
    qty: "8",
    parent: "CRSJ4VTR01",
  },
  {
    sno: "5",
    partNo: "73SG3001",
    label: "SEAL_VALVE_STEM",
    qty: "8",
    parent: "CRSJ4VTR01",
  },
  {
    sno: "6",
    partNo: "74SG4068",
    label: "BOLT",
    qty: "10",
    parent: "CRSJ4VTR01",
  },
  {
    sno: "7",
    partNo: "78SG3001",
    label: "SPRING_VALVE",
    qty: "8",
    parent: "CRSJ4VTR01",
  },
  {
    sno: "8",
    partNo: "86SG3001",
    label: "TAPPET",
    qty: "8",
    parent: "CRSJ4VTR01",
  },
  {
    sno: "9",
    partNo: "91SG3003",
    label: "VALVE_AIR_INTAKE",
    qty: "4",
    parent: "CRSJ4VTR01",
  },
  {
    sno: "10",
    partNo: "91SG3004",
    label: "VALVE_EXHAUST",
    qty: "4",
    parent: "CRSJ4VTR01",
  },
  {
    sno: "11",
    partNo: "SG990044",
    label: "ROCKER_SHAFT_ASSEMBLY",
    qty: "1",
    parent: "CRSJ4VTR01",
  },
  {
    sno: "1",
    partNo: "2318A604",
    label: "NUT",
    qty: "8",
    parent: "CRSJ4EMF01",
  },
  {
    sno: "2",
    partNo: "33SG3012",
    label: "SPACER_EXHAUST_MANIFOLD",
    qty: "8",
    parent: "CRSJ4EMF01",
  },
  {
    sno: "3",
    partNo: "49SG4129",
    label: "JOINT_EXHAUST_MANIFOLD",
    qty: "1",
    parent: "CRSJ4EMF01",
  },
  {
    sno: "4",
    partNo: "54SG4055",
    label: "MANIFOLD_EXHAUST",
    qty: "1",
    parent: "CRSJ4EMF01",
  },
  {
    sno: "5",
    partNo: "82SG3008",
    label: "STUD",
    qty: "8",
    parent: "CRSJ4EMF01",
  },
  {
    sno: "1",
    partNo: "2411158",
    label: "WASHER",
    qty: "1",
    parent: "CRSJ4WOB01",
  },
  {
    sno: "2",
    partNo: "2431152",
    label: "PLUG",
    qty: "1",
    parent: "CRSJ4WOB01",
  },
  {
    sno: "3",
    partNo: "08SG3047",
    label: "BODY_WATER_OUTLET",
    qty: "1",
    parent: "CRSJ4WOB01",
  },
  {
    sno: "4",
    partNo: "20SG3069",
    label: "CONNECTION_WATER_BY_PASS",
    qty: "1",
    parent: "CRSJ4WOB01",
  },
  {
    sno: "5",
    partNo: "49SG3069",
    label: "JOINT",
    qty: "1",
    parent: "CRSJ4WOB01",
  },
  {
    sno: "6",
    partNo: "49SG3075",
    label: "JOINT_WATER_OUTLET_BODY",
    qty: "1",
    parent: "CRSJ4WOB01",
  },
  {
    sno: "7",
    partNo: "62SG3022_2",
    label: "CONNECTION_WATERLINE",
    qty: "1",
    parent: "CRSJ4WOB01",
  },
  {
    sno: "8",
    partNo: "65DE0005",
    label: "PLUG",
    qty: "1",
    parent: "CRSJ4WOB01",
  },
  {
    sno: "9",
    partNo: "74SG3074_4",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ4WOB01",
  },
  {
    sno: "10",
    partNo: "74SG3077",
    label: "BOLT",
    qty: "1",
    parent: "CRSJ4WOB01",
  },
  {
    sno: "11",
    partNo: "74SG3091",
    label: "BOLT",
    qty: "1",
    parent: "CRSJ4WOB01",
  },
  {
    sno: "12",
    partNo: "74SG3095",
    label: "BOLT",
    qty: "2",
    parent: "CRSJ4WOB01",
  },
  {
    sno: "13",
    partNo: "DE006051",
    label: "THERMOSTAT",
    qty: "1",
    parent: "CRSJ4WOB01",
  },
  {
    sno: "14",
    partNo: "DE006146",
    label: "TEMPREATURE_SENSOR",
    qty: "1",
    parent: "CRSJ4WOB01",
  },
  {
    sno: "1",
    partNo: "330940",
    label: "SPACER",
    qty: "5",
    parent: "CRSJ4CHC02",
  },
  {
    sno: "2",
    partNo: "49SG4025",
    label: "JOINT_VALVE_COVER",
    qty: "1",
    parent: "CRSJ4CHC02",
  },
  {
    sno: "3",
    partNo: "57SG4015",
    label: "NUT",
    qty: "10",
    parent: "CRSJ4CHC02",
  },
  {
    sno: "4",
    partNo: "73SG4010",
    label: "SEAL_VALVE_COVER",
    qty: "5",
    parent: "CRSJ4CHC02",
  },
  {
    sno: "5",
    partNo: "92SG4003",
    label: "WASHER",
    qty: "5",
    parent: "CRSJ4CHC02",
  },
  {
    sno: "6",
    partNo: "99SG4849",
    label: "CYLINDER_HEAD_COVER_ASSEMBLY",
    qty: "1",
    parent: "CRSJ4CHC02",
  },
  {
    sno: "1",
    partNo: "920492",
    label: "SPACER",
    qty: "1",
    parent: "CRSJ4TCC02",
  },
  {
    sno: "2",
    partNo: "49SG3044",
    label: "JOINT_LUB_OIL_FILLER",
    qty: "1",
    parent: "CRSJ4TCC02",
  },
  {
    sno: "3",
    partNo: "49SG3072",
    label: "JOINT_TIMING_CASE_COVER",
    qty: "1",
    parent: "CRSJ4TCC02",
  },
  {
    sno: "4",
    partNo: "57SG3013_2",
    label: "NUT",
    qty: "5",
    parent: "CRSJ4TCC02",
  },
  {
    sno: "5",
    partNo: "74SG3071_2",
    label: "SETSCREW",
    qty: "14",
    parent: "CRSJ4TCC02",
  },
  {
    sno: "6",
    partNo: "74SG3075",
    label: "BOLT",
    qty: "1",
    parent: "CRSJ4TCC02",
  },
  {
    sno: "7",
    partNo: "74SG3077_1",
    label: "BOLT",
    qty: "1",
    parent: "CRSJ4TCC02",
  },
  {
    sno: "8",
    partNo: "74SG3081_1",
    label: "SETSCREW",
    qty: "6",
    parent: "CRSJ4TCC02",
  },
  {
    sno: "9",
    partNo: "82S30063",
    label: "STUD",
    qty: "1",
    parent: "CRSJ4TCC02",
  },
  {
    sno: "10",
    partNo: "82S30108",
    label: "STUD",
    qty: "3",
    parent: "CRSJ4TCC02",
  },
  {
    sno: "11",
    partNo: "94DE0385",
    label: "PLATE_LUB_OIL_FILLER_BLANKING",
    qty: "1",
    parent: "CRSJ4TCC02",
  },
  {
    sno: "12",
    partNo: "99SG4667",
    label: "COVER_TIMING_CASE_ASSEMBLY",
    qty: "1",
    parent: "CRSJ4TCC02",
  },
  {
    sno: "1",
    partNo: "33125112",
    label: "WASHER_CRANKSHAFT_PULLEY",
    qty: "1",
    parent: "CRSJ4CRP01",
  },
  {
    sno: "2",
    partNo: "33176137",
    label: "WASHER",
    qty: "1",
    parent: "CRSJ4CRP01",
  },
  {
    sno: "3",
    partNo: "67SG4020",
    label: "PULLEY_CRANKSHAFT",
    qty: "1",
    parent: "CRSJ4CRP01",
  },
  {
    sno: "4",
    partNo: "94SG3135",
    label: "POINTER_TIMING",
    qty: "1",
    parent: "CRSJ4CRP01",
  },
  {
    sno: "5",
    partNo: "S4007426",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ4CRP01",
  },
  {
    sno: "1",
    partNo: "20SG3070",
    label: "HOSE_BYPASS_WATER_CONNECTION",
    qty: "1",
    parent: "CRSJ4WTP03",
  },
  {
    sno: "2",
    partNo: "20SG4082",
    label: "HOSE",
    qty: "1",
    parent: "CRSJ4WTP03",
  },
  {
    sno: "3",
    partNo: "49S30196",
    label: "JOINT_WATER_PUMP_BACK_PLATE",
    qty: "1",
    parent: "CRSJ4WTP03",
  },
  {
    sno: "4",
    partNo: "57SG3013_3",
    label: "NUT",
    qty: "4",
    parent: "CRSJ4WTP03",
  },
  {
    sno: "5",
    partNo: "82S20071",
    label: "STUD",
    qty: "4",
    parent: "CRSJ4WTP03",
  },
  {
    sno: "6",
    partNo: "94S20189",
    label: "PLATE_WATER_PUMP_BACK",
    qty: "1",
    parent: "CRSJ4WTP03",
  },
  {
    sno: "7",
    partNo: "99SG4957",
    label: "WATERPUMP_ASSEMBLY",
    qty: "1",
    parent: "CRSJ4WTP03",
  },
  {
    sno: "8",
    partNo: "S4001800",
    label: "CLIP",
    qty: "2",
    parent: "CRSJ4WTP03",
  },
  {
    sno: "9",
    partNo: "S4001801",
    label: "CLIP",
    qty: "2",
    parent: "CRSJ4WTP03",
  },
  {
    sno: "1",
    partNo: "20SG4028",
    label: "CONNECTION_WATER_OUTLET",
    qty: "1",
    parent: "CRSJ4WOC01",
  },
  {
    sno: "2",
    partNo: "49S30197",
    label: "JOINT_WATER_OUTLET_CONNECTION",
    qty: "1",
    parent: "CRSJ4WOC01",
  },
  {
    sno: "3",
    partNo: "57DE0103_1",
    label: "NUT",
    qty: "2",
    parent: "CRSJ4WOC01",
  },
  {
    sno: "4",
    partNo: "82SG4004",
    label: "STUD",
    qty: "2",
    parent: "CRSJ4WOC01",
  },
  {
    sno: "5",
    partNo: "92DE0023",
    label: "WASHER",
    qty: "2",
    parent: "CRSJ4WOC01",
  },
  {
    sno: "1",
    partNo: "49S30267",
    label: "JOINT_LUB_OIL_FILLER",
    qty: "1",
    parent: "CRSJ4LOF03",
  },
  {
    sno: "2",
    partNo: "57SG3013_4",
    label: "NUT",
    qty: "2",
    parent: "CRSJ4LOF03",
  },
  {
    sno: "3",
    partNo: "82S30063_1",
    label: "STUD",
    qty: "2",
    parent: "CRSJ4LOF03",
  },
  {
    sno: "4",
    partNo: "99SG3144",
    label: "LUB_OIL_FILLER_ASSEMBLY",
    qty: "1",
    parent: "CRSJ4LOF03",
  },
  {
    sno: "1",
    partNo: "920003",
    label: "WASHER_PLAIN",
    qty: "2",
    parent: "CRSJ4IMF03",
  },
  {
    sno: "2",
    partNo: "10SG4208",
    label: "BRACKET_CR_FUEL_FILTER",
    qty: "1",
    parent: "CRSJ4IMF03",
  },
  {
    sno: "3",
    partNo: "20SG4177",
    label: "ELBOW_INTAKE_MANIFOLD",
    qty: "1",
    parent: "CRSJ4IMF03",
  },
  {
    sno: "4",
    partNo: "49SG3049",
    label: "GASKET_INTAKE_ELBOW",
    qty: "1",
    parent: "CRSJ4IMF03",
  },
  {
    sno: "5",
    partNo: "49SG4011",
    label: "JOINT_AIR_INTAKE_MANIFOLD",
    qty: "1",
    parent: "CRSJ4IMF03",
  },
  {
    sno: "6",
    partNo: "54SG4061",
    label: "MANIFOLD_AIR_INTAKE",
    qty: "1",
    parent: "CRSJ4IMF03",
  },
  {
    sno: "7",
    partNo: "57SG3013_5",
    label: "NUT",
    qty: "4",
    parent: "CRSJ4IMF03",
  },
  {
    sno: "8",
    partNo: "74SG3029_1",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ4IMF03",
  },
  {
    sno: "9",
    partNo: "74SG3040",
    label: "SETSCREW",
    qty: "3",
    parent: "CRSJ4IMF03",
  },
  {
    sno: "10",
    partNo: "74SG4069",
    label: "BOLT",
    qty: "8",
    parent: "CRSJ4IMF03",
  },
  {
    sno: "11",
    partNo: "82SG4021",
    label: "STUD",
    qty: "2",
    parent: "CRSJ4IMF03",
  },
  {
    sno: "12",
    partNo: "DE006125",
    label: "BOOST_PRESSURE_SENSOR",
    qty: "1",
    parent: "CRSJ4IMF03",
  },
  {
    sno: "1",
    partNo: "10SG4147",
    label: "CLAMP_INJECTOR",
    qty: "4",
    parent: "CRSJ4INJ01",
  },
  {
    sno: "2",
    partNo: "73SG3014",
    label: "SEAL_COMMON_RAIL_INJECTOR",
    qty: "4",
    parent: "CRSJ4INJ01",
  },
  {
    sno: "3",
    partNo: "74S30262",
    label: "BOLT",
    qty: "4",
    parent: "CRSJ4INJ01",
  },
  {
    sno: "4",
    partNo: "92SG3009",
    label: "SPHERICAL_WASHER_INJECTOR_CLAMP_TIER4",
    qty: "4",
    parent: "CRSJ4INJ01",
  },
  {
    sno: "5",
    partNo: "DE006055",
    label: "GLOW_PLUG",
    qty: "4",
    parent: "CRSJ4INJ01",
  },
  {
    sno: "6",
    partNo: "DE006117",
    label: "CR_INJECTOR",
    qty: "4",
    parent: "CRSJ4INJ01",
  },
  {
    sno: "7",
    partNo: "DE006578",
    label: "FUEL_RETURN_LINE_ASSEMBLY",
    qty: "1",
    parent: "CRSJ4INJ01",
  },
  {
    sno: "1",
    partNo: "74SG3040_1",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ4HPP01",
  },
  {
    sno: "2",
    partNo: "99SG4819",
    label: "HIGH_PRESSURE_PIPE_ASSEMBLY_RAIL_TO_INJECTOR_1",
    qty: "1",
    parent: "CRSJ4HPP01",
  },
  {
    sno: "3",
    partNo: "99SG4820",
    label: "HIGH_PRESSURE_PIPE_ASSEMBLY_RAIL_TO_INJECTOR_2",
    qty: "1",
    parent: "CRSJ4HPP01",
  },
  {
    sno: "4",
    partNo: "99SG4821",
    label: "HIGH_PRESSURE_PIPE_ASSEMBLY_RAIL_TO_INJECTOR_3",
    qty: "1",
    parent: "CRSJ4HPP01",
  },
  {
    sno: "5",
    partNo: "99SG4822",
    label: "HIGH_PRESSURE_PIPE_ASSEMBLY_RAIL_TO_INJECTOR_4",
    qty: "1",
    parent: "CRSJ4HPP01",
  },
  {
    sno: "6",
    partNo: "99SG4842",
    label: "HIGH_PRESSURE_PIPE_ASSEMBLY_PUMP_TO_RAIL_1",
    qty: "1",
    parent: "CRSJ4HPP01",
  },
  {
    sno: "7",
    partNo: "99SG4843",
    label: "HIGH_PRESSURE_PIPE_ASSEMBLY_PUMP_TO_RAIL_2",
    qty: "1",
    parent: "CRSJ4HPP01",
  },
  {
    sno: "8",
    partNo: "DE006491",
    label: "COMMOM_RAIL",
    qty: "1",
    parent: "CRSJ4HPP01",
  },
  {
    sno: "8A",
    partNo: "DE006483",
    label: "RAIL PRESSURE SENSOR",
    qty: "1",
    parent: "CRSJ4HPP01",
  },
  {
    sno: "1",
    partNo: "09DE0005",
    label: "BOLT_BANJO",
    qty: "4",
    parent: "CRSJ4LPC02",
  },
  {
    sno: "2",
    partNo: "33SG3006",
    label: "SPACER",
    qty: "2",
    parent: "CRSJ4LPC02",
  },
  {
    sno: "3",
    partNo: "74S30314",
    label: "BOLT",
    qty: "2",
    parent: "CRSJ4LPC02",
  },
  {
    sno: "4",
    partNo: "74SG3050",
    label: "SCREW",
    qty: "2",
    parent: "CRSJ4LPC02",
  },
  {
    sno: "5",
    partNo: "92S30194",
    label: "WASHER",
    qty: "8",
    parent: "CRSJ4LPC02",
  },
  {
    sno: "6",
    partNo: "99SG3817",
    label: "HOSE_ASSEMBLY_FUEL_FILTER",
    qty: "2",
    parent: "CRSJ4LPC02",
  },
  {
    sno: "7",
    partNo: "DE006341",
    label: "PRE_FILTER_FUEL",
    qty: "1",
    parent: "CRSJ4LPC02",
  },
  {
    sno: "8",
    partNo: "DE006385",
    label: "MAIN_FILTER",
    qty: "1",
    parent: "CRSJ4LPC02",
  },
  {
    sno: "8A",
    partNo: "DE006378",
    label: "PRE FILTER HEAD",
    qty: "1",
    parent: "CRSJ4LPC02",
  },
  {
    sno: "8B",
    partNo: "DE006379",
    label: "PRE FILTER INSERT",
    qty: "1",
    parent: "CRSJ4LPC02",
  },
  {
    sno: "9A",
    partNo: "DE006448",
    label: "MAIN FILTER TEMPERATURE SENSOR",
    qty: "1",
    parent: "CRSJ4LPC02",
  },
  {
    sno: "9B",
    partNo: "DE006447",
    label: "MAIN FILTER WATER IN FUEL SENSOR",
    qty: "1",
    parent: "CRSJ4LPC02",
  },
  {
    sno: "9C",
    partNo: "DE006529",
    label: "MAIN FILTER INSERT",
    qty: "1",
    parent: "CRSJ4LPC02",
  },
  {
    sno: "9D",
    partNo: "DE007349",
    label: "MAIN FILTER HEAD",
    qty: "1",
    parent: "CRSJ4LPC02",
  },
  {
    sno: "1",
    partNo: "08SG4056",
    label: "LUBE_OIL_COOLER_FILTER_HEAD",
    qty: "1",
    parent: "CRSJ4LFT06",
  },
  {
    sno: "2",
    partNo: "17SG3010",
    label: "CONSTANT_TENSION_CLAMP",
    qty: "8",
    parent: "CRSJ4LFT06",
  },
  {
    sno: "3",
    partNo: "20SG3049",
    label: "CONNECTION_LUB_OIL_FILTER_HEAD",
    qty: "1",
    parent: "CRSJ4LFT06",
  },
  {
    sno: "4",
    partNo: "49S30201",
    label: "JOINT_LUB_OIL_FILTER",
    qty: "1",
    parent: "CRSJ4LFT06",
  },
  {
    sno: "5",
    partNo: "74SG3045",
    label: "BOLT",
    qty: "2",
    parent: "CRSJ4LFT06",
  },
  {
    sno: "6",
    partNo: "DE006173",
    label: "OIL_COOLER_ASSEMBLY",
    qty: "1",
    parent: "CRSJ4LFT06",
  },
  {
    sno: "7",
    partNo: "DE006785",
    label: "LUBE_OIL_FILTER",
    qty: "1",
    parent: "CRSJ4LFT06",
  },
  {
    sno: "8",
    partNo: "SG990014",
    label: "PIPE_ASSY_BLOCK_TO_OIL_COOLER",
    qty: "1",
    parent: "CRSJ4LFT06",
  },
  {
    sno: "9",
    partNo: "SG990015",
    label: "PIPE_ASSY_OIL_COOLER_TO_WATER_PUMP",
    qty: "1",
    parent: "CRSJ4LFT06",
  },
  {
    sno: "1",
    partNo: "180063",
    label: "CLIP",
    qty: "4",
    parent: "CRSJ4BRT01",
  },
  {
    sno: "2",
    partNo: "180064",
    label: "CLIP",
    qty: "2",
    parent: "CRSJ4BRT01",
  },
  {
    sno: "3",
    partNo: "10SG4151",
    label: "BRACKET_CCV_HORIZONTAL_MOUNTING",
    qty: "1",
    parent: "CRSJ4BRT01",
  },
  {
    sno: "4",
    partNo: "20SG4127",
    label: "HOSE_CYL.HEAD_COVER_TO_ BREATHER",
    qty: "1",
    parent: "CRSJ4BRT01",
  },
  {
    sno: "5",
    partNo: "20SG4128",
    label: "HOSE_BREATHER_TO_ CYLINDER_BLOCK",
    qty: "1",
    parent: "CRSJ4BRT01",
  },
  {
    sno: "6",
    partNo: "62SG3041",
    label: "HOSE",
    qty: "1",
    parent: "CRSJ4BRT01",
  },
  {
    sno: "7",
    partNo: "74SG3029_2",
    label: "SETSCREW",
    qty: "4",
    parent: "CRSJ4BRT01",
  },
  {
    sno: "8",
    partNo: "74SG3040_2",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ4BRT01",
  },
  {
    sno: "9",
    partNo: "DE006676",
    label: "BREATHER_ASSEMBLY",
    qty: "1",
    parent: "CRSJ4BRT01",
  },
  {
    sno: "10",
    partNo: "NRV_FLEET_GUARD",
    label: "NRV_FLEET_GUARD",
    qty: "1",
    parent: "CRSJ4BRT01",
  },
  {
    sno: "1",
    partNo: "09DE0006_1",
    label: "BOLT_BANJO",
    qty: "2",
    parent: "CRSJ4TUR04",
  },
  {
    sno: "2",
    partNo: "17SG3010_1",
    label: "CONSTANT_TENSION_CLAMP",
    qty: "1",
    parent: "CRSJ4TUR04",
  },
  {
    sno: "3",
    partNo: "20SG4052",
    label: "UNION_TURBOCHARGER_OIL_DRAIN",
    qty: "1",
    parent: "CRSJ4TUR04",
  },
  {
    sno: "4",
    partNo: "20SG4179",
    label: "ELBOW_TURBO_COMPRESSOR_OUT",
    qty: "1",
    parent: "CRSJ4TUR04",
  },
  {
    sno: "5",
    partNo: "49DE0162",
    label: "JOINT_TURBOCHARGER_OIL_DRAIN",
    qty: "1",
    parent: "CRSJ4TUR04",
  },
  {
    sno: "6",
    partNo: "49SG3048",
    label: "GASKET_EXH_MANIFOLD_TO_TURBOCHARGER",
    qty: "1",
    parent: "CRSJ4TUR04",
  },
  {
    sno: "7",
    partNo: "49SG3105",
    label: "GASKET_TURBO_COMPRESSOR_OUTLET",
    qty: "1",
    parent: "CRSJ4TUR04",
  },
  {
    sno: "8",
    partNo: "57C20006",
    label: "NUT",
    qty: "2",
    parent: "CRSJ4TUR04",
  },
  {
    sno: "9",
    partNo: "57SG4015_1",
    label: "NUT",
    qty: "3",
    parent: "CRSJ4TUR04",
  },
  {
    sno: "10",
    partNo: "74SG3082_1",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ4TUR04",
  },
  {
    sno: "11",
    partNo: "82SG3002",
    label: "STUD",
    qty: "3",
    parent: "CRSJ4TUR04",
  },
  {
    sno: "12",
    partNo: "82SG3005",
    label: "STUD",
    qty: "2",
    parent: "CRSJ4TUR04",
  },
  {
    sno: "13",
    partNo: "92DE0009_2",
    label: "WASHER",
    qty: "4",
    parent: "CRSJ4TUR04",
  },
  {
    sno: "14",
    partNo: "99SG4354",
    label: "PIPE_TURBOCHARGER_OIL_DRAIN",
    qty: "1",
    parent: "CRSJ4TUR04",
  },
  {
    sno: "15",
    partNo: "99SG4646",
    label: "PIPE_ASSY_TURBOCHARGER_TO_OIL_FEED",
    qty: "1",
    parent: "CRSJ4TUR04",
  },
  {
    sno: "16",
    partNo: "DE007021",
    label: "TURBO_CHARGER_PUNCHING_TAMPER_PROOF",
    qty: "1",
    parent: "CRSJ4TUR04",
  },
  {
    sno: "1",
    partNo: "920053",
    label: "WASHER_SPRING",
    qty: "1",
    parent: "CRSJ4ELT02",
  },
  {
    sno: "2",
    partNo: "07DE0003",
    label: "BELT_FAN_DRIVE",
    qty: "1",
    parent: "CRSJ4ELT02",
  },
  {
    sno: "3",
    partNo: "10SG3039",
    label: "BRACKET_ALTERNATOR",
    qty: "1",
    parent: "CRSJ4ELT02",
  },
  {
    sno: "4",
    partNo: "10SG4186",
    label: "BRACKET_STARTER_MOTOR",
    qty: "1",
    parent: "CRSJ4ELT02",
  },
  {
    sno: "5",
    partNo: "2318A604_1",
    label: "NUT",
    qty: "1",
    parent: "CRSJ4ELT02",
  },
  {
    sno: "6",
    partNo: "24SG4087",
    label: "HEATSHIELD_GRS_STARTER_MOTOR",
    qty: "1",
    parent: "CRSJ4ELT02",
  },
  {
    sno: "7",
    partNo: "52SG4025",
    label: "LEVER_ALTERNATOR",
    qty: "1",
    parent: "CRSJ4ELT02",
  },
  {
    sno: "8",
    partNo: "57SG3013_6",
    label: "NUT",
    qty: "3",
    parent: "CRSJ4ELT02",
  },
  {
    sno: "9",
    partNo: "57SG4015_2",
    label: "NUT",
    qty: "2",
    parent: "CRSJ4ELT02",
  },
  {
    sno: "10",
    partNo: "67DE0094",
    label: "PULLEY_ALTERNATOR",
    qty: "1",
    parent: "CRSJ4ELT02",
  },
  {
    sno: "11",
    partNo: "74DE0203",
    label: "BOLT",
    qty: "1",
    parent: "CRSJ4ELT02",
  },
  {
    sno: "12",
    partNo: "74SG3081_2",
    label: "SETSCREW",
    qty: "4",
    parent: "CRSJ4ELT02",
  },
  {
    sno: "13",
    partNo: "74SG3086",
    label: "SETSCREW",
    qty: "3",
    parent: "CRSJ4ELT02",
  },
  {
    sno: "14",
    partNo: "74SG4069_1",
    label: "BOLT",
    qty: "1",
    parent: "CRSJ4ELT02",
  },
  {
    sno: "15",
    partNo: "92DE0023_1",
    label: "WASHER",
    qty: "1",
    parent: "CRSJ4ELT02",
  },
  {
    sno: "16",
    partNo: "DE007043",
    label: "STARTER_MOTOR_GRS78_2.7KW",
    qty: "1",
    parent: "CRSJ4ELT02",
  },
  {
    sno: "17",
    partNo: "DE007083",
    label: "ALTERNATOR",
    qty: "1",
    parent: "CRSJ4ELT02",
  },
  {
    sno: "18",
    partNo: "S4008217",
    label: "STUD",
    qty: "3",
    parent: "CRSJ4ELT02",
  },
  {
    sno: "1",
    partNo: "920056",
    label: "WASHER_SPRING",
    qty: "8",
    parent: "CRSJ4MBT01",
  },
  {
    sno: "2",
    partNo: "57S30051",
    label: "NUT",
    qty: "8",
    parent: "CRSJ4MBT01",
  },
  {
    sno: "3",
    partNo: "74DE0294",
    label: "BOLT",
    qty: "2",
    parent: "CRSJ4MBT01",
  },
  {
    sno: "4",
    partNo: "74S30179",
    label: "SETSCREW",
    qty: "4",
    parent: "CRSJ4MBT01",
  },
  {
    sno: "5",
    partNo: "82S30118",
    label: "STUD",
    qty: "8",
    parent: "CRSJ4MBT01",
  },
  {
    sno: "1",
    partNo: "350016_2",
    label: "DOWEL",
    qty: "2",
    parent: "CRSJ4BEN03",
  },
  {
    sno: "2",
    partNo: "921062",
    label: "WASHER_TAB",
    qty: "3",
    parent: "CRSJ4BEN03",
  },
  {
    sno: "3",
    partNo: "32161114",
    label: "PLUG",
    qty: "1",
    parent: "CRSJ4BEN03",
  },
  {
    sno: "4",
    partNo: "44SG4098",
    label: "FLYWHEEL_HOUSING",
    qty: "1",
    parent: "CRSJ4BEN03",
  },
  {
    sno: "5",
    partNo: "57DE0103_2",
    label: "NUT",
    qty: "8",
    parent: "CRSJ4BEN03",
  },
  {
    sno: "6",
    partNo: "74SG3029_3",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ4BEN03",
  },
  {
    sno: "7",
    partNo: "92SG3010",
    label: "PLAIN_WASHER_(SPEED_SENSOR)",
    qty: "1",
    parent: "CRSJ4BEN03",
  },
  {
    sno: "8",
    partNo: "99SG4850",
    label: "FLYWHEEL_ASSEMBLY",
    qty: "1",
    parent: "CRSJ4BEN03",
  },
  {
    sno: "9",
    partNo: "DE006123",
    label: "ROTATIONAL_SPEED_SENSOR",
    qty: "1",
    parent: "CRSJ4BEN03",
  },
  {
    sno: "10",
    partNo: "S4007416",
    label: "SETSCREW",
    qty: "6",
    parent: "CRSJ4BEN03",
  },
  {
    sno: "11",
    partNo: "S4008208",
    label: "STUD",
    qty: "2",
    parent: "CRSJ4BEN03",
  },
  {
    sno: "12",
    partNo: "S4008210",
    label: "STUD",
    qty: "6",
    parent: "CRSJ4BEN03",
  },
  {
    sno: "1",
    partNo: "17SG3010_2",
    label: "CONSTANT_TENSION_CLAMP",
    qty: "8",
    parent: "CRSJ4EGR01",
  },
  {
    sno: "2",
    partNo: "20SG3041",
    label: "HOSE_EGR_TO_EGR_COOLER",
    qty: "1",
    parent: "CRSJ4EGR01",
  },
  {
    sno: "3",
    partNo: "20SG4178",
    label: "HOSE_EGR_COOLER_TO_WATER_OUTLET_BODY",
    qty: "1",
    parent: "CRSJ4EGR01",
  },
  {
    sno: "4",
    partNo: "49SG3050",
    label: "GASKET_EGR",
    qty: "2",
    parent: "CRSJ4EGR01",
  },
  {
    sno: "5",
    partNo: "49SG3054",
    label: "GASKET_EGR_VALVE_&_COOLER",
    qty: "3",
    parent: "CRSJ4EGR01",
  },
  {
    sno: "6",
    partNo: "57C20006_1",
    label: "NUT",
    qty: "2",
    parent: "CRSJ4EGR01",
  },
  {
    sno: "7",
    partNo: "62SG3020",
    label: "EXHAUST_GAS_INLET_PIPE_EMF_TO_EGR",
    qty: "1",
    parent: "CRSJ4EGR01",
  },
  {
    sno: "8",
    partNo: "62SG4061",
    label: "EGR PIPE - EGR COOLER TO IMF",
    qty: "1",
    parent: "CRSJ4EGR01",
  },
  {
    sno: "9",
    partNo: "74SG3027",
    label: "SETSCREW",
    qty: "8",
    parent: "CRSJ4EGR01",
  },
  {
    sno: "10",
    partNo: "74SG3040_3",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ4EGR01",
  },
  {
    sno: "11",
    partNo: "74SG3051",
    label: "BOLT",
    qty: "3",
    parent: "CRSJ4EGR01",
  },
  {
    sno: "12",
    partNo: "82SG3005_1",
    label: "STUD",
    qty: "2",
    parent: "CRSJ4EGR01",
  },
  {
    sno: "13",
    partNo: "99SG3247",
    label: "MOTORIZED_EGR",
    qty: "1",
    parent: "CRSJ4EGR01",
  },
  {
    sno: "14",
    partNo: "99SG4872",
    label: "PIPE_ASSY_BLOCK_TO_EGR",
    qty: "1",
    parent: "CRSJ4EGR01",
  },
  {
    sno: "15",
    partNo: "DE006932",
    label: "EGR_COOLER_10_KW",
    qty: "1",
    parent: "CRSJ4EGR01",
  },
  {
    sno: "1",
    partNo: "09DE0006_2",
    label: "BOLT_BANJO",
    qty: "1",
    parent: "CRSJ4ACP01",
  },
  {
    sno: "2",
    partNo: "20DE0061",
    label: "UNION",
    qty: "1",
    parent: "CRSJ4ACP01",
  },
  {
    sno: "3",
    partNo: "20DE0062",
    label: "ELBOW_SOCKET",
    qty: "1",
    parent: "CRSJ4ACP01",
  },
  {
    sno: "4",
    partNo: "56DE0005",
    label: "SLEEVE",
    qty: "2",
    parent: "CRSJ4ACP01",
  },
  {
    sno: "5",
    partNo: "57DE0012",
    label: "NUT",
    qty: "2",
    parent: "CRSJ4ACP01",
  },
  {
    sno: "6",
    partNo: "57DE0013",
    label: "NUT",
    qty: "1",
    parent: "CRSJ4ACP01",
  },
  {
    sno: "7",
    partNo: "57SG3013_7",
    label: "NUT",
    qty: "1",
    parent: "CRSJ4ACP01",
  },
  {
    sno: "8",
    partNo: "65DE0036_1",
    label: "PLUG",
    qty: "1",
    parent: "CRSJ4ACP01",
  },
  {
    sno: "9",
    partNo: "74SG3077_2",
    label: "BOLT",
    qty: "3",
    parent: "CRSJ4ACP01",
  },
  {
    sno: "10",
    partNo: "92DE0003_1",
    label: "WASHER",
    qty: "3",
    parent: "CRSJ4ACP01",
  },
  {
    sno: "11",
    partNo: "92DE0009_3",
    label: "WASHER",
    qty: "4",
    parent: "CRSJ4ACP01",
  },
  {
    sno: "12",
    partNo: "92DE0027",
    label: "WASHER_SEALING",
    qty: "2",
    parent: "CRSJ4ACP01",
  },
  {
    sno: "13",
    partNo: "99SG4815",
    label: "PIPE_ASSY_BLOCK_TO_COMPRESSOR",
    qty: "1",
    parent: "CRSJ4ACP01",
  },
  {
    sno: "14",
    partNo: "DE006048",
    label: "COMPRESSOR_AIR",
    qty: "1",
    parent: "CRSJ4ACP01",
  },
  {
    sno: "15",
    partNo: "S4000904",
    label: "BOLT_BANJO",
    qty: "1",
    parent: "CRSJ4ACP01",
  },
  {
    sno: "16",
    partNo: "S4004108",
    label: "GEAR_AIR_COMPRESSOR",
    qty: "1",
    parent: "CRSJ4ACP01",
  },
  {
    sno: "17",
    partNo: "S4004916",
    label: "JOINT_POWER_TAKE_OFF_BLANKING_PLATE",
    qty: "1",
    parent: "CRSJ4ACP01",
  },
  {
    sno: "18",
    partNo: "S4008217_1",
    label: "STUD",
    qty: "1",
    parent: "CRSJ4ACP01",
  },
  {
    sno: "1",
    partNo: "2154007",
    label: "RIVET_NAME_PLATE",
    qty: "2",
    parent: "CRSJ4NAM03",
  },
  {
    sno: "2",
    partNo: "94SG4264",
    label: "NAME_PLATEE SJV436",
    qty: "1",
    parent: "CRSJ4NAM03",
  },
  {
    sno: "1",
    partNo: "32SG4013",
    label: "DIPSTICK_ASSEMBLY",
    qty: "1",
    parent: "CRSJ4DIP01",
  },
  {
    sno: "2",
    partNo: "99SG4460",
    label: "TUBE_DIPSTICK",
    qty: "1",
    parent: "CRSJ4DIP01",
  },
  {
    sno: "1",
    partNo: "920645",
    label: "WASHER_TAB",
    qty: "2",
    parent: "CRSJ4EXT03",
  },
  {
    sno: "2",
    partNo: "36DE0026",
    label: "EXTENSION_FAN",
    qty: "1",
    parent: "CRSJ4EXT03",
  },
  {
    sno: "3",
    partNo: "74SG3041",
    label: "SETSCREW",
    qty: "4",
    parent: "CRSJ4EXT03",
  },
  {
    sno: "1",
    partNo: "74SG3029_4",
    label: "SETSCREW",
    qty: "3",
    parent: "CRSJ4SER01",
  },
  {
    sno: "2",
    partNo: "92SG3010_1",
    label: "PLAIN_WASHER_(SPEED_SENSOR)",
    qty: "1",
    parent: "CRSJ4SER01",
  },
  {
    sno: "3",
    partNo: "DE006123_1",
    label: "ROTATIONAL_SPEED_SENSOR",
    qty: "1",
    parent: "CRSJ4SER01",
  },
  {
    sno: "4",
    partNo: "DE006124_1",
    label: "CAM/PHASE_SENSOR",
    qty: "1",
    parent: "CRSJ4SER01",
  },
  {
    sno: "5",
    partNo: "DE006125_1",
    label: "BOOST_PRESSURE_SENSOR",
    qty: "1",
    parent: "CRSJ4SER01",
  },
  {
    sno: "6",
    partNo: "DE006146_1",
    label: "TEMPREATURE_SENSOR",
    qty: "1",
    parent: "CRSJ4SER01",
  },
  {
    sno: "7",
    partNo: "DE006804_1",
    label: "COMBINED_OIL_PRESSURE_TEMPERTAURE_SENSOR",
    qty: "1",
    parent: "CRSJ4SER01",
  },
];

export const ENGINE2_MAIN_PART_LIST = [
  {
    sno: "1",
    label: "Cylinder Block Assembly",
    id: "CRSJ3CBK03",
  },
  {
    sno: "2",
    label: "Crank shaft assembly",
    id: "CRSJ3CRS02",
  },
  {
    sno: "3",
    label: "Connecting Rod Assembly",
    id: "CRSJ3CRD01",
  },
  {
    sno: "4",
    label: "Piston Assembly",
    id: "CRSJ3PSN02",
  },
  {
    sno: "5",
    label: "Crank Pulley",
    id: "CRSJ3CRP02",
  },
  {
    sno: "6",
    label: "Camshaft Assembly",
    id: "CRSJ3CAM02",
  },
  {
    sno: "7",
    label: "Cylinder Head Assembly",
    id: "CRSJ3CHD04",
  },
  {
    sno: "8",
    label: "Valve train Assembly",
    id: "CRSJ3VTR01",
  },
  {
    sno: "9",
    label: "Head Cover Assembly",
    id: "CRSJ3CHC01",
  },
  {
    sno: "10",
    label: "Injector Assembly",
    id: "CRSJ3INJ03",
  },
  {
    sno: "11",
    label: "High Pressure Fuel Pipe Assembly",
    id: "CRSJ3HPP02",
  },
  {
    sno: "12",
    label: "Fuel Injection Pump Assembly",
    id: "CRSJ3FIP01",
  },
  {
    sno: "13",
    label: "Timing Case Assembly",
    id: "CRSJ3TMC01",
  },
  {
    sno: "14",
    label: "Twin Gear Assembly",
    id: "CRSJ3TGR01",
  },
  {
    sno: "15",
    label: "Timing Case Cover Assembly",
    id: "CRSJ3CC02",
  },
  {
    sno: "16",
    label: "Intake Manifold Assembly",
    id: "CRSJ3IMF03",
  },
  {
    sno: "17",
    label: "Exhaust Manifold Assembly",
    id: "CRSJ3EMF01",
  },
  {
    sno: "18",
    label: "Back Ends Assembly",
    id: "CRSJ3BEN01",
  },
  {
    sno: "19",
    label: "Rear end Oil Seal Assembly",
    id: "CRSJ3ROS01",
  },
  {
    sno: "20",
    label: "Oil Pan Assembly",
    id: "CRSJ3OPN03",
  },
  {
    sno: "21",
    label: "Lube Oil Pump Assembly",
    id: "CRSJ3LOP01",
  },
  {
    sno: "22",
    label: "Lube Oil Filler Assembly",
    id: "CRSJ3LOF03",
  },
  {
    sno: "23",
    label: "EGR Assembly",
    id: "CRSJ3EGR01",
  },
  {
    sno: "24",
    label: "Turbo Charger Assembly",
    id: "CRSJ3TUR02",
  },
  {
    sno: "25",
    label: "Lifting Hook Assembly",
    id: "CRSJ3LHK01",
  },
  {
    sno: "26",
    label: "Electrical Parts Assembly",
    id: "CRSJ3ELT02",
  },
  {
    sno: "27",
    label: "Lube Oil Filter Assembly",
    id: "CRSJ3LFT01",
  },
  {
    sno: "28",
    label: "Water Inlet Connection Assembly",
    id: "CRSJ3WIC01",
  },
  {
    sno: "29",
    label: "Water Pump Assembly",
    id: "CRSJ3WTP02",
  },
  {
    sno: "30",
    label: "Water Outlet Connection Assembly",
    id: "CRSJ3WOC02",
  },
  {
    sno: "31",
    label: "Dipstick Assembly",
    id: "CRSJ3DIP01",
  },
  {
    sno: "32",
    label: "Extension Fan Assembly",
    id: "CRSJ3EXT01",
  },
];
export const ENGINE2_CHILD_PART_LIST = [
  {
    sno: "1",
    partNo: "99SG3964",
    label: "CYLINDER_BLOCK_ASSEMBLY",
    qty: "1",
    parent: "CRSJ3CBK03",
  },
  {
    sno: "2",
    partNo: "350016",
    label: "DOWEL",
    qty: "2",
    parent: "CRSJ3CBK03",
  },
  {
    sno: "3",
    partNo: "650566",
    label: "PLUG",
    qty: "1",
    parent: "CRSJ3CBK03",
  },
  {
    sno: "4",
    partNo: "650710",
    label: "PLUG",
    qty: "2",
    parent: "CRSJ3CBK03",
  },
  {
    sno: "5",
    partNo: "20DE0344",
    label: "UNION_LUB_OIL_PRESSURE_GAUGE",
    qty: "1",
    parent: "CRSJ3CBK03",
  },
  {
    sno: "6",
    partNo: "62SG3022",
    label: "CONNECTION_WATERLINE",
    qty: "2",
    parent: "CRSJ3CBK03",
  },
  {
    sno: "7",
    partNo: "65SG3001",
    label: "PLUG",
    qty: "1",
    parent: "CRSJ3CBK03",
  },
  {
    sno: "8",
    partNo: "65SG3004",
    label: "PLUG_HEATER",
    qty: "1",
    parent: "CRSJ3CBK03",
  },
  {
    sno: "9",
    partNo: "65SG3009",
    label: "PLUG_COOLANT_CONNECTION",
    qty: "1",
    parent: "CRSJ3CBK03",
  },
  {
    sno: "10",
    partNo: "74DE0220",
    label: "PLUG",
    qty: "2",
    parent: "CRSJ3CBK03",
  },
  {
    sno: "11",
    partNo: "74SG3029",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ3CBK03",
  },
  {
    sno: "12",
    partNo: "92DE0009",
    label: "WASHER",
    qty: "2",
    parent: "CRSJ3CBK03",
  },
  {
    sno: "13",
    partNo: "92DE0107",
    label: "WASHER",
    qty: "4",
    parent: "CRSJ3CBK03",
  },
  {
    sno: "14",
    partNo: "DE006124",
    label: "CAM/PHASE_SENSOR",
    qty: "1",
    parent: "CRSJ3CBK03",
  },
  {
    sno: "15",
    partNo: "S4007436",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ3CBK03",
  },
  {
    sno: "16",
    partNo: "S4007469",
    label: "GRUB_SCREW",
    qty: "1",
    parent: "CRSJ3CBK03",
  },
  {
    sno: "1",
    partNo: "26SG3013",
    label: "CRANKSHAFT",
    qty: "1",
    parent: "CRSJ3CRS02",
  },
  {
    sno: "2",
    partNo: "2415608",
    label: "O_RING",
    qty: "2",
    parent: "CRSJ3CRS02",
  },
  {
    sno: "3",
    partNo: "31121261",
    label: "BEARING_MAIN",
    qty: "4",
    parent: "CRSJ3CRS02",
  },
  {
    sno: "4",
    partNo: "31122411",
    label: "BEARING_MAIN",
    qty: "1",
    parent: "CRSJ3CRS02",
  },
  {
    sno: "5",
    partNo: "31122421",
    label: "BEARING_MAIN",
    qty: "1",
    parent: "CRSJ3CRS02",
  },
  {
    sno: "6",
    partNo: "31123331",
    label: "BEARING_MAIN",
    qty: "2",
    parent: "CRSJ3CRS02",
  },
  {
    sno: "7",
    partNo: "31137211",
    label: "WASHER_THRUST",
    qty: "2",
    parent: "CRSJ3CRS02",
  },
  {
    sno: "8",
    partNo: "31137221",
    label: "WASHER_THRUST",
    qty: "2",
    parent: "CRSJ3CRS02",
  },
  {
    sno: "9",
    partNo: "36271704",
    label: "KEY_CRANKSHAFT",
    qty: "1",
    parent: "CRSJ3CRS02",
  },
  {
    sno: "10",
    partNo: "S4004101",
    label: "GEAR_CRANK_SHAFT",
    qty: "1",
    parent: "CRSJ3CRS02",
  },
  {
    sno: "1",
    partNo: "31131171",
    label: "BEARING_CONNECTING_ROD",
    qty: "6",
    parent: "CRSJ3CRD01",
  },
  {
    sno: "2",
    partNo: "SG990157",
    label: "CONNECTING_ROD_ASSEMBLY",
    qty: "3",
    parent: "CRSJ3CRD01",
  },
  {
    sno: "1",
    partNo: "17SG3008",
    label: "CIRCLIP_GUDGEON_PIN",
    qty: "6",
    parent: "CRSJ3PSN02",
  },
  {
    sno: "2",
    partNo: "61SG3002",
    label: "GUDGEON_PIN",
    qty: "3",
    parent: "CRSJ3PSN02",
  },
  {
    sno: "3",
    partNo: "63SG3013",
    label: "PISTON_ENGINE",
    qty: "3",
    parent: "CRSJ3PSN02",
  },
  {
    sno: "4",
    partNo: "64SG3010",
    label: "PISTON_RING_TOP_RING",
    qty: "3",
    parent: "CRSJ3PSN02",
  },
  {
    sno: "5",
    partNo: "64SG3011",
    label: "PISTON_RING_SECOND_RING",
    qty: "3",
    parent: "CRSJ3PSN02",
  },
  {
    sno: "6",
    partNo: "64SG3012",
    label: "PISTON_RING_OIL_RING",
    qty: "3",
    parent: "CRSJ3PSN02",
  },
  {
    sno: "7",
    partNo: "99SG3521",
    label: "PISTON KIT",
    qty: "1",
    parent: "CRSJ3PSN02",
  },
  {
    sno: "8",
    partNo: "99SG3522",
    label: "PISTON RINGS KIT",
    qty: "1",
    parent: "CRSJ3PSN02",
  },
  {
    sno: "1",
    partNo: "33125112",
    label: "WASHER_CRANKSHAFT_PULLEY",
    qty: "1",
    parent: "CRSJ3CRP02",
  },
  {
    sno: "2",
    partNo: "33176137",
    label: "WASHER",
    qty: "1",
    parent: "CRSJ3CRP02",
  },
  {
    sno: "3",
    partNo: "67SG3060",
    label: "PULLEY_CRANKSHAFT",
    qty: "1",
    parent: "CRSJ3CRP02",
  },
  {
    sno: "4",
    partNo: "S4007426",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ3CRP02",
  },
  {
    sno: "5",
    partNo: "S4009415",
    label: "POINTER_TIMING",
    qty: "1",
    parent: "CRSJ3CRP02",
  },
  {
    sno: "1",
    partNo: "350005",
    label: "DOWEL",
    qty: "1",
    parent: "CRSJ3CAM02",
  },
  {
    sno: "2",
    partNo: "14SG3066",
    label: "CAMSHAFT",
    qty: "1",
    parent: "CRSJ3CAM02",
  },
  {
    sno: "3",
    partNo: "31SG3001",
    label: "WASHER_THRUST",
    qty: "1",
    parent: "CRSJ3CAM02",
  },
  {
    sno: "4",
    partNo: "41SG3001",
    label: "GEAR_CAMSHAFT",
    qty: "1",
    parent: "CRSJ3CAM02",
  },
  {
    sno: "5",
    partNo: "49S30199",
    label: "JOINT_TACHO_DRIVE",
    qty: "1",
    parent: "CRSJ3CAM02",
  },
  {
    sno: "6",
    partNo: "74SG3079",
    label: "SETSCREW",
    qty: "3",
    parent: "CRSJ3CAM02",
  },
  {
    sno: "7",
    partNo: "74SG3082",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ3CAM02",
  },
  {
    sno: "8",
    partNo: "74SG3087",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ3CAM02",
  },
  {
    sno: "9",
    partNo: "94S30406",
    label: "PLATE_TACHO_DRIVE_BLANKING",
    qty: "1",
    parent: "CRSJ3CAM02",
  },
  {
    sno: "1",
    partNo: "650710_1",
    label: "PLUG",
    qty: "5",
    parent: "CRSJ3CHD04",
  },
  {
    sno: "2",
    partNo: "650713",
    label: "PLUG",
    qty: "4",
    parent: "CRSJ3CHD04",
  },
  {
    sno: "3",
    partNo: "32417121",
    label: "PLUG",
    qty: "3",
    parent: "CRSJ3CHD04",
  },
  {
    sno: "4",
    partNo: "20SG3092",
    label: "BYPASS CONNECTION",
    qty: "1",
    parent: "CRSJ3CHD04",
  },
  {
    sno: "5",
    partNo: "2114A046",
    label: "DOWEL",
    qty: "2",
    parent: "CRSJ3CHD04",
  },
  {
    sno: "6",
    partNo: "29SG3019",
    label: "CYLINDER_HEAD",
    qty: "1",
    parent: "CRSJ3CHD04",
  },
  {
    sno: "7",
    partNo: "42SG3002",
    label: "GUIDE_VALVE",
    qty: "6",
    parent: "CRSJ3CHD04",
  },
  {
    sno: "8",
    partNo: "47SJ3001",
    label: "INSERT_VALVE_SEAT_AIR_INTAKE",
    qty: "3",
    parent: "CRSJ3CHD04",
  },
  {
    sno: "9",
    partNo: "47SJ3002",
    label: "INSERT_VALVE_SEAT_EXHAUST",
    qty: "3",
    parent: "CRSJ3CHD04",
  },
  {
    sno: "10",
    partNo: "49SG3153",
    label: "CYLINDER_HEAD_GASKET_MLS",
    qty: "1",
    parent: "CRSJ3CHD04",
  },
  {
    sno: "11",
    partNo: "74SG3001",
    label: "BOLT",
    qty: "8",
    parent: "CRSJ3CHD04",
  },
  {
    sno: "12",
    partNo: "74SG3002",
    label: "BOLT",
    qty: "4",
    parent: "CRSJ3CHD04",
  },
  {
    sno: "13",
    partNo: "DE006146",
    label: "TEMPREATURE_SENSOR",
    qty: "1",
    parent: "CRSJ3CHD04",
  },
  {
    sno: "1",
    partNo: "99SG3412",
    label: "ROCKER_SHAFT_ASSEMBLY",
    qty: "1",
    parent: "CRSJ3VTR01",
  },
  {
    sno: "2",
    partNo: "74SG4068",
    label: "BOLT",
    qty: "8",
    parent: "CRSJ3VTR01",
  },
  {
    sno: "3",
    partNo: "91SG3003",
    label: "VALVE_AIR_INTAKE",
    qty: "3",
    parent: "CRSJ3VTR01",
  },
  {
    sno: "4",
    partNo: "91SG3004",
    label: "VALVE_EXHAUST",
    qty: "3",
    parent: "CRSJ3VTR01",
  },
  {
    sno: "5",
    partNo: "70SG3001",
    label: "ROD_PUSH",
    qty: "6",
    parent: "CRSJ3VTR01",
  },
  {
    sno: "6",
    partNo: "86SG3001",
    label: "TAPPET",
    qty: "6",
    parent: "CRSJ3VTR01",
  },
  {
    sno: "7",
    partNo: "33177518",
    label: "WASHER - SPRING LOCATING",
    qty: "6",
    parent: "CRSJ3VTR01",
  },
  {
    sno: "8",
    partNo: "73SG3001",
    label: "SEAL_VALVE_STEM",
    qty: "6",
    parent: "CRSJ3VTR01",
  },
  {
    sno: "9",
    partNo: "78SG3001",
    label: "SPRING_VALVE",
    qty: "6",
    parent: "CRSJ3VTR01",
  },
  {
    sno: "10",
    partNo: "33423148",
    label: "CAP_VALVE_SPRING",
    qty: "6",
    parent: "CRSJ3VTR01",
  },
  {
    sno: "11",
    partNo: "230001",
    label: "COTTER_VALVE_SPRING",
    qty: "12",
    parent: "CRSJ3VTR01",
  },
  {
    sno: "1",
    partNo: "DE006177",
    label: "INTEGRATED_CYLINDER_HEAD_COVER",
    qty: "1",
    parent: "CRSJ3CHC01",
  },
  {
    sno: "2",
    partNo: "DE006667",
    label: "JOINT",
    qty: "1",
    parent: "CRSJ3CHC01",
  },
  {
    sno: "3",
    partNo: "DE006666",
    label: "SETSCREW",
    qty: "10",
    parent: "CRSJ3CHC01",
  },
  {
    sno: "1",
    partNo: "10SG3023",
    label: "CLAMP_INJECTOR_TIER4",
    qty: "3",
    parent: "CRSJ3INJ03",
  },
  {
    sno: "2",
    partNo: "72SG3008",
    label: "SETSCREW",
    qty: "3",
    parent: "CRSJ3INJ03",
  },
  {
    sno: "3",
    partNo: "73SG3014",
    label: "SEAL_COMMON_RAIL_INJECTOR",
    qty: "3",
    parent: "CRSJ3INJ03",
  },
  {
    sno: "4",
    partNo: "74SG3040",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ3INJ03",
  },
  {
    sno: "5",
    partNo: "92SG3009",
    label: "SPHERICAL_WASHER_INJECTOR_CLAMP_TIER4",
    qty: "3",
    parent: "CRSJ3INJ03",
  },
  {
    sno: "6",
    partNo: "99SG3288",
    label: "FUEL_RETURN_LINE_COMMON_RAIL_INJECTORS",
    qty: "1",
    parent: "CRSJ3INJ03",
  },
  {
    sno: "7",
    partNo: "DE006117",
    label: "CR_INJECTOR",
    qty: "3",
    parent: "CRSJ3INJ03",
  },
  {
    sno: "8",
    partNo: "DE006492",
    label: "COMMON_RAIL_HFR16_(3+2PORTS)",
    qty: "1",
    parent: "CRSJ3INJ03",
  },
  {
    sno: "9",
    partNo: "DE007006",
    label: "WASHER",
    qty: "3",
    parent: "CRSJ3INJ03",
  },
  {
    sno: "8A",
    partNo: "DE006483",
    label: "RAIL PRESSURE SENSOR",
    qty: "1",
    parent: "CRSJ3INJ03",
  },
  {
    sno: "1",
    partNo: "99SG3968",
    label: "HIGH PRESSURE PIPE-PUMP TO RAIL NO 1",
    qty: "1",
    parent: "CRSJ3HPP02",
  },
  {
    sno: "2",
    partNo: "99SG3969",
    label: "HIGH PRESSRE PIPE-PUMP TO RAIL NO 2",
    qty: "1",
    parent: "CRSJ3HPP02",
  },
  {
    sno: "3",
    partNo: "99SG3970",
    label: "HIGH PRESSURE PIPE RAIL TO INJECTOR NO 1",
    qty: "1",
    parent: "CRSJ3HPP02",
  },
  {
    sno: "4",
    partNo: "99SG3971",
    label: "HIGH PRESSURE PIPE RAIL TO INJECTOR NO 2",
    qty: "1",
    parent: "CRSJ3HPP02",
  },
  {
    sno: "5",
    partNo: "99SG3972",
    label: "HIGH PRESSRE PIPE RAIL TO INJECTOR 3",
    qty: "1",
    parent: "CRSJ3HPP02",
  },
  {
    sno: "1",
    partNo: "09DE0006",
    label: "BOLT_BANJO",
    qty: "1",
    parent: "CRSJ3FIP01",
  },
  {
    sno: "2",
    partNo: "41SG3049",
    label: "GEAR",
    qty: "1",
    parent: "CRSJ3FIP01",
  },
  {
    sno: "3",
    partNo: "57SG3013",
    label: "NUT",
    qty: "3",
    parent: "CRSJ3FIP01",
  },
  {
    sno: "4",
    partNo: "82S30063",
    label: "STUD",
    qty: "3",
    parent: "CRSJ3FIP01",
  },
  {
    sno: "5",
    partNo: "92DE0009_1",
    label: "WASHER",
    qty: "2",
    parent: "CRSJ3FIP01",
  },
  {
    sno: "6",
    partNo: "99SG3710",
    label: "PIPE_FIP_TO_OIL_FEED",
    qty: "1",
    parent: "CRSJ3FIP01",
  },
  {
    sno: "7",
    partNo: "DE006115",
    label: "CB_18_PUMP",
    qty: "1",
    parent: "CRSJ3FIP01",
  },
  {
    sno: "8",
    partNo: "DE007068",
    label: "BANJO INLET",
    qty: "1",
    parent: "CRSJ3FIP01",
  },
  {
    sno: "9",
    partNo: "DE007069",
    label: "INLET WASHER",
    qty: "2",
    parent: "CRSJ3FIP01",
  },
  {
    sno: "10",
    partNo: "DE007066",
    label: "BANJO OUTLET",
    qty: "1",
    parent: "CRSJ3FIP01",
  },
  {
    sno: "11",
    partNo: "DE007067",
    label: "OUTLET WASHER",
    qty: "2",
    parent: "CRSJ3FIP01",
  },
  {
    sno: "1",
    partNo: "330940",
    label: "SPACER",
    qty: "1",
    parent: "CRSJ3TMC01",
  },
  {
    sno: "2",
    partNo: "920299",
    label: "WASHER",
    qty: "1",
    parent: "CRSJ3TMC01",
  },
  {
    sno: "3",
    partNo: "24S30111",
    label: "BRIDGE_PIECE",
    qty: "1",
    parent: "CRSJ3TMC01",
  },
  {
    sno: "4",
    partNo: "44SG3075",
    label: "TIMING_CASE",
    qty: "1",
    parent: "CRSJ3TMC01",
  },
  {
    sno: "5",
    partNo: "49SG3026",
    label: "JOINT_HYDRAULIC_PUMP",
    qty: "1",
    parent: "CRSJ3TMC01",
  },
  {
    sno: "6",
    partNo: "49SG3073",
    label: "JOINT_TIMING_CASE",
    qty: "1",
    parent: "CRSJ3TMC01",
  },
  {
    sno: "7",
    partNo: "57SG3013_1",
    label: "NUT",
    qty: "1",
    parent: "CRSJ3TMC01",
  },
  {
    sno: "8",
    partNo: "74S30121",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ3TMC01",
  },
  {
    sno: "9",
    partNo: "74SG3071",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ3TMC01",
  },
  {
    sno: "10",
    partNo: "74SG3073",
    label: "SETSCREW",
    qty: "7",
    parent: "CRSJ3TMC01",
  },
  {
    sno: "11",
    partNo: "74SG3074",
    label: "SETSCREW",
    qty: "8",
    parent: "CRSJ3TMC01",
  },
  {
    sno: "12",
    partNo: "74SG3078",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ3TMC01",
  },
  {
    sno: "13",
    partNo: "74SG3079_1",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ3TMC01",
  },
  {
    sno: "14",
    partNo: "94SG3160",
    label: "PLATE_POWER_TAKE_OFF_BLANKING",
    qty: "1",
    parent: "CRSJ3TMC01",
  },
  {
    sno: "15",
    partNo: "S4008217",
    label: "STUD",
    qty: "1",
    parent: "CRSJ3TMC01",
  },
  {
    sno: "1",
    partNo: "350016_1",
    label: "DOWEL",
    qty: "2",
    parent: "CRSJ3TGR01",
  },
  {
    sno: "2",
    partNo: "92DE0003",
    label: "WASHER",
    qty: "2",
    parent: "CRSJ3TGR01",
  },
  {
    sno: "3",
    partNo: "S4000901",
    label: "BOLT_BANJO",
    qty: "1",
    parent: "CRSJ3TGR01",
  },
  {
    sno: "4",
    partNo: "S4004126",
    label: "GEAR_IDLER",
    qty: "1",
    parent: "CRSJ3TGR01",
  },
  {
    sno: "5",
    partNo: "S4004501",
    label: "HUB_IDLER_GEAR",
    qty: "1",
    parent: "CRSJ3TGR01",
  },
  {
    sno: "6",
    partNo: "S4005703",
    label: "NUT",
    qty: "6",
    parent: "CRSJ3TGR01",
  },
  {
    sno: "7",
    partNo: "S4008206",
    label: "STUD",
    qty: "6",
    parent: "CRSJ3TGR01",
  },
  {
    sno: "8",
    partNo: "S4009201",
    label: "WASHER_TAB",
    qty: "2",
    parent: "CRSJ3TGR01",
  },
  {
    sno: "9",
    partNo: "S4009401",
    label: "PLATE_IDLER_GEAR",
    qty: "1",
    parent: "CRSJ3TGR01",
  },
  {
    sno: "10",
    partNo: "S4009404",
    label: "PLATE_IDLER_GEAR",
    qty: "1",
    parent: "CRSJ3TGR01",
  },
  {
    sno: "11",
    partNo: "S4009906",
    label: "PIPE",
    qty: "1",
    parent: "CRSJ3TGR01",
  },
  {
    sno: "12",
    partNo: "S4009937",
    label: "GEAR_IDLER",
    qty: "1",
    parent: "CRSJ3TGR01",
  },
  {
    sno: "13",
    partNo: "SK004015",
    label: "HUB_IDLER_GEAR",
    qty: "1",
    parent: "CRSJ3TGR01",
  },
  {
    sno: "1",
    partNo: "330940_1",
    label: "SPACER",
    qty: "1",
    parent: "CRSJ3CC02",
  },
  {
    sno: "2",
    partNo: "35S30019",
    label: "POINTER_TIMING_PIN",
    qty: "1",
    parent: "CRSJ3CC02",
  },
  {
    sno: "3",
    partNo: "49SG3072",
    label: "JOINT_TIMING_CASE_COVER",
    qty: "1",
    parent: "CRSJ3CC02",
  },
  {
    sno: "4",
    partNo: "57SG3013_2",
    label: "NUT",
    qty: "5",
    parent: "CRSJ3CC02",
  },
  {
    sno: "5",
    partNo: "74SG3047",
    label: "BOLT",
    qty: "2",
    parent: "CRSJ3CC02",
  },
  {
    sno: "6",
    partNo: "74SG3071_1",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ3CC02",
  },
  {
    sno: "7",
    partNo: "74SG3076",
    label: "BOLT",
    qty: "4",
    parent: "CRSJ3CC02",
  },
  {
    sno: "8",
    partNo: "74SG3077",
    label: "BOLT",
    qty: "2",
    parent: "CRSJ3CC02",
  },
  {
    sno: "9",
    partNo: "74SG4065",
    label: "BOLT",
    qty: "9",
    parent: "CRSJ3CC02",
  },
  {
    sno: "10",
    partNo: "82S30063_1",
    label: "STUD",
    qty: "1",
    parent: "CRSJ3CC02",
  },
  {
    sno: "11",
    partNo: "82S30106",
    label: "STUD",
    qty: "1",
    parent: "CRSJ3CC02",
  },
  {
    sno: "12",
    partNo: "82SG4014",
    label: "STUD",
    qty: "1",
    parent: "CRSJ3CC02",
  },
  {
    sno: "13",
    partNo: "99SG3866",
    label: "COVER_TIMING_CASE_ASSEMBLY",
    qty: "1",
    parent: "CRSJ3CC02",
  },
  {
    sno: "1",
    partNo: "10SG3156",
    label: "MAIN_FILTER_BRACKET",
    qty: "1",
    parent: "CRSJ3IMF03",
  },
  {
    sno: "2",
    partNo: "10SG3185",
    label: "BRACKET_OBD_CONNECTOR",
    qty: "1",
    parent: "CRSJ3IMF03",
  },
  {
    sno: "3",
    partNo: "49SG3046",
    label: "GASKET_INTAKE_MANIFOLD",
    qty: "1",
    parent: "CRSJ3IMF03",
  },
  {
    sno: "4",
    partNo: "54SG3063",
    label: "MANIFOLD_AIR_INTAKE",
    qty: "1",
    parent: "CRSJ3IMF03",
  },
  {
    sno: "5",
    partNo: "57SG3013_3",
    label: "NUT",
    qty: "12",
    parent: "CRSJ3IMF03",
  },
  {
    sno: "6",
    partNo: "74SG3029_1",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ3IMF03",
  },
  {
    sno: "7",
    partNo: "74SG3040_1",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ3IMF03",
  },
  {
    sno: "8",
    partNo: "74SG3047_1",
    label: "BOLT",
    qty: "3",
    parent: "CRSJ3IMF03",
  },
  {
    sno: "9",
    partNo: "74SG3074_1",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ3IMF03",
  },
  {
    sno: "10",
    partNo: "82SG3035",
    label: "STUD_INTAKE MANIFOLD_TO_FILTER_BRACKET",
    qty: "3",
    parent: "CRSJ3IMF03",
  },
  {
    sno: "11",
    partNo: "82SG4010",
    label: "STUD",
    qty: "1",
    parent: "CRSJ3IMF03",
  },
  {
    sno: "12",
    partNo: "DE006125",
    label: "BOOST_PRESSURE_SENSOR",
    qty: "1",
    parent: "CRSJ3IMF03",
  },
  {
    sno: "1",
    partNo: "49SG3047",
    label: "GASKET_EXH_MANIFOLD_TO_CYL _HEAD",
    qty: "1",
    parent: "CRSJ3EMF01",
  },
  {
    sno: "2",
    partNo: "54SG3033",
    label: "EXHAUST MANIFOLD",
    qty: "1",
    parent: "CRSJ3EMF01",
  },
  {
    sno: "3",
    partNo: "33SG3012",
    label: "SPACER_EXHAUST_MANIFOLD",
    qty: "6",
    parent: "CRSJ3EMF01",
  },
  {
    sno: "4",
    partNo: "24SG3041",
    label: "HEAT SHIELD_EXH MANIFOLD_RIGHT",
    qty: "1",
    parent: "CRSJ3EMF01",
  },
  {
    sno: "5",
    partNo: "24SG3035",
    label: "HEAT_SHIELD_EX_MANIFOLD_TOP",
    qty: "1",
    parent: "CRSJ3EMF01",
  },
  {
    sno: "6",
    partNo: "74SG3046",
    label: "SETSCREW",
    qty: "6",
    parent: "CRSJ3EMF01",
  },
  {
    sno: "7",
    partNo: "82SG3008",
    label: "STUD",
    qty: "6",
    parent: "CRSJ3EMF01",
  },
  {
    sno: "8",
    partNo: "2318A604",
    label: "NUT",
    qty: "6",
    parent: "CRSJ3EMF01",
  },
  {
    sno: "1",
    partNo: "350016_2",
    label: "DOWEL",
    qty: "2",
    parent: "CRSJ3BEN01",
  },
  {
    sno: "2",
    partNo: "921062",
    label: "WASHER_TAB",
    qty: "3",
    parent: "CRSJ3BEN01",
  },
  {
    sno: "3",
    partNo: "32161114",
    label: "PLUG",
    qty: "1",
    parent: "CRSJ3BEN01",
  },
  {
    sno: "4",
    partNo: "10SG3150",
    label: "BRACKET_PROTECT_FILTER",
    qty: "1",
    parent: "CRSJ3BEN01",
  },
  {
    sno: "5",
    partNo: "44SG3076",
    label: "ADAPTOR_PLATE_TRANSMISSION",
    qty: "1",
    parent: "CRSJ3BEN01",
  },
  {
    sno: "6",
    partNo: "49S30151",
    label: "JOINT_CYLINDER_BLOCK_TO_FLYWHEEL_HOUSING",
    qty: "1",
    parent: "CRSJ3BEN01",
  },
  {
    sno: "7",
    partNo: "57DE0103",
    label: "NUT",
    qty: "6",
    parent: "CRSJ3BEN01",
  },
  {
    sno: "8",
    partNo: "72S30011",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ3BEN01",
  },
  {
    sno: "9",
    partNo: "74SG3029_2",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ3BEN01",
  },
  {
    sno: "10",
    partNo: "74SG3048",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ3BEN01",
  },
  {
    sno: "11",
    partNo: "92SG3010",
    label: "PLAIN_WASHER_(SPEED_SENSOR)",
    qty: "1",
    parent: "CRSJ3BEN01",
  },
  {
    sno: "12",
    partNo: "99SG3720",
    label: "FLYWHEEL_ASSEMBLY",
    qty: "1",
    parent: "CRSJ3BEN01",
  },
  {
    sno: "13",
    partNo: "DE006123",
    label: "ROTATIONAL_SPEED_SENSOR",
    qty: "1",
    parent: "CRSJ3BEN01",
  },
  {
    sno: "14",
    partNo: "S4007444",
    label: "BOLT",
    qty: "6",
    parent: "CRSJ3BEN01",
  },
  {
    sno: "15",
    partNo: "S4008210",
    label: "STUD",
    qty: "6",
    parent: "CRSJ3BEN01",
  },
  {
    sno: "1",
    partNo: "37424611",
    label: "HOUSING_REAR_END_OIL_SEAL_ASSEMBLY",
    qty: "1",
    parent: "CRSJ3ROS01",
  },
  {
    sno: "2",
    partNo: "49S30190",
    label: "JOINT_REAR_END_OIL_SEAL",
    qty: "1",
    parent: "CRSJ3ROS01",
  },
  {
    sno: "3",
    partNo: "73DE0019",
    label: "SEAL_REAR_END_HOUSING",
    qty: "1",
    parent: "CRSJ3ROS01",
  },
  {
    sno: "4",
    partNo: "74SG3074_2",
    label: "SETSCREW",
    qty: "9",
    parent: "CRSJ3ROS01",
  },
  {
    sno: "1",
    partNo: "490016",
    label: "JOINT",
    qty: "1",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "2",
    partNo: "560244",
    label: "OLIVE",
    qty: "2",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "3",
    partNo: "920798",
    label: "WASHER_TAB",
    qty: "2",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "4",
    partNo: "49SG3067",
    label: "LINK_PIECE_SMALL_END",
    qty: "1",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "5",
    partNo: "49SG3068",
    label: "LINK_PIECE",
    qty: "1",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "6",
    partNo: "57SG3013_4",
    label: "NUT",
    qty: "5",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "7",
    partNo: "62SG3054",
    label: "PIPE_LUB_OIL_DELIVERY",
    qty: "1",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "8",
    partNo: "65S30050",
    label: "PLUG_LUB_OIL_PAN_DRAIN",
    qty: "1",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "9",
    partNo: "73S30025",
    label: "O_RING",
    qty: "1",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "10",
    partNo: "74SG3072",
    label: "SETSCREW",
    qty: "6",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "11",
    partNo: "74SG3081",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "12",
    partNo: "74SG4065_1",
    label: "BOLT",
    qty: "4",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "13",
    partNo: "74SG4067",
    label: "BOLT",
    qty: "2",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "14",
    partNo: "83SG3040",
    label: "SUMP_NVH",
    qty: "1",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "15",
    partNo: "99S31377",
    label: "PIPE_LUB_OIL_SUCTION",
    qty: "1",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "16",
    partNo: "99SG3445",
    label: "JOINT_LUB_OIL_PAN",
    qty: "1",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "17",
    partNo: "S4005711",
    label: "NUT",
    qty: "2",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "18",
    partNo: "S4007403",
    label: "BOLT",
    qty: "2",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "19",
    partNo: "S4008243",
    label: "STUD",
    qty: "2",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "20",
    partNo: "S4008244",
    label: "STUD",
    qty: "3",
    parent: "CRSJ3OPN03",
  },
  {
    sno: "1",
    partNo: "180831",
    label: "CIRCLIP",
    qty: "1",
    parent: "CRSJ3LOP01",
  },
  {
    sno: "2",
    partNo: "74SG3079_2",
    label: "SETSCREW",
    qty: "3",
    parent: "CRSJ3LOP01",
  },
  {
    sno: "3",
    partNo: "SG990116",
    label: "PUMP_LUB_OIL_ASSY",
    qty: "1",
    parent: "CRSJ3LOP01",
  },
  {
    sno: "1",
    partNo: "49SG3091",
    label: "JOINT_LUB_OIL_FILLER",
    qty: "1",
    parent: "CRSJ3LOF03",
  },
  {
    sno: "2",
    partNo: "74SG3081_1",
    label: "SETSCREW",
    qty: "6",
    parent: "CRSJ3LOF03",
  },
  {
    sno: "3",
    partNo: "99SG4463",
    label: "LUB_OIL_FILLER_ASSEMBLY",
    qty: "1",
    parent: "CRSJ3LOF03",
  },
  {
    sno: "1",
    partNo: "17SG3010",
    label: "CONSTANT_TENSION_CLAMP",
    qty: "8",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "2",
    partNo: "20SG3041",
    label: "HOSE_EGR_TO_EGR_COOLER",
    qty: "1",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "3",
    partNo: "20SG3043",
    label: "HOSE_EGR_COOLER_TO_CYLINDER_HEAD",
    qty: "1",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "4",
    partNo: "24SG3042",
    label: "HEAT_SHIELD_EXHAUST_MANIFOLD_LEFT",
    qty: "1",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "5",
    partNo: "49SG3054",
    label: "GASKET_EGR_VALVE_&_COOLER",
    qty: "2",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "6",
    partNo: "57C20006",
    label: "NUT",
    qty: "2",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "7",
    partNo: "57SG3013_5",
    label: "NUT",
    qty: "2",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "8",
    partNo: "62SG3020",
    label: "EXHAUST_GAS_INLET_PIPE_EMF_TO_EGR",
    qty: "1",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "9",
    partNo: "62SG3047",
    label: "EGR_PIPE_EGR_COOLER_TO_IMF",
    qty: "1",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "10",
    partNo: "74SG3027",
    label: "SETSCREW",
    qty: "8",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "11",
    partNo: "74SG3031",
    label: "PLATE_LOP_FLANGE_SCREWS",
    qty: "1",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "12",
    partNo: "74SG3046_1",
    label: "SETSCREW",
    qty: "3",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "13",
    partNo: "74SG3051",
    label: "BOLT",
    qty: "2",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "14",
    partNo: "82S30123",
    label: "STUD",
    qty: "1",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "15",
    partNo: "82SG3005",
    label: "STUD",
    qty: "2",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "16",
    partNo: "99SG3247",
    label: "MOTORIZED_EGR",
    qty: "1",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "17",
    partNo: "99SG3510",
    label: "ASSEMBLY_PIPE_BLOCK_TO_EGR",
    qty: "1",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "18",
    partNo: "99SG3248",
    label: "EGR_COOLER_10_KW",
    qty: "1",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "19",
    partNo: "S4007421",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "20",
    partNo: "20SG3078",
    label: "HOSE",
    qty: "1",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "21",
    partNo: "20SG3079",
    label: "HOSE",
    qty: "1",
    parent: "CRSJ3EGR01",
  },
  {
    sno: "1",
    partNo: "09DE0006_1",
    label: "BOLT_BANJO",
    qty: "2",
    parent: "CRSJ3TUR02",
  },
  {
    sno: "2",
    partNo: "17SG3010_1",
    label: "CONSTANT_TENSION_CLAMP",
    qty: "1",
    parent: "CRSJ3TUR02",
  },
  {
    sno: "3",
    partNo: "20SG3039",
    label: "ELBOW_TURBOCHARGER_COMPRESSOR_OUTLET_CENTURION",
    qty: "1",
    parent: "CRSJ3TUR02",
  },
  {
    sno: "4",
    partNo: "49DE0162",
    label: "JOINT_TURBOCHARGER_OIL_DRAIN",
    qty: "1",
    parent: "CRSJ3TUR02",
  },
  {
    sno: "5",
    partNo: "49SG3048",
    label: "GASKET_EXH_MANIFOLD_TO_TURBOCHARGER",
    qty: "1",
    parent: "CRSJ3TUR02",
  },
  {
    sno: "6",
    partNo: "49SG3105",
    label: "GASKET_TURBO_COMPRESSOR_OUTLET",
    qty: "1",
    parent: "CRSJ3TUR02",
  },
  {
    sno: "7",
    partNo: "57SG4015",
    label: "NUT",
    qty: "3",
    parent: "CRSJ3TUR02",
  },
  {
    sno: "8",
    partNo: "74SG3027_1",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ3TUR02",
  },
  {
    sno: "9",
    partNo: "74SG3040_2",
    label: "SETSCREW",
    qty: "2",
    parent: "CRSJ3TUR02",
  },
  {
    sno: "10",
    partNo: "82SG3017",
    label: "SPECIAL_STUD_EXHAUST_TO_TURBO",
    qty: "3",
    parent: "CRSJ3TUR02",
  },
  {
    sno: "11",
    partNo: "92DE0009_2",
    label: "WASHER",
    qty: "4",
    parent: "CRSJ3TUR02",
  },
  {
    sno: "12",
    partNo: "99SG3281",
    label: "PIPE_ASSEMBLY_TURBOCHARGER_OIL_FEED",
    qty: "1",
    parent: "CRSJ3TUR02",
  },
  {
    sno: "13",
    partNo: "DE007021",
    label: "TURBOCHARGER_WITH_WASTE_GATE",
    qty: "1",
    parent: "CRSJ3TUR02",
  },
  {
    sno: "14",
    partNo: "SG990048",
    label: "PIPE-TURBO_OIL_DRAIN",
    qty: "1",
    parent: "CRSJ3TUR02",
  },
  {
    sno: "1",
    partNo: "10SG3025",
    label: "ENGINE_LIFTING_BRACKET_FRONT",
    qty: "1",
    parent: "CRSJ3LHK01",
  },
  {
    sno: "2",
    partNo: "74SG3048_1",
    label: "SETSCREW",
    qty: "4",
    parent: "CRSJ3LHK01",
  },
  {
    sno: "3",
    partNo: "94SG3023",
    label: "ENGINE_HOOK_LIFTING",
    qty: "1",
    parent: "CRSJ3LHK01",
  },
  {
    sno: "1",
    partNo: "10SG3141",
    label: "BRACKET_ALTERNATOR",
    qty: "1",
    parent: "CRSJ3ELT02",
  },
  {
    sno: "2",
    partNo: "57SG3013_6",
    label: "NUT",
    qty: "3",
    parent: "CRSJ3ELT02",
  },
  {
    sno: "3",
    partNo: "57SG4015_1",
    label: "NUT",
    qty: "1",
    parent: "CRSJ3ELT02",
  },
  {
    sno: "4",
    partNo: "74SG3059",
    label: "BOLT",
    qty: "1",
    parent: "CRSJ3ELT02",
  },
  {
    sno: "5",
    partNo: "S4008217_1",
    label: "STUD",
    qty: "3",
    parent: "CRSJ3ELT02",
  },
  {
    sno: "1",
    partNo: "2654408",
    label: "FILTER_OIL",
    qty: "1",
    parent: "CRSJ3LFT01",
  },
  {
    sno: "2",
    partNo: "37764251",
    label: "HEAD_LUB_OIL_FILTER",
    qty: "1",
    parent: "CRSJ3LFT01",
  },
  {
    sno: "3",
    partNo: "49S30201",
    label: "JOINT_LUB_OIL_FILTER",
    qty: "1",
    parent: "CRSJ3LFT01",
  },
  {
    sno: "4",
    partNo: "74SG3045",
    label: "BOLT",
    qty: "2",
    parent: "CRSJ3LFT01",
  },
  {
    sno: "1",
    partNo: "20SG3014",
    label: "CONNECTION_WATER_INLET",
    qty: "1",
    parent: "CRSJ3WIC01",
  },
  {
    sno: "2",
    partNo: "49SG3074",
    label: "JOINT_WATER_INLET_CONNECTION",
    qty: "1",
    parent: "CRSJ3WIC01",
  },
  {
    sno: "3",
    partNo: "74SG3074_3",
    label: "SETSCREW",
    qty: "1",
    parent: "CRSJ3WIC01",
  },
  {
    sno: "4",
    partNo: "74SG3076_1",
    label: "BOLT",
    qty: "1",
    parent: "CRSJ3WIC01",
  },
  {
    sno: "1",
    partNo: "20SG3068",
    label: "HOSE",
    qty: "1",
    parent: "CRSJ3WTP02",
  },
  {
    sno: "2",
    partNo: "20SG3123",
    label: "WATER_OUTLET_HOSE",
    qty: "1",
    parent: "CRSJ3WTP02",
  },
  {
    sno: "3",
    partNo: "49S30196",
    label: "JOINT_WATER_PUMP_BACK_PLATE",
    qty: "1",
    parent: "CRSJ3WTP02",
  },
  {
    sno: "4",
    partNo: "57SG3013_7",
    label: "NUT",
    qty: "4",
    parent: "CRSJ3WTP02",
  },
  {
    sno: "5",
    partNo: "82S20071",
    label: "STUD",
    qty: "4",
    parent: "CRSJ3WTP02",
  },
  {
    sno: "6",
    partNo: "94S20189",
    label: "PLATE_WATER_PUMP_BACK",
    qty: "1",
    parent: "CRSJ3WTP02",
  },
  {
    sno: "7",
    partNo: "99SG3826",
    label: "WATER_PUMP_ASSEMBLY",
    qty: "1",
    parent: "CRSJ3WTP02",
  },
  {
    sno: "8",
    partNo: "S4001800",
    label: "CLIP",
    qty: "2",
    parent: "CRSJ3WTP02",
  },
  {
    sno: "9",
    partNo: "S4001801",
    label: "CLIP",
    qty: "2",
    parent: "CRSJ3WTP02",
  },
  {
    sno: "1",
    partNo: "49S30197",
    label: "JOINT_WATER_OUTLET _CONNECTION",
    qty: "1",
    parent: "CRSJ3WOC02",
  },
  {
    sno: "2",
    partNo: "20SG3124",
    label: "WATER OUTLET CONNECTION",
    qty: "1",
    parent: "CRSJ3WOC02",
  },
  {
    sno: "3",
    partNo: "82SG4016",
    label: "STUD",
    qty: "2",
    parent: "CRSJ3WOC02",
  },
  {
    sno: "4",
    partNo: "57DE0103_1",
    label: "NUT",
    qty: "4",
    parent: "CRSJ3WOC02",
  },
  {
    sno: "5",
    partNo: "92DE0023",
    label: "WASHER",
    qty: "4",
    parent: "CRSJ3WOC02",
  },
  {
    sno: "6",
    partNo: "DE006051",
    label: "THERMOSTAT",
    qty: "1",
    parent: "CRSJ3WOC02",
  },
  {
    sno: "1",
    partNo: "20SG3007",
    label: "UNION_DIPSTICK",
    qty: "1",
    parent: "CRSJ3DIP01",
  },
  {
    sno: "2",
    partNo: "32SG3008",
    label: "DIPSTICK_ASSEMBLY",
    qty: "1",
    parent: "CRSJ3DIP01",
  },
  {
    sno: "1",
    partNo: "360045",
    label: "EXTENSION_FAN",
    qty: "1",
    parent: "CRSJ3EXT01",
  },
  {
    sno: "2",
    partNo: "920645",
    label: "WASHER_TAB",
    qty: "2",
    parent: "CRSJ3EXT01",
  },
  {
    sno: "3",
    partNo: "S4007405",
    label: "BOLT",
    qty: "4",
    parent: "CRSJ3EXT01",
  },
];

// let selectedPart = null;
// let selectedSubPart = null;

// // Function to toggle background color on click
// function onPartClick(event) {
//   const clickedItem = event.target;
//   if (selectedPart !== null) {
//     selectedPart.style.backgroundColor = "";
//   }
//   clickedItem.style.backgroundColor =
//     clickedItem.style.backgroundColor === "" ? "lightcoral" : "white";
//   selectedPart = clickedItem;
//   if (clickedItem.getAttribute("id")) {
//     renderChildPartsList(clickedItem.getAttribute("id"));
//   }
// }

// function onChildClick(event) {
//   const clickedItem = event.target;
//   if (selectedSubPart !== null) {
//     selectedSubPart.style.backgroundColor = "";
//   }
//   clickedItem.style.backgroundColor =
//     clickedItem.style.backgroundColor === "" ? "lightcoral" : "white";
//   selectedSubPart = clickedItem;
// }

// // Function to render the list in HTML
// function renderList() {
//   // Get the container element
//   const container = document.getElementById("main-parts");

//   // Create an unordered list
//   const ul = document.createElement("ul");

//   // Loop through the list of people and create list items
//   MAIN_PART_LIST.forEach((part) => {
//     const li = document.createElement("li");
//     li.textContent = `${part.sno}, ${part.label}, ${part.id}`;
//     li.setAttribute("class", "part");
//     li.setAttribute("id", part.id);
//     li.addEventListener("click", onPartClick);
//     ul.appendChild(li);
//   });

//   // Append the list to the container
//   container.appendChild(ul);
// }

// // Function to render the list in HTML
// function renderChildPartsList(parentId) {
//   // Get the container element
//   const container = document.getElementById("child-parts");
//   const subPartButtons = document.getElementById("sub-part-buttons");

//   // Create an unordered list
//   const ul = document.createElement("ul");
//   const subParts = CHILD_PART_LIST.filter((p) => p.parent === parentId);

//   // Loop through the list of people and create list items
//   subParts.forEach((part) => {
//     const li = document.createElement("li");
//     li.textContent = `${part.sno}, ${part.label}, ${part.partNo}`;
//     li.setAttribute("class", "part");
//     li.setAttribute("id", `${parentId}_${part.partNo}`);
//     li.addEventListener("click", onChildClick);
//     ul.appendChild(li);
//   });

//   while (container.firstChild) {
//     container.firstChild.remove();
//   }
//   if (subParts.length > 0) {
//     subPartButtons.style.display = "block";
//   } else {
//     subPartButtons.style.display = "none";
//   }
//   // Append the list to the container
//   container.appendChild(ul);
// }
// function explode() {}

// function assemble() {}

// function show() {}

// // Call the renderList function when the page is loaded
// window.onload = renderList;
