import React, { useState, useEffect } from "react";
import "./index.scss";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Grid } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useNavigate } from "react-router-dom";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { HTTP_METHODS, plainApi } from "../../utils/http-service";

const Footer = () => {
  const navigate = useNavigate();
  const [newsData, setNewsData] = useState({});
  const getNewsData = () => {
    plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.NEWS}`, null, {
      page: 1,
      limit: 3,
      sortBy: "-createdAt",
    })
      .then((res) => {
        setNewsData(res);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    getNewsData();
  }, []);

  return (
    <footer className="footer">
      <Grid
        container
        spacing={{ md: 5, xs: 0 }}
        md={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          borderBottom: "1px solid gray",
        }}
      >
        <Grid
          item
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { md: "center", xs: "flex-start" },
          }}
          xs={5}
        >
          <h1 className="footer-title">Our Products</h1>
          <div>
            <span className="product-con">
              <CheckBoxIcon fontSize="small" sx={{ mr: 1.5 }} /> <a>Engines</a>
            </span>
            <span className="product-con">
              <CheckBoxIcon fontSize="small" sx={{ mr: 1.5 }} />{" "}
              <a>Spare Parts</a>
            </span>
            <span className="product-con">
              <CheckBoxIcon fontSize="small" sx={{ mr: 1.5 }} /> <a>Paints</a>
            </span>
          </div>
        </Grid>
        <Grid
          item
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { md: "center", xs: "flex-start" },
          }}
          xs={7}
        >
          <h1 className="footer-title">CONTACT US</h1>
          <div>
            <span className="product-con">
              <span className="si-hide-mobile">
                <LocationOnIcon fontSize="small" sx={{ mr: 1.5 }} />
              </span>

              <p
                style={{
                  textAlign: "start",
                  margin: 0,
                  wordBreak: "break-all",
                }}
              >
                861 / 862 Anna Salai, Chennai 600 002, Tamilnadu, INDIA
              </p>
            </span>
            <span className="product-con">
              <LocalPhoneIcon
                fontSize="small"
                sx={{ mr: 1.5 }}
                className="si-hide-mobile"
              />{" "}
              <a> 91 + 44 2858 4918, 2857 7244</a>
            </span>
            <span className="product-con">
              <MailIcon
                fontSize="small"
                sx={{ mr: 1.5 }}
                className="si-hide-mobile"
              />{" "}
              <a>marketing@simpson.co.in</a>
            </span>
          </div>
        </Grid>
        <Grid
          item
          md={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className="si-hide-mobile"
        >
          <h1>News & Events</h1>
          <div style={{ textAlign: "left" }}>
            {newsData?.results?.map((news) => {
              return <p>{news?.title}</p>;
            })}
          </div>
        </Grid>
      </Grid>
      <div className="menu-bottom">
        <p className="link-text" onClick={() => navigate("/faq")}>
          FAQ
        </p>
        <p className="link-text">Products</p>
        <p className="link-text">Support</p>
        <p className="link-text">Network</p>
        <p className="link-text"> Privacy Policy</p>
      </div>
    </footer>
  );
};

export default Footer;
