import { Grid, Skeleton } from "@mui/material";
import "./index.scss";
import React, { useState } from "react";

const CardSkeleton = ({ isLoadingApi, listLength = 12 }) => {
  const [cardList, setCardList] = useState(
    new Array(listLength).fill(Math.floor(Math.random() * 100) + 1)
  );
  return (
    isLoadingApi && (
      <div className="card-skeleton-list-con">
        <Grid container spacing={4}>
          {cardList?.map(() => {
            return (
              <Grid item md={3}>
                <Skeleton width="100%" height={250} variant="rounded" />
              </Grid>
            );
          })}
        </Grid>
      </div>
    )
  );
};

export default CardSkeleton;
