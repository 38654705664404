import React from "react";
import { Grid, Typography, Card, CardContent } from "@mui/material";
import SideContent from "../info-for-shareholder/side-content";
import Footer from "../../component/footer";
import PAINT1 from "../../resources/paints/addisons.jpg";
import ADDISONS_PAINTS from "../../resources/paints/addisons-paints.jpg";
const Paints = () => {
  return (
    <div className="page-load">
      <div className="shareholder-info-con">
        <Grid container spacing={7}>
          <Grid item md={8} sx={{ gridGap: 10 }}>
            <Typography
              variant="h4"
              sx={{
                borderBottom: "1px solid #ded8d7",
                paddingBottom: 2,
                mb: 3,
              }}
              className="primary-text"
            >
              Paints
            </Typography>
            <Typography variant="body1">
              <b>ADDISONS PAINTS & CHEMICALS LIMITED (APC)</b> was established
              in 1948, when surface coatings of all categories were imported in
              a fiercely competitive market. After independence, the ascent of
              industrialization gave a push to establishment of industrial units
              and their products demanded a variety of finishes of different
              types.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              The company commenced its manufacturing activity to cater to their
              demands in collaboration with Hardie Trading Limited, Australia,
              and marketed products under brand names SPARTAN and TROJAN.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Addison Paints & Chemicals Ltd. is now a part of Simpson & Co.
              Ltd.
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item md={4}>
                <img src={PAINT1} alt="ADDISONS" />
              </Grid>
              <Grid item md={3}>
                <img src={ADDISONS_PAINTS} alt="ADDISONS_PAINTS" />
              </Grid>
            </Grid>
            <Card sx={{ mt: 5 }}>
              <CardContent>
                <Grid container>
                  <Grid
                    item
                    md={6}
                    sx={{
                      borderRight: { md: "1px solid lightGray", xs: "none" },
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: 2 }}>
                      Engine Parts - Contact Details
                    </Typography>
                    <Typography variant="body2">
                      Merchandising Division, Simpson & Co Ltd.,
                    </Typography>
                    <Typography variant="body2">861/862 Anna Salai,</Typography>
                    <Typography variant="body2" sx={{ mb: 2 }}>
                      Chennai 600 002
                    </Typography>
                    <Typography variant="body2">Tel: 044-28577246</Typography>
                    <Typography variant="body2">
                      E.mail: sraghothaman@simpson.co.in
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body2" className="file-link">
                      Branches and RO Contacts
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={2}>
            <SideContent />
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default Paints;
