import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "../../core/navbar/nav-bar";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../slice/user-slice";
import { getOfflineData } from "../../utils/offline-services";
import { HTTP_METHODS, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { addItem } from "../../slice/cart-slice";
const AppLayout = ({ children }) => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (getOfflineData("user")) {
      dispatch(setUserDetails(getOfflineData("user")));
      getMyCart();
    }
  }, []);
  const getMyCart = () => {
    plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.CART}`)
      .then((response) => {
        const { cartItems = [] } = response;
        dispatch(addItem(cartItems));
      })
      .catch(() => {});
  };
  return (
    <div>
      {pathname !== "/login" && <Navbar />}

      <div>{children}</div>
    </div>
  );
};
export default AppLayout;
