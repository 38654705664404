import { Button, Chip } from "@mui/material";
import moment from "moment";
import { DownloadBtn } from "../../component/download-btn";
import {
  convertToCamelCaseFromUnderScore,
  removeUnderscoreAndAfter,
} from "../../utils/helper";
export const getHeaderConfig = () => {
  const config = [
    {
      label: "Order Id",
      id: "orderId",
    },
    {
      label: "Date",
      id: "createdAt",
      render: (data) => {
        const date = moment(data.createdAt).format("DD-MM-YYYY, hh:mm a");
        return date;
      },
    },
    {
      label: "Order By",
      id: "userId",
      render: (data) => {
        return data?.userId?.name;
      },
    },
    {
      label: "Total Price(INR)",
      id: "totalSum",
    },
    {
      label: "Status",
      id: "status",
      render: (data) => {
        const status = ORDER_STATUS.find(
          (ele) => ele.value === data?.status
        )?.label;
        return (
          <Chip
            label={status || data?.status}
            size="small"
            color={data?.status === "COMPLETED" ? "success" : "warning"}
            variant="outlined"
          />
        );
      },
    },
    {
      label: "Order Items",
      id: "orderItems",
      render: (data, onClick) => {
        const itemsToShow = data.orderItems.slice(0, 3);

        return (
          <Button
            color="info"
            size="small"
            onClick={() => onClick(data, "SHOW_ORDERS")}
          >
            show details
          </Button>
        );
      },
    },
    {
      label: "Action",
      id: "action",
      render: (data, onClick) => {
        return (
          <div style={{ display: "flex" }}>
            <DownloadBtn onClick={() => onClick(data, "DOWNLOAD_EXCEL")} />
            {data?.status !== "COMPLETED" && (
              <Button
                size="small"
                variant="outlined"
                sx={{ ml: 2 }}
                onClick={() => onClick(data, "UPDATE_STATUS")}
              >
                mark completed
              </Button>
            )}
          </div>
        );
      },
    },
  ];
  return config;
};
export const getItemHeaderConfig = () => {
  const config = [
    {
      label: "Product Name",
      id: "label",
      render: (data) => {
        const { partId } = data;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <img src={partId?.img} width="50px" />
            <span style={{ marginLeft: "1rem" }}>
              {convertToCamelCaseFromUnderScore(partId?.label)}
            </span>
          </div>
        );
      },
    },
    {
      label: "Engine Name",
      id: "engineName",
      render: (data) => {
        const { partId } = data;
        return partId?.engineName;
      },
    },
    {
      label: "List Number",
      id: "listNumber",
      render: (data) => {
        const { partId } = data;
        return partId?.listNumber;
      },
    },
    {
      label: "Part Number",
      id: "partNo",
      render: (data) => {
        const { partId } = data;
        return removeUnderscoreAndAfter(partId?.partNo);
      },
    },

    {
      label: "Quantity",
      id: "quantity",
    },
    {
      label: "Item Price",
      id: "totalPrice",
    },
  ];
  return config;
};
export const ORDER_STATUS = [
  { label: "Pending", value: "OPEN" },
  { label: "Completed", value: "COMPLETED" },
  { label: "All", value: "ALL" },
];
