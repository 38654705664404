import React, { useEffect, useState } from "react";
import { getOfflineData } from "../../utils/offline-services";
import { useNavigate } from "react-router-dom";
import MuiTable from "../../component/mui-table";
import {
  getKowledgeBaseHeaderConfig,
  getReleaseNotesHeaderConfig,
} from "./config";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import CustomModal from "../../component/modal";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import DeleteModal from "../../core/delete-modal";
import ChangesSection from "./release-changes";

const AdminReleaseNotes = () => {
  const [formData, setFormData] = useState({});
  const [data, setData] = useState({});
  const [filters, setFilters] = useState({
    sortBy: "-createdAt",
    page: 1,
    limit: 10,
  });
  const [editReleaseNotes, setEditReleaseNotes] = useState({
    isOpen: false,
    data: {},
  });
  const [readMoreModal, setReadMoreModal] = useState({
    isOpen: false,
    text: "",
  });
  const [changes, setChanges] = useState([]);
  const [deleteModal, setDeleteModal] = useState({ isOpen: false, data: {} });
  const navigate = useNavigate();
  const user = getOfflineData("user");
  const updateHandler = (data, type) => {
    switch (type) {
      case "EDIT":
        setEditReleaseNotes({
          isOpen: true,
          data: data,
        });
        setFormData({
          version: data.version,
          releaseDate: new Date(data.releaseDate).toISOString().split("T")[0],
          description: data.description,
          title: data.title,
        });
        if (data.changes.length > 0) {
          const newChanges = data.changes.map((ele) => ({
            type: ele?.type,
            description: ele?.description,
          }));
          setChanges(newChanges);
        }

        break;
      case "DELETE":
        setDeleteModal({
          isOpen: true,
          data: data,
        });
        break;
      default:
        break;
    }
  };
  const updateknowledgeBase = () => {
    const payload = {};
    for (let i in formData) {
      if (formData[i]) {
        payload[i] = formData[i];
      }
    }
    const releaseChnages = changes.map((ele) => ({
      type: ele?.type,
      description: ele?.description,
    }));
    payload["changes"] = releaseChnages;
    if (editReleaseNotes?.data?.id) {
      invokeApi(
        HTTP_METHODS.PUT,
        `${HOSTNAME}${REST_URLS.RELEASE_NOTES}/${editReleaseNotes.data.id}`,
        payload
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Updated Successfully", { autoClose: 1000 });
            setFormData({});
            setEditReleaseNotes({
              isOpen: false,
              data: {},
            });
            getData({ ...filters, page: 1 });
          }
        })
        .catch((err) => console.error(err));
    } else {
      invokeApi(
        HTTP_METHODS.POST,
        `${HOSTNAME}${REST_URLS.RELEASE_NOTES}`,
        payload
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Created Successfully", { autoClose: 1000 });
            setFormData({});
            setEditReleaseNotes({
              isOpen: false,
              data: {},
            });
            getData({ ...filters, page: 1 });
          }
        })
        .catch((err) => console.error(err));
    }
  };
  const getData = (filter) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.RELEASE_NOTES}`,
      null,
      filter
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setData(res);
        }
      })
      .catch((err) => console.error(err));
  };
  const readMoreFunc = (data) => {
    setReadMoreModal(data);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevVal) => ({ ...prevVal, [name]: value }));
  };
  const deleteHandler = () => {
    if (deleteModal?.data?.id) {
      invokeApi(
        HTTP_METHODS.DELETE,
        `${HOSTNAME}${REST_URLS.RELEASE_NOTES}/${deleteModal?.data?.id}`
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
            setDeleteModal({
              isOpen: false,
              data: {},
            });
          } else {
            toast.success("Deleted Successfully", { autoClose: 2000 });
            setDeleteModal({
              isOpen: false,
              data: {},
            });
            getData({ ...filters, page: 1 });
          }
        })
        .catch((err) => console.error(err));
    }
  };
  useEffect(() => {
    if (editReleaseNotes.isOpen && editReleaseNotes.data.changes) {
      setChanges(editReleaseNotes.data.changes);
    } else {
      setChanges([]);
    }
  }, [editReleaseNotes]);
  useEffect(() => {
    if (user?.role !== "admin") {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    getData(filters);
  }, []);
  return (
    <div>
      <div className="users-con">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" className="primary-text">
            Release Notes
          </Typography>
          <Button
            size="small"
            variant="outlined"
            sx={{ mb: 1 }}
            onClick={() => {
              setEditReleaseNotes({ isOpen: true, data: {} });
            }}
          >
            Add Release Notes
          </Button>
        </div>

        <MuiTable
          columnsList={getReleaseNotesHeaderConfig()}
          dataList={data?.results || []}
          filters={filters}
          pageCount={data?.totalPages}
          onClick={updateHandler}
          maxWords="9"
          showAllFunc={readMoreFunc}
          onChange={(page) => {
            setFilters({
              ...filters,
              page,
            });
            getData({
              ...filters,
              page,
            });
          }}
        ></MuiTable>

        {data?.results?.length === 0 && (
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            Release Notes Not Found
          </Typography>
        )}
        {editReleaseNotes.isOpen && (
          <CustomModal
            title={
              editReleaseNotes?.data.id
                ? "Edit Release Note"
                : "Create Release Note"
            }
            onClose={() => {
              setEditReleaseNotes({
                isOpen: false,
                data: {},
              });
              setFormData({});
            }}
            contentClassName="set-modal-styles "
          >
            <Box sx={{ gridGap: "12px", display: "grid" }}>
              <TextField
                label="Title"
                name="title"
                size="small"
                value={formData?.title}
                onChange={handleChange}
                required
                fullWidth
              />
              <TextField
                label="Version"
                name="version"
                size="small"
                required
                value={formData?.version}
                onChange={handleChange}
                fullWidth
              />
              <TextField
                label="Description"
                name="description"
                size="small"
                value={formData?.description}
                onChange={handleChange}
                fullWidth
                required
                multiline
                minRows={2}
              />
              <TextField
                label="Release Date"
                name="releaseDate"
                size="small"
                type="date"
                value={formData?.releaseDate}
                onChange={handleChange}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
              <Typography variant="body2" sx={{ color: "gray", mt: 1 }}>
                Select Release Category
              </Typography>
              <ChangesSection changes={changes} setChanges={setChanges} />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={updateknowledgeBase}
                  disabled={
                    !formData?.version ||
                    !formData?.title ||
                    !formData?.description ||
                    changes.length === 0
                  }
                  sx={{ mb: 10 }}
                >
                  Save
                </Button>
              </div>
            </Box>
          </CustomModal>
        )}
        {readMoreModal?.isOpen && (
          <CustomModal
            onClose={() => {
              setReadMoreModal({
                isOpen: false,
                text: "",
              });
            }}
          >
            <Typography variant="body1">{readMoreModal?.text}</Typography>
          </CustomModal>
        )}
        {deleteModal?.isOpen && (
          <DeleteModal
            onClose={() =>
              setDeleteModal({
                isOpen: false,
                data: {},
              })
            }
            deleteFunc={deleteHandler}
          />
        )}
      </div>
    </div>
  );
};

export default AdminReleaseNotes;
