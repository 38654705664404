import React from "react";
import "./index.scss";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import Footer from "../../component/footer";
import SideImgGallery from "../history/side-img-gallery";
import SIVASAILAM_IMG from "../../resources/Sivasailam.jpg";
import KRISHNAMOORTHY_IMG from "../../resources/Krishnamoorthy.jpg";
const Leadership = () => {
  return (
    <div className="page-load">
      <div className="leadership-content drop-in">
        <Grid container spacing={5}>
          <Grid item md={9} xs={12}>
            <Typography
              variant="h4"
              className="primary-text"
              sx={{
                mb: 2,
                borderBottom: "1px solid #dbdbd9",
                paddingBottom: "0.5rem",
                fontSize: { md: "2.5rem", xs: "2rem" },
              }}
            >
              Leadership
            </Typography>
            <Card>
              <CardContent>
                <div>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    <b>Sri. A. Sivasailam,</b> Chairman (1968-2011)
                  </Typography>
                  <Grid container className="learship-card-con">
                    <Grid item md={2} xs={8}>
                      <img
                        src={SIVASAILAM_IMG}
                        alt="Sri. A. Sivasailam"
                        width="100%"
                      />
                    </Grid>
                    <Grid item md={9} xs={12}>
                      <Typography
                        variant="body1"
                        sx={{
                          mb: { md: 3, xs: 0 },
                          fontSize: "18px",
                          mt: { md: 1, xs: 0 },
                        }}
                      >
                        Excellence is not just something that we engineer,
                        inspect and input into our products.
                      </Typography>
                      <Typography variant="body1" sx={{ fontSize: "18px" }}>
                        It is an innate desire to obtain the best that comes
                        from within each one of us. It defines our lives at work
                        and at home and ripples out into the world around us.
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    <b>Mr. A. Krishnamoorthy,</b> Chairman & Managing Director
                  </Typography>
                  <Grid container className="learship-card-con">
                    <Grid item md={9} xs={12}>
                      <Typography variant="body1" sx={{ fontSize: "18px" }}>
                        The growth has been sound because the roots are strong.
                        The will to weather the challenges and the spirit to
                        seize new opportunities have sprung from the values
                        instilled by the Group's illustrious Founder, Shri. S.
                        Anantharamakrishnan. And these values will continue to
                        be the basis for building our future.
                      </Typography>
                    </Grid>
                    <Grid item md={2} xs={8}>
                      <img
                        src={KRISHNAMOORTHY_IMG}
                        alt="Mr. A. Krishnamoorthy"
                        width="100%"
                      />
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    <b>Sri. A. Sivasailam,</b> Chairman (1968-2011)
                  </Typography>
                </div>
                <div>
                  <Typography variant="h5" sx={{ mb: 2 }}>
                    <b>Sri. A. Sivasailam,</b> Chairman (1968-2011)
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item md={3} xs={12} sx={{ overflow: "hidden" }}>
            <SideImgGallery />
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default Leadership;
