import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Theme } from "./utils/theme";
import AppRoutes from "./routes";
import store from "./store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "animate.css/animate.compat.css";
import Loader from "./core/loader";
const theme = createTheme(Theme);
function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Loader />
        <ToastContainer autoClose={2000} />
        <AppRoutes />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
