import React, { useState } from "react";
import "./index.scss";
import { Grid, Typography } from "@mui/material";
import Footer from "../../component/footer";
import MILESTONES_IMG from "../../resources/milstones.jpg";
const Milestones = () => {
  return (
    <div className="page-load">
      <div className="brands-con drop-in">
        <Typography
          variant="h3"
          sx={{ mb: 3, fontSize: { md: "2rem", xs: "1.5rem" } }}
          className="primary-text"
        >
          Milestones
        </Typography>
        <Grid container>
          <Grid item md={8} xs={12}>
            <div className="milestone-table">
              <table width="100%">
                <tbody>
                  <tr>
                    <td align="center" className="blue">
                      2018
                    </td>
                    <td> 175 Years celebrations of Simpson</td>
                  </tr>

                  <tr>
                    <td align="center" className="blue">
                      2016
                    </td>
                    <td>
                      Successful foray into Italy and Turkey global markets
                    </td>
                  </tr>
                  <tr>
                    <td align="center" className="blue">
                      2014
                    </td>
                    <td>Achieved all time record sale of 180,000 engines</td>
                  </tr>
                  <tr>
                    <td width="14%" align="center" className="blue">
                      2013
                    </td>
                    <td width="86%">
                      Launch of new Training Centre at Sembium, Chennai
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="2" align="center" className="blue">
                      2012
                    </td>
                    <td>
                      Commissioning of new Emission Lab at Sembium Plant,
                      Chennai
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Started making new generation SJV series and compact SC213
                      engines
                    </td>
                  </tr>
                  <tr>
                    <td align="center" className="blue">
                      2007
                    </td>
                    <td>License granted to make 120,000 engines, annually </td>
                  </tr>
                  <tr>
                    <td rowspan="2" align="center" className="blue">
                      2005
                    </td>
                    <td>
                      Launch of the fully air-conditioned “J” Hook assembly line
                      at Sembium Plant{" "}
                    </td>
                  </tr>
                  <tr>
                    <td> “J” Centenary celebrations</td>
                  </tr>
                  <tr>
                    <td align="center" className="blue">
                      2001
                    </td>
                    <td>
                      Started making new generation SJ series engines with
                      technical support from AVL, Austria
                    </td>
                  </tr>
                  <tr>
                    <td align="center" className="blue">
                      1999
                    </td>
                    <td>
                      Launch of Oracle-based ERP, Full-fledged Research &amp;
                      Development centre established at Sembium Plant, Chennai.
                    </td>
                  </tr>
                  <tr>
                    <td align="center" className="blue">
                      1997
                    </td>
                    <td>Surpassed annual production of 50,000 engines </td>
                  </tr>
                  <tr>
                    <td align="center" className="blue">
                      1995{" "}
                    </td>
                    <td>
                      Commenced making engines at Kumbakonam Plant with an
                      annual capacity of 24,000 in nos.
                    </td>
                  </tr>
                  <tr>
                    <td align="center" className="blue">
                      1993{" "}
                    </td>
                    <td>Exceeded annual target of 30,000 engines</td>
                  </tr>
                  <tr>
                    <td align="center" className="blue">
                      1992{" "}
                    </td>
                    <td>
                      Introduced S325 &amp; S433 engines for Genset and Marine
                      applications
                    </td>
                  </tr>
                  <tr>
                    <td align="center" className="blue">
                      1991
                    </td>
                    <td>
                      First overseas bulk order for 2,000 nos. S325 engine
                      received from Industrija Masina I Traktora (IMT),
                      Belgarde, Yugoslavia.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Grid>
          <Grid
            item
            md={4}
            sx={{
              display: "flex",
              justifyContent: { md: "flex-end", xs: "center" },
              mt: { md: 0, xs: 3 },
            }}
            xs={12}
          >
            <img src={MILESTONES_IMG} alt="milestones" />
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default Milestones;
