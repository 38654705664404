export const Theme = {
  typography: {
    // fontFamily: ["sans-serif"],
  },
  palette: {
    primary: {
      main: "#00238C",
    },
    secondary: {
      main: "#f7f8fa",
    },
    light: {
      main: "white",
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: "#050400",
      },
      containedPrimary: {
        color: "#102f77",
      },
      text: {
        color: "#fff",
      },
    },
    MuiInputBase: {
      input: {
        background: "#fff",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 30,
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: "#fff",
      },
    },
  },
};
