import { Grid, Typography } from "@mui/material";
import React from "react";
import SideContent from "../info-for-shareholder/side-content";
import Footer from "../../component/footer";
import CYLINDER_BLOCK_IMG from "../../resources/manufacturing/sbm-plant.jpg";
import ENGINE_ASSEMBLY_LINE from "../../resources/manufacturing/engine-assembly-line.jpg";
import STANDARDS_ROOMS from "../../resources/manufacturing/standards-room.jpg";
import ENGINE_TESTING_CELL from "../../resources/manufacturing/engine-testing-cell.jpg";
import AUTOMATIC_PAINTING_CELL from "../../resources/manufacturing/automatic-painting-cell.jpg";
const Manufacturing = () => {
  const imageList = [
    { label: "Cylinder Block & Head Machining Line", url: CYLINDER_BLOCK_IMG },
    { label: "Engine Assembly Line", url: ENGINE_ASSEMBLY_LINE },
    { label: "Standards Room", url: STANDARDS_ROOMS },
    { label: "Engine Testing Cell", url: ENGINE_TESTING_CELL },
    { label: "Automatic Painting Cell", url: AUTOMATIC_PAINTING_CELL },
  ];
  return (
    <div className="page-load">
      <div className="shareholder-info-con drop-in">
        <Grid container spacing={7}>
          <Grid item md={8} sx={{ gridGap: 10 }}>
            <Typography
              variant="h4"
              sx={{
                borderBottom: "1px solid #ded8d7",
                paddingBottom: 2,
                fontSize: { md: "2rem", xs: "1.5rem" },
                mb: 3,
              }}
              className="primary-text"
            >
              Manufacturing
            </Typography>
            <Typography variant="body2">
              Simpson & Co.’s manufacturing plant span across total area of
              4,00,000 sq. ft. It is equipped with state of the art machinery
              and leading-edge cutting technologies across 3 facilities in
              Sembium (Chennai), Anna Salai (Chennai) and Kumbakonam.
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
              New process technologies and the cell layouts ensure precision,
              adaptability, accurate, swiftness and cost efficacy.
            </Typography>
            <Grid container sx={{ mt: 5 }} spacing={3}>
              {imageList?.map((ele, index) => {
                return (
                  <Grid item md={6} key={index}>
                    <img src={ele?.url} width="100%" alt="manufacturing" />
                    <Typography
                      sx={{
                        textAlign: "center",
                        mt: 2,
                        mb: 3,
                        color: "#0092CE",
                      }}
                    >
                      {ele?.label}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item md={2}>
            <div className="si-hide-mobile">
              <SideContent />
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default Manufacturing;
