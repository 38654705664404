import moment from "moment/moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton } from "@mui/material";
export const getKowledgeBaseHeaderConfig = () => {
  const config = [
    { label: "Topic", id: "topic" },
    { label: "Summary", id: "summary" },
    { label: "Description", id: "description" },
    {
      label: "Updated At",
      id: "updatedAt",
      render: (data) => {
        return moment(data?.updatedAt).format("DD-MM-YY");
      },
    },
    {
      label: "Action",
      id: "action",
      render: (data, onClick) => {
        return (
          <Box sx={{ display: "flex" }}>
            <IconButton onClick={() => onClick(data, "EDIT")}>
              <EditIcon fontSize="small" color="primary" />
            </IconButton>
            <IconButton onClick={() => onClick(data, "DELETE")}>
              <DeleteIcon fontSize="small" color="error" />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  return config;
};
export const getNewsHeaderConfig = () => {
  const config = [
    { label: "Title", id: "title" },
    { label: "Description", id: "desc" },
    {
      label: "Published At",
      id: "updatedAt",
      render: (data) => {
        return moment(data?.date).format("DD-MM-YY");
      },
    },
    {
      label: "Action",
      id: "action",
      render: (data, onClick) => {
        return (
          <Box sx={{ display: "flex" }}>
            <IconButton onClick={() => onClick(data, "EDIT")}>
              <EditIcon fontSize="small" color="primary" />
            </IconButton>
            <IconButton onClick={() => onClick(data, "DELETE")}>
              <DeleteIcon fontSize="small" color="error" />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  return config;
};
export const getReleaseNotesHeaderConfig = () => {
  const config = [
    { label: "Title", id: "title" },
    { label: "Version", id: "version" },
    { label: "Description", id: "description" },
    {
      label: "Release Date",
      id: "releaseDate",
      render: (data) => {
        return moment(data?.releaseDate).format("DD-MM-YY");
      },
    },
    {
      label: "Action",
      id: "action",
      render: (data, onClick) => {
        return (
          <Box sx={{ display: "flex" }}>
            <IconButton onClick={() => onClick(data, "EDIT")}>
              <EditIcon fontSize="small" color="primary" />
            </IconButton>
            <IconButton onClick={() => onClick(data, "DELETE")}>
              <DeleteIcon fontSize="small" color="error" />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  return config;
};
