import { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Stack,
  Chip,
  Grid,
  Button,
  IconButton,
  Drawer
} from "@mui/material";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import "./index.scss";
import PhotoViewer from "../../component/photo-viewer";
import { HTTP_METHODS, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";
import { addItem } from "../../slice/cart-slice";
import ItemQty from "../../component/item-qty";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../component/footer";
import { setOfflineData } from "../../utils/offline-services";
import { toast } from "react-toastify";
import { convertToCamelCaseFromUnderScore } from "../../utils/helper";
import StarRatings from "react-star-ratings";
import RecommandedProducts from "./recom-products";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import EngineParts from "../../component/engine-parts";
import {
  CHILD_PART_LIST,
  ENGINE2_CHILD_PART_LIST,
  ENGINE2_MAIN_PART_LIST,
  MAIN_PART_LIST
} from "../../utils/parts_data";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

const parts = {
  SJV436: MAIN_PART_LIST,
  SJV3259: ENGINE2_MAIN_PART_LIST
};

const childParts = {
  SJV436: CHILD_PART_LIST,
  SJV3259: ENGINE2_CHILD_PART_LIST
};

const props = {
  width: 400,
  height: 300,
  zoomPosition: "original",
  img: require("../../resources/engine001.png")
};

const ItemDetails = () => {
  const { itemId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const cartDetails = useSelector(
    state => (state.user && state.cart.items) || {}
  );
  const [itemDetails, setItemDetails] = useState({});
  const [photoList, setPhotoList] = useState([]);
  const [selectedPart, setSelectedPart] = useState({});
  const [selectedSubpart, setSelectedSubpart] = useState({});
  const [open, setOpen] = useState(false);

  const toggleDrawer = newOpen => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    loadData();
    increaseViews();
  }, [itemId]);

  const loadData = () => {
    plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.PART}/${itemId}`)
      .then(response => {
        if (response.id) {
          const randomRating = Math.ceil(4 + Math.random());
          const subPart = childParts[searchParams.get("engine")].find(
            p => p.partNo === response.partNo
          );
          setSelectedPart(
            parts[searchParams.get("engine")].find(
              p => p.id === subPart?.parent
            ) || {}
          );
          setSelectedSubpart(subPart || {});
          setItemDetails({ ...response, quantity: 1, rating: randomRating });
          setPhotoList([
            {
              imageSrc: response.img,
              id: Math.random()
            }
          ]);
          // if (response?.imageGallery.length > 0) {
          //   setPhotoList(
          //     response.imageGallery.map((ele, index) => ({
          //       imageSrc: ele,
          //       id: index + 1
          //     }))
          //   );
          // }
        } else {
          setItemDetails({});
        }
      })
      .catch(err => console.error(err));
  };

  const increaseViews = () => {
    plainApi(
      HTTP_METHODS.PUT,
      `${HOSTNAME}${REST_URLS.INCREASE_VIEWS}/${itemId}`
    )
      .then(response => {})
      .catch(err => console.error(err));
  };

  const onAddToCart = () => {
    const updatedCart = cartDetails.map(ele => ({
      partId: ele?.partId.id,
      quantity: ele.quantity
    }));
    let payload = {
      cartItems: [
        ...updatedCart,
        {
          partId: itemDetails.id,
          quantity: itemDetails.quantity
        }
      ]
    };

    plainApi(HTTP_METHODS.PUT, `${HOSTNAME}${REST_URLS.CART}`, payload)
      .then(response => {
        if (!response.message) {
          getMyCart();
          navigate("/my-cart");
        } else {
          if (response?.message?.includes("duplicate")) {
            toast.info("Part is already in the cart", { autoClose: 2000 });
          } else if (response.code == 401) {
            setOfflineData("redirectRoute", pathname);
          } else {
            toast.error(response.message, { autoClose: 2000 });
          }
        }
      })
      .catch(() => {});
  };

  const getMyCart = () => {
    plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.CART}`)
      .then(response => {
        const { cartItems = [] } = response;
        dispatch(addItem(cartItems));
      })
      .catch(() => {});
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, [itemDetails]);

  const onSelectItem = (part, subPart) => {
    setSelectedPart(part);
    setSelectedSubpart(subPart || {});
    if (subPart?.partNo) {
      getSearchParts(part.id, subPart.partNo);
      setOpen(false);
    }    
  };

  const getSearchParts = (mainPartId, partNo) => {
    const params = {
      modelNumber: searchParams.get("engine")
    };
    if (mainPartId) {
      params.mainPartId = mainPartId;
    }
    if (partNo) {
      params.partNo = partNo;
    }
    plainApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.SEARCH_PARTS}`,
      null,
      params
    )
      .then(response => {
        if (response.length > 0) {
          let details = response[0];
          const randomRating = Math.round(4 + Math.random());
          setItemDetails({ ...details, quantity: 1, rating: randomRating });
          let imagesList = [];
          for (let i in details) {
            if (i === "img") {
              imagesList?.push({ imageSrc: details[i], id: 1010 });
            } else if (i === "imageGallery" && details[i].length > 0) {
              const newList = details[i]?.map((ele, index) => ({
                imageSrc: ele,
                id: index + 1
              }));
              imagesList = [...imagesList, ...newList];
            }
          }
          setPhotoList(imagesList);
        }
      })
      .catch(error => {});
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid
          item
          md={3}
          sx={{
            display: { xs: "none", sm: "flex" } // Hide on xs (mobile), show on sm and above
          }}
        >
          <EngineParts
            selectedPart={selectedPart?.id}
            selectedSubpart={selectedSubpart?.partNo}
            onClick={onSelectItem}
          />
        </Grid>
        <Grid item md={9} sx={12}>
          <Card sx={{ margin: "10px" }}>
            <CardContent sx={{ padding: "10px", borderRadius: "10px" }}>
              <Grid container md={12} spacing={2}>
                <Grid item md={7} sm={12}>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography>
                      <IconButton
                        onClick={() =>
                          navigate({
                            pathname: "/explore-items",
                            search: createSearchParams({
                              engine: searchParams.get("engine"),
                              listNo: searchParams.get("listNo")
                            }).toString()
                          })
                        }
                      >
                        <ArrowBackIosNewIcon />
                      </IconButton>
                      {searchParams.get("engine")} |{" "}
                      {searchParams.get("listNo")}
                    </Typography>
                    <div>
                      <IconButton
                        onClick={toggleDrawer(true)}
                        sx={{
                          display: { sm: "flex", md: "none" }
                        }}
                      >
                        <FilterAltOutlinedIcon />
                      </IconButton>
                      <Drawer open={open} onClose={toggleDrawer(false)}>
                        <EngineParts
                          selectedPart={selectedPart?.id}
                          selectedSubpart={selectedSubpart?.partNo}
                          onClick={onSelectItem}
                        />
                      </Drawer>
                    </div>
                  </div>

                  <PhotoViewer photos={photoList} enableZoom={false} />
                </Grid>
                <Grid item md={5} sm={12}>
                  <div>
                    <Typography variant="h4" sx={{ mb: 1 }}>
                      {convertToCamelCaseFromUnderScore(itemDetails.label)}
                    </Typography>
                    {/* <StarRatings
                      rating={itemDetails.rating}
                      starRatedColor="#f0b81f"
                      numberOfStars={5}
                      name="rating"
                      starDimension="25px"
                      starSpacing="2px"
                    />
                    <sub>
                      <Typography
                        gutterBottom
                        variant="body2"
                        component="span"
                        color="gray"
                      >
                        {"  "} Customer Rating
                      </Typography>
                    </sub> */}
                    {/* <Typography
                          gutterBottom
                          variant="h5"
                          sx={{
                            mt: 2.5,
                            borderBottom: "1px solid #edebeb",
                            pb: 1
                          }}
                        >
                          &#x20B9;{itemDetails.unitPrice}{" "}
                          <Typography
                            gutterBottom
                            variant="body1"
                            component="span"
                            color="gray"
                          >
                            Unit Price incl. VAT
                          </Typography>
                        </Typography> */}
                    <Typography variant="body1" sx={{ color: "gray", mt: 2 }}>
                      <b> Few Items Left</b> - Make it yours
                    </Typography>
                    <div className="add-to-cart-qty-con">
                      <ItemQty
                        onAdd={() =>
                          setItemDetails({
                            ...itemDetails,
                            quantity: Math.min(
                              itemDetails.quantity + 1,
                              itemDetails.maxOrderQuantity || 1
                            )
                          })
                        }
                        onSubtract={() =>
                          setItemDetails({
                            ...itemDetails,
                            quantity: Math.max(itemDetails.quantity - 1, 1)
                          })
                        }
                        qty={itemDetails.quantity || 1}
                        maxOrderQuantity={itemDetails.maxOrderQuantity || 1}
                      />
                      <Button
                        variant="contained"
                        color="error"
                        startIcon={<ShoppingBagIcon />}
                        onClick={onAddToCart}
                      >
                        Add to Cart
                      </Button>
                    </div>
                    <div>
                      <Typography variant="h5" sx={{ mt: 3, mb: 1 }}>
                        Specifications
                      </Typography>

                      <Grid
                        container
                        md={12}
                        sx={{
                          borderBottom: "1px solid #eaeaea",
                          pt: 1,
                          pb: 1
                        }}
                      >
                        <Grid item md={6} sx={{ color: "gray" }}>
                          Model Number
                        </Grid>
                        <Grid item md={6}>
                          {itemDetails?.modelNumber}
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        md={12}
                        sx={{
                          borderBottom: "1px solid #eaeaea",
                          pt: 1,
                          pb: 1
                        }}
                      >
                        <Grid item md={6} sx={{ color: "gray" }}>
                          Part No
                        </Grid>
                        <Grid item md={6}>
                          {itemDetails?.partNo}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        md={12}
                        sx={{
                          borderBottom: "1px solid #eaeaea",
                          pt: 1,
                          pb: 1
                        }}
                      >
                        <Grid item md={6} sx={{ color: "gray" }}>
                          Serial Number
                        </Grid>
                        <Grid item md={6}>
                          {itemDetails?.serialNumber}
                        </Grid>
                      </Grid>
                    </div>

                    {/* <div>
                      <Typography variant="h5" sx={{ mt: 3, mb: 1 }}>
                        Description
                      </Typography>
                      <Typography variant="body2" sx={{ color: "gray" }}>
                        {showMore
                          ? itemDetails?.description
                          : `${
                              itemDetails?.description?.slice(0, 250) ||
                              itemDetails?.description
                            }...`}
                        <Button
                          onClick={() => setShowMore(!showMore)}
                          size="small"
                          color="info"
                        >
                          {showMore ? "Show less" : "Show more"}
                        </Button>
                        {itemDetails?.description}
                      </Typography>
                    </div> */}
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <RecommandedProducts />
        </Grid>
      </Grid>

      <Footer />
    </div>
  );
};
export default ItemDetails;
