import React, { useState, useEffect } from "react";
import "./index.scss";
import MuiTable from "../../component/mui-table";
import { getHeaderConfig, userRoles } from "./config";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import { getOfflineData } from "../../utils/offline-services";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import CustomModal from "../../component/modal";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
const Users = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [editUser, setEditUser] = useState({
    isOpen: false,
    data: {},
  });
  const [formData, setFormData] = useState({
    role: "user",
    address: {
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      pinCode: "",
    },
  });
  const [filters, setFilters] = useState({
    sortBy: "-createdAt",
    page: 1,
    limit: 10,
  });
  const user = getOfflineData("user");
  const getData = (filter) => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.USERS}`, null, filter)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setData(res);
        }
      })
      .catch((err) => console.error(err));
  };
  const updateUser = () => {
    const payload = {};
    for (let i in formData) {
      if (formData[i]) {
        payload[i] = formData[i];
      }
    }

    if (editUser?.data?.id) {
      invokeApi(
        HTTP_METHODS.PUT,
        `${HOSTNAME}${REST_URLS.USERS}/${editUser.data.id}`,
        payload
      )
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("User Updated Successfully", { autoClose: 1000 });
            setFormData({ role: "user" });
            setEditUser({
              isOpen: false,
              data: {},
            });
            getData({ ...filters, page: 1 });
          }
        })
        .catch((err) => console.error(err));
    } else {
      invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.USERS}`, payload)
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("User Created Successfully", { autoClose: 1000 });
            setFormData({ role: "user" });
            setEditUser({
              isOpen: false,
              data: {},
            });
            getData({ ...filters, page: 1 });
          }
        })
        .catch((err) => console.error(err));
    }
  };
  const updateUserHandler = (data) => {
    setEditUser({
      isOpen: true,
      data: data,
    });
    setFormData({
      name: data.name,
      email: data.email,
      role: data.role,
      phoneNumber: data.phoneNumber,
      address: {
        addressLine1: data?.address?.addressLine1,
        addressLine2: data?.address?.addressLine2,
        city: data?.address?.city,
        state: data?.address?.state,
        pinCode: data?.address?.pinCode,
      },
    });
  };
  const inputHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prevVal) => ({ ...prevVal, [name]: value }));
  };
  useEffect(() => {
    if (user?.role === "user") {
      navigate("/");
    } else {
      getData(filters);
    }
  }, []);

  return (
    <div>
      <div className="users-con">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5" className="primary-text">
            Users
          </Typography>
          <Button
            size="small"
            variant="outlined"
            sx={{ mb: 1 }}
            endIcon={<PersonAddAltIcon />}
            onClick={() => {
              setEditUser({ isOpen: true, data: {} });
            }}
          >
            Create User
          </Button>
        </div>

        <MuiTable
          columnsList={getHeaderConfig()}
          dataList={data?.results || []}
          filters={filters}
          pageCount={data?.totalPages}
          onClick={updateUserHandler}
          onChange={(page) => {
            setFilters({
              ...filters,
              page,
            });
            getData({
              ...filters,
              page,
            });
          }}
        ></MuiTable>
        {data?.results?.length === 0 && (
          <Typography variant="h4" sx={{ textAlign: "center" }}>
            No Users
          </Typography>
        )}
        {editUser.isOpen && (
          <CustomModal
            title={editUser?.data.id ? "Edit User" : "Create User"}
            onClose={() => {
              setEditUser({
                isOpen: false,
                data: {},
              });
              setFormData({ role: "user" });
            }}
          >
            <div>
              <TextField
                name="name"
                size="small"
                value={formData?.name}
                onChange={inputHandler}
                label="Name"
                required
                fullWidth
              />
              <TextField
                name="email"
                size="small"
                value={formData?.email}
                onChange={inputHandler}
                label="Email"
                type="email"
                required
                fullWidth
                sx={{ mt: 1 }}
              />

              <TextField
                name="password"
                size="small"
                onChange={inputHandler}
                label="Password"
                type="password"
                required={editUser?.data?.id ? false : true}
                fullWidth
                sx={{ mt: 1 }}
              />
              <TextField
                name="phoneNumber"
                size="small"
                value={formData?.phoneNumber}
                onChange={inputHandler}
                label="Mobile Number"
                type="number"
                fullWidth
                sx={{ mt: 1 }}
              />
              <p>Address Details</p>
              <TextField
                name="addressLine1"
                size="small"
                value={formData?.address?.addressLine1}
                onChange={(e) => {
                  setFormData((prevVal) => ({
                    ...prevVal,
                    address: {
                      ...formData.address,
                      addressLine1: e.target.value,
                    },
                  }));
                }}
                label="Address 1"
                required
                fullWidth
              />
              <TextField
                name="addressLine2"
                size="small"
                value={formData?.address?.addressLine2}
                onChange={(e) => {
                  setFormData((prevVal) => ({
                    ...prevVal,
                    address: { ...data.formData, addressLine2: e.target.value },
                  }));
                }}
                label="Address 2"
                sx={{ mt: 1 }}
                fullWidth
              />
              <TextField
                name="city"
                size="small"
                value={formData?.address?.city}
                onChange={(e) => {
                  setFormData((prevVal) => ({
                    ...prevVal,
                    address: { ...formData.address, city: e.target.value },
                  }));
                }}
                label="City"
                sx={{ mt: 1 }}
                fullWidth
                required
              />
              <TextField
                name="state"
                size="small"
                value={formData?.address?.state}
                onChange={(e) => {
                  setFormData((prevVal) => ({
                    ...prevVal,
                    address: { ...formData.address, state: e.target.value },
                  }));
                }}
                label="State"
                sx={{ mt: 1 }}
                fullWidth
                required
              />
              <TextField
                name="pinCode"
                size="small"
                value={formData?.address?.pinCode}
                onChange={(e) => {
                  setFormData((prevVal) => ({
                    ...prevVal,
                    address: { ...formData.address, pinCode: e.target.value },
                  }));
                }}
                label="Pin Code"
                sx={{ mt: 1 }}
                fullWidth
                required
              />
              <FormControl component="fieldset" sx={{ mt: 1, ml: 2 }}>
                <FormLabel component="legend">Role</FormLabel>
                <RadioGroup
                  aria-label="role"
                  name="role"
                  value={formData.role}
                  onChange={inputHandler}
                  row
                >
                  {userRoles.map((role) => (
                    <FormControlLabel
                      key={role.value}
                      value={role.value}
                      control={<Radio />}
                      label={role.label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={updateUser}
                  disabled={
                    !formData.name ||
                    !formData.email ||
                    !formData?.address?.addressLine1 ||
                    !formData?.address?.city ||
                    !formData?.address?.state ||
                    !formData?.address?.pinCode
                  }
                  sx={{ mb: 5 }}
                >
                  Save
                </Button>
              </div>
            </div>
          </CustomModal>
        )}
      </div>
    </div>
  );
};

export default Users;
