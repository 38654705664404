import React, { useEffect, useState } from "react";
import "./index.scss";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import { Chip, Grid, Typography } from "@mui/material";
import { ORDER_STATUS } from "./config";
import moment from "moment";
import EMPTY_CART_IMG from "../../resources/images/empty-cart.png";
import {
  convertToCamelCaseFromUnderScore,
  removeUnderscoreAndAfter,
} from "../../utils/helper";

const OrderCard = ({ item }) => {
  const orderStatus = ORDER_STATUS.find(
    (ele) => ele.value === item?.status
  )?.label;
  const totalPrice = item?.orderItems?.reduce(
    (total, item) => total + item.totalPrice,
    0
  );
  return (
    <div className="my-order-card-con">
      <div className="order-card-header">
        <div style={{ display: "flex" }}>
          <div>
            <Typography sx={{ color: "gray" }}>Order Placed</Typography>
            <Typography>
              {moment(item?.createdAt).format("MMMM Do YYYY")}
            </Typography>
          </div>
          {/* <div style={{ marginLeft: "3rem" }}>
            <Typography sx={{ color: "gray" }}>Total</Typography>
            <Typography>&#8377;{totalPrice}</Typography>
          </div> */}
        </div>

        <div>
          <Typography> {item?.orderId}</Typography>
          <Chip
            label={orderStatus || item?.status}
            size="small"
            sx={{ ml: 2 }}
            variant="outlined"
            color={item?.status === "COMPLETED" ? "success" : "warning"}
          />
        </div>
      </div>
      <div className="items-list-con">
        {item?.orderItems?.map((ele) => {
          const { partId } = ele;

          return (
            <Grid
              container
              sx={{
                marginBottom: 3,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid item md={1} sx={{ mr: { md: 3, xs: 1 } }} xs={2}>
                <div>
                  <img src={partId.img} width="100%" />
                </div>
              </Grid>

              <Grid item md={10} xs={9}>
                <Typography variant="h6" className="primary-text">
                  {convertToCamelCaseFromUnderScore(partId.label)}
                </Typography>
                <div className="item-details">
                  <div>
                    <Typography className="default-font">
                      Model Number
                    </Typography>
                    <Typography className="default-font" sx={{ color: "gray" }}>
                      {partId?.modelNumber}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="default-font">
                      Part Number
                    </Typography>
                    <Typography className="default-font" sx={{ color: "gray" }}>
                      {removeUnderscoreAndAfter(partId?.partNo)}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="default-font">
                      List Number
                    </Typography>
                    <Typography className="default-font" sx={{ color: "gray" }}>
                      {partId?.listNumber}
                    </Typography>
                  </div>
                  <div>
                    <Typography className="default-font">Quantity</Typography>
                    <Typography className="default-font" sx={{ color: "gray" }}>
                      {ele?.quantity}
                    </Typography>
                  </div>
                  {/* <div>
                    <Typography className="default-font">Price</Typography>
                    <Typography className="default-font" sx={{ color: "gray" }}>
                      {ele?.totalPrice}
                    </Typography>
                  </div> */}
                </div>
              </Grid>
            </Grid>
          );
        })}
      </div>
    </div>
  );
};
const MyOrders = () => {
  const [orders, setOrders] = useState([]);
  const [filters, setFilters] = useState({
    limit: 100,
    page: 1,
    sortBy: "-createdAt",
  });
  const getMyOrders = (filter) => {
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_MY_ORDERS}`,
      null,
      filter
    )
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setOrders(res);
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getMyOrders(filters);
  }, []);
  return (
    <div className="my-orders-con page-load">
      <Typography variant="h5" sx={{ mb: 2 }} className="primary-text">
        My Orders
      </Typography>
      {orders?.results?.length > 0 ? (
        <div>
          {orders.results?.map((item) => (
            <OrderCard item={item} />
          ))}
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={EMPTY_CART_IMG} alt="empty-cart" width="300px" />
          <Typography
            variant="h6"
            sx={{ mb: 2, color: "red", textAlign: "center" }}
          >
            No Orders Found
          </Typography>
        </div>
      )}
    </div>
  );
};

export default MyOrders;
