import React, { useState, useEffect } from "react";
import "./index.scss";
import Footer from "../../component/footer";
import { Grid, Typography } from "@mui/material";
import ENGINE from "../../resources/engine.png";
import GENSET from "../../resources/genset.png";
import PARTS from "../../resources/simpsons_parts.png";
import LEGACY_IMAGE from "../../resources/abt-side2.jpg";
import SMART_AGRICULTURE from "../../resources/images/smart-agriculture.png";
import EFFECIENCY from "../../resources/efficency.png";
import QUALITY from "../../resources/quality.png";
import { HTTP_METHODS, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import moment from "moment";
import BANNER_IMG from "../../resources/banner1.jpg";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import CustomModal from "../../component/modal";
import ScrollAnimation from "react-animate-on-scroll";
const NewHome = () => {
  const [newsData, setNewsData] = useState({});
  const [newsModal, setNewsModal] = useState({
    isOpen: false,
    data: {},
  });
  const getNewsData = () => {
    plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.NEWS}`, null, {
      page: 1,
      limit: 100,
      sortBy: "-createdAt",
    })
      .then((res) => {
        setNewsData(res);
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    getNewsData();
  }, []);
  return (
    <div className="page-load">
      <div>
        <img src={BANNER_IMG} alt="SIMSONS BANNER" className="new-home-img" />
        <div className="banner">
          <h2>LEADING ENGINE, ENGINE COMPONENTS AND GENSET MANUFACTURER</h2>
        </div>

        <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
          <div>
            <Grid container md={12}>
              <Grid item md={3} xs={12}>
                <div className="category-con">
                  <h2>ENGINE</h2>
                  <img src={ENGINE} alt="engine" />
                </div>
              </Grid>
              <Grid item md={3} xs={12}>
                <div className="category-con">
                  <h2>POWER GENERATION</h2>
                  <img src={GENSET} alt="gensets" />
                </div>
              </Grid>
              <Grid item md={3} xs={12}>
                <div className="category-con">
                  <h2>ENGINE PARTS</h2>
                  <img src={PARTS} alt="engine-parts" />
                </div>
              </Grid>
              <Grid item md={3} xs={12}>
                <div className="category-con">
                  <h2>SMART AGRICULTURE MACHINES</h2>
                  <img src={SMART_AGRICULTURE} alt="smart agriculture" />
                </div>
              </Grid>
            </Grid>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="slideInUp" animateOnce={true}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Grid
              container
              md={10}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: { xs: 2, md: 10 },
                mb: { xs: 2, md: 5 },
              }}
              spacing={{ xs: 2 }}
            >
              <Grid
                item
                md={4}
                xs={12}
                sx={{
                  display: { xs: "flex" },
                  alignItems: { xs: "center" },
                  mr: { md: 0, xs: 4 },
                  ml: { md: 0, xs: 4 },
                }}
              >
                <img src={LEGACY_IMAGE} width="100%" />
              </Grid>
              <Grid
                item
                md={7}
                sx={{
                  color: "black",
                  mr: { md: 0, xs: 4 },
                  ml: { md: 0, xs: 4 },
                }}
                xs={12}
              >
                <h1 className="primary-text">THE SIMPSONS LEGACY</h1>
                <Typography
                  sx={{ mb: 2, fontSize: { xs: "10px", md: "1rem" } }}
                >
                  The story of Simpsons dates back to 1840. A Scotsman by name
                  Mr Simpson foreseeing the potential for developing India as a
                  major centre for building coaches and carriages established
                  the company. With a past marked by a rare display of
                  responding and meeting the needs of the consumers, Simpsons
                  took the same role in the changing industrial environment of
                  post-independent India and ushered in the diesel era in 1951.
                  Through an agreement with Perkins Ltd., Peterborough, UK,
                  Simpsons took up the sale and distribution of Perkins diesel
                  engines and in 1955 became the first Indian company to
                  indigenously manufacture diesel engines for surface transport
                  vehicles. It also became the first company in India to
                  revolutionize farm mechanisation.
                </Typography>
                <Typography sx={{ fontSize: { xs: "10px", md: "1rem" } }}>
                  Simpsons today is a leading manufacturer of high performance
                  diesel engines and is the preferred choice in the 10 – 100.5
                  hp range for agricultural, industrial, power generation and
                  marine applications.
                </Typography>
              </Grid>
            </Grid>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="slideInUp" animateOnce={true} duration={1}>
          <div>
            <Grid container md={12}>
              <Grid md={6} item className="effe-con" xs={12}>
                <div className="circle-logo">
                  <img src={EFFECIENCY} />
                </div>
                <div style={{ marginLeft: "20px" }}>
                  <Typography
                    variant="h4"
                    sx={{
                      mb: { md: 2, xs: 1 },
                      fontSize: { xs: "15px", md: "2rem" },
                    }}
                  >
                    EFFICIENCY
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: { xs: "10px", md: "1.1rem" } }}
                  >
                    The company ensures perfect synchronisation between its
                    manufacturing and engineering operations. Most of the
                    tooling is made and calibrated in-house on world-class
                    equipment, substantially reducing the lead time in
                    tailor-made production, either made to customer
                    specifications or engineered for their applications.
                  </Typography>
                </div>
              </Grid>

              <Grid md={6} item className="quality-con" xs={12}>
                <div className="circle-logo">
                  <img src={QUALITY} />
                </div>
                <div style={{ marginLeft: "20px" }}>
                  <Typography
                    variant="h4"
                    sx={{
                      mb: { md: 2, xs: 1 },
                      fontSize: { xs: "15px", md: "2rem" },
                    }}
                  >
                    QUALITY
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: { xs: "10px", md: "1.1rem" } }}
                  >
                    We practice stringent quality control measures for all our
                    products. In the Simpsons Corporation Quality Control
                    laboratories, products undergo Chemical Analysis with an
                    Emission Spectrometer, and Microstructural Analysis using
                    CCTV camera equipped Metallurgical Microscopes and BHN/HRB
                    Hardness Testing Machines. The products are further depth
                    measured and tested for Tensile Strength, Elongation,
                    Modulus of Elasticity, Surface Quality and Internal Defects.
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </div>
        </ScrollAnimation>
        <ScrollAnimation
          animateIn="slideInLeft"
          animateOnce={true}
          duration={1}
        >
          <div className="news-con">
            <Grid container spacing={{ md: 5, xs: 2 }}>
              <Grid item md={4}>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: { xs: "1.5rem", md: "3rem" },
                    mb: 1,
                    mt: { md: 5, xs: 0 },
                  }}
                >
                  Latest News
                </Typography>
                <Typography>Stay Informed, Stay Ahead</Typography>
                <Typography
                  sx={{ mt: 2, color: "gray" }}
                  className="si-hide-mobile"
                >
                  Empower yourself with our up-to-the-minute news coverage and
                  in-depth analyses. Stay informed and engaged with the stories
                  that shape our world.
                </Typography>
              </Grid>
              <Grid item md={8}>
                <div className="notes-card-con">
                  {newsData.results?.map((ele) => {
                    return (
                      <div className="news-card" key={ele.title}>
                        <div className="title-con">
                          <Typography
                            variant="h5"
                            sx={{
                              fontWeight: { md: 600, xs: 500 },
                              mt: 1,
                              mb: 1,
                              fontSize: { md: "1.5rem", xs: "1.2rem" },
                              alignItems: "center",
                              display: "flex",
                            }}
                            className="primary-text"
                          >
                            {ele?.title}
                            <OpenInNewIcon
                              sx={{ ml: { md: 1, xs: 0.5 }, cursor: "pointer" }}
                              onClick={() =>
                                setNewsModal({ isOpen: true, data: ele })
                              }
                              fontSize="small"
                            />
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ color: "gray", mr: { xs: 0, md: 5 } }}
                          >
                            {moment(ele?.date).format("DD-MM-YYYY")}
                          </Typography>
                        </div>
                        <Typography
                          variant="h6"
                          sx={{
                            color: "gray",
                            lineHeight: 1.2,
                            fontSize: {
                              md: "1.3rem",
                              xs: "0.9rem",
                            },
                          }}
                        >
                          {ele?.desc?.slice(0, 250)}...
                        </Typography>
                      </div>
                    );
                  })}
                </div>
              </Grid>
            </Grid>
            {newsModal?.isOpen && (
              <CustomModal
                onClose={() => setNewsModal({ isOpen: false, data: {} })}
                contentClassName="news-modal"
              >
                <Typography variant="h6" className="primary-text">
                  {newsModal?.data?.title}
                </Typography>
                <Typography variant="body2">{newsModal?.data?.desc}</Typography>
              </CustomModal>
            )}
          </div>
        </ScrollAnimation>
        <Footer />
      </div>
    </div>
  );
};

export default NewHome;
