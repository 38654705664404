import React from "react";
import { Grid, Typography } from "@mui/material";
import Footer from "../../component/footer";
import SAFETY_IMG from "../../resources/CSR/safety.jpg";
const Safety = () => {
  return (
    <div className="page-load">
      <div className="shareholder-info-con drop-in">
        <Grid container spacing={7} sx={{ mb: 15 }}>
          <Grid item md={8} sx={{ gridGap: 10 }}>
            <Typography
              variant="h4"
              sx={{
                borderBottom: "1px solid #ded8d7",
                paddingBottom: 2,
                mb: 3,
                fontSize: { md: "2rem", xs: "1.5rem" },
              }}
              className="primary-text"
            >
              Safety
            </Typography>
            <Typography variant="body1" sx={{ mb: { md: 2, xs: 0 } }}>
              Employee’s Safety – A foremost concern
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Simpson & Co. believes in "if a job poses a potential safety or
              health threat, no stone shall be left unturned to plan to do it in
              a safe way to complete the task in a safe manner".
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              The company follows the following guidelines to ensure safety of
              its employees:
            </Typography>
            <ul className="quality-points">
              <li>Provide workers with a safe work environment</li>
              <li>Conduct routine/regular workplace inspections</li>
              <li>Provide Personal Protective Equipment(PPE)</li>
              <li>Develop and implement safe work procedures and rules</li>
              <li>Provide on-going safety training</li>
              <li>Enforce safety rules and appropriate discipline</li>
            </ul>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Regular training is imparted to machine operators and maintenance
              personnel. All employees are required to wear protective gear as a
              safety measure to avoid potential threats. Proper ventilation
              arrangements are made in the premises.
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              All personnel are trained on fire safety & alarm protocols and on
              evacuation to safer assembly areas in times of emergency.
            </Typography>
          </Grid>
          <Grid item md={4}>
            <div className="photo-box">
              <img src={SAFETY_IMG} alt="Safety" width="100%" />
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default Safety;
