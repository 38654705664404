import React, { useEffect, useRef, useState } from "react";
import "./visualize-product.scss";
import PartsDetailView from "./parts-detail-view";
import { Typography } from "@mui/material";
import {
  CHILD_PART_LIST,
  MAIN_PART_LIST,
  ENGINE2_CHILD_PART_LIST,
  ENGINE2_MAIN_PART_LIST
} from "../../utils/parts_data";
import { HTTP_METHODS, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import EngineCards from "../../component/engine-cards";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { showClickedDetails } from "./config";

const VisualizeProducts = () => {
  const [searchParams] = useSearchParams();
  const [activeEngine, setActiveEngine] = useState("");
  const [isEngineView, setIsEngineView] = useState(false);
  const [parentList, setParentList] = useState([]);
  const [childList, setChildList] = useState([]);
  const jsonMapping = useRef([]);
  const [activeListNo, setActiveListNo] = useState("");
  const [selectParts, setSelectParts] = useState({});

  useEffect(() => {
    return window?.document?.removeEventListener(
      "clickedCanvasObject",
      handleClickCanvasObject
    );
  }, []);

  useEffect(() => {
    if (
      searchParams &&
      searchParams.get("engine") &&
      searchParams.get("listNo")
    ) {
      onEngineClick(searchParams.get("engine"), searchParams.get("listNo"));
    }
  }, []);

  const goBackHandler = () => {
    setIsEngineView(false);
    setActiveListNo("");
  };

  const getPartsList = params => {
    plainApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.GET_CUSTOM_PART_LIST}`,
      null,
      params
    )
      .then(res => {
        if (!res.message) {
          if (!params?.parent) {
            const result = res.map((ele, index) => {
              const sno = ele?.id || index;
              const id = ele?.mainPartId;
              delete ele.id;
              delete ele.mainPartId;
              return {
                ...ele,
                sno: sno,
                id: id
              };
            });
            setParentList(result);
          } else {
            const result = res.map((ele, index) => {
              const sno = `${ele?._id || index}`;
              delete ele._id;
              return {
                ...ele,
                sno: sno
              };
            });
            setChildList(result);
          }
        } else {
          goBackHandler();
        }
      })
      .catch(err => console.error(err));
  };

  const loadMapping = () => {
    axios.get(`${activeEngine}.json`).then(response => {
      jsonMapping.current = response.data || [];
    });
  };

  useEffect(() => {
    if (isEngineView) {
      loadMapping();
      switch (activeEngine) {
        case "SJV436":
          setChildList(CHILD_PART_LIST);
          setParentList(MAIN_PART_LIST);
          addEvent();
          break;
        case "SJV3259":
          setChildList(ENGINE2_CHILD_PART_LIST);
          setParentList(ENGINE2_MAIN_PART_LIST);
          addEvent();
          break;
        default:
          break;
      }
    }
  }, [isEngineView]);

  const addEvent = () => {
    window.document.addEventListener(
      "clickedCanvasObject",
      handleClickCanvasObject
    );
  };

  const handleClickCanvasObject = event => {
    console.log("object id ", event.detail.message)
    const data = showClickedDetails(event.detail.message, jsonMapping.current);
    setSelectParts({ ...data });
  };

  const onEngineClick = (engine, listNo) => {
    setActiveEngine(engine);
    setActiveListNo(listNo);
    setIsEngineView(true);
  };

  return (
    <div className="page-load drop-in">
      {isEngineView ? (
        <PartsDetailView
          parentList={parentList}
          childList={childList}
          activeEngine={activeEngine}
          activeListNo={activeListNo}
          goBackHandler={goBackHandler}
          getPartsList={getPartsList}
          selectParts={selectParts}
          jsonMapping={jsonMapping}
        />
      ) : (
        <div className="admin-dashboard-container">
          <div>
            <Typography
              variant="h4"
              sx={{
                paddingBottom: 0.5,
                fontSize: { md: "2rem", xs: "1.5rem" }
              }}
              className="primary-text"
            >
              Visualize Products
            </Typography>
            <Typography variant="body2">
              Please select an engine to visualize it in 3D
            </Typography>
          </div>
          <div style={{ margin: "20px" }}>
            <EngineCards onClick={onEngineClick} />
          </div>
        </div>
      )}
    </div>
  );
};

export default VisualizeProducts;
