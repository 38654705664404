import React from "react";
import Footer from "../../component/footer";
import { Grid, Typography } from "@mui/material";
import SideContent from "../info-for-shareholder/side-content";
import IT_SUPPORT_IMG from "../../resources/it-support.jpg";
const Others = () => {
  return (
    <div>
      <div className="shareholder-info-con drop-in">
        <Grid container spacing={7}>
          <Grid item md={8} sx={{ gridGap: 10 }}>
            <Typography
              variant="h4"
              sx={{
                borderBottom: "1px solid #ded8d7",
                paddingBottom: 2,
                fontSize: { md: "2rem", xs: "1.5rem" },
                mb: 3,
              }}
              className="primary-text"
            >
              Others
            </Typography>
            <div>
              <Typography
                sx={{
                  mt: 2,
                  mb: 1,
                  color: "#0092CE",
                }}
              >
                Information Systems
              </Typography>
              <img
                src={IT_SUPPORT_IMG}
                alt="Information Systems"
                style={{ maxWidth: "100%" }}
              />
            </div>
            <Typography variant="body2" sx={{ mt: 4, mb: 2 }}>
              Simpson has the latest IT infrastructure comprising Hardware and
              Software. The Company has embraced the best available softwaresto
              provide a strong data base meeting dynamic business needs.
              Information Technology’s focus is to enable business growth by
              providing key inputs on market conditions and set up a framework
              for improved decision making and support.
            </Typography>
            <Typography variant="body2">
              Simpson & Co. has implemented Oracle E-business suite for seamless
              operations. Other support services like e-mail, broad-band based
              internet, antivirus and messaging security suite, client hardware
              with required software are also available to serve its IT needs.
            </Typography>
          </Grid>
          <Grid item md={2}>
            <div className="si-hide-mobile">
              <SideContent />
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default Others;
