import React from "react";
import IMG1 from "../../resources/hty-img1.jpg";
import IMG2 from "../../resources/hty-img2.jpg";
import IMG3 from "../../resources/hty-img3.jpg";
const SideImgGallery = () => {
  return (
    <div>
      <div className="cont-right">
        <img src={IMG1} />
        <img src={IMG2} />
        <img src={IMG3} />
      </div>
    </div>
  );
};

export default SideImgGallery;
