import React, { useState, useEffect } from "react";
import "./nav.scss";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Badge, Menu, MenuItem } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getNavList } from "./config";
import { clearOfflineData, getOfflineData } from "../../utils/offline-services";
import MobileNavbar from "./mobile-nav";
import LOGO from "../../resources/log.png";

function Navbar() {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElSubMenu, setAnchorElSubMenu] = useState(null);
  const [navList, setNavList] = useState([]);
  const [activePage, setActivePage] = useState("/");
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [user, setUser] = useState({});
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const cartDetails = useSelector(
    state => (state.user && state.cart.items) || {}
  );
  const userDetails = useSelector(state => state.user?.userDetails || {});

  const subMenuHandleClick = (event, menuLabel) => {
    setAnchorElSubMenu(
      activeSubMenu === menuLabel ? null : event.currentTarget
    );
    setActiveSubMenu(activeSubMenu === menuLabel ? null : menuLabel);
  };

  const logoutHandler = () => {
    clearOfflineData("user");
    clearOfflineData("tokens");
    setUser({});
    navigate("/login");
  };

  const updateNavList = () => {
    const nav = getNavList();
    setNavList(nav);
  };

  useEffect(() => {
    const user = getOfflineData("user");
    setUser(user);
  }, [activePage]);

  useEffect(() => {
    updateNavList();
  }, [user]);

  useEffect(() => {
    setActivePage(pathname);
  }, [pathname]);

  const isActive = tab => {    
    if (tab?.activeRoutes?.length > 0) {
      let found = false;
      tab.activeRoutes.forEach(element => {
        if (window.location.pathname.includes(element)) {
          found = true;
        }
      });
      if (found) return true;
    }
    if (activePage === tab.url) return true;

    // Check if any submenu item is active
    return (
      tab.submenu &&
      tab.submenu.some(subsection => subsection.url === activePage)
    );
  };

  const redirectionHandler = url => {
    if (url) {
      navigate(url);
    }
  };

  return (
    <div>
      <Box className="mui-nav-con si-hide-mobile">
        <div className="logo-bg" onClick={() => navigate("/")}>
          <img src={LOGO} alt="LOGO" className="logo-img" />
        </div>

        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: "flex", alignItems: "center", gridGap: "20px" }}>
            {navList.map((page, index) => {
              const active = isActive(page);

              if (page.submenu) {
                return (
                  <div
                    key={page.label}
                    className={`nav-label-cls ${active ? "active-label" : ""}`}
                    onClick={event => subMenuHandleClick(event, page.label)}
                  >
                    <Typography
                      sx={{ alignItems: "center", display: "flex" }}
                      className="nav-label-cls"
                    >
                      {page.label}
                      <KeyboardArrowDownIcon
                        fontSize="small"
                        sx={{ color: "gray", fontSize: "12px" }}
                      />
                    </Typography>
                    <Popover
                      id={`simple-popover-${page.label}`}
                      open={activeSubMenu === page.label}
                      anchorEl={anchorElSubMenu}
                      onClose={() => setActiveSubMenu(null)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                      }}
                      sx={{ mt: 2 }}
                    >
                      <div className="sub-menu-links-con">
                        {page.submenu.map((subsection, subIndex) => (
                          <Typography
                            key={subIndex}
                            variant="body1"
                            sx={{ mb: 1, ml: 0.3, fontSize: "13px" }}
                            onClick={() => {
                              navigate(subsection?.url || "/");
                              setActiveSubMenu(null);
                            }}
                            className="sub-menu-nav-link"
                          >
                            {subsection.label}
                          </Typography>
                        ))}
                      </div>
                    </Popover>
                  </div>
                );
              } else {
                return (
                  <div
                    key={page.label}
                    onClick={() => redirectionHandler(page?.url)}
                    className={`nav-label-cls ${active ? "active-label" : ""}`}
                  >
                    <Typography className="nav-label-cls">
                      {page.url === "/my-cart" ? (
                        <Badge badgeContent={cartDetails.length} color="error">
                          {page.label}
                        </Badge>
                      ) : (
                        page.label
                      )}
                    </Typography>
                  </div>
                );
              }
            })}
          </Box>
          <Box sx={{ ml: 3 }}>
            {Object.keys(user).length ? (
              <div>
                <AccountCircleIcon
                  onClick={event => setAnchorElUser(event.currentTarget)}
                  sx={{ cursor: "pointer" }}
                />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorElUser}
                  open={Boolean(anchorElUser)}
                  onClose={() => setAnchorElUser(null)}
                  sx={{ mt: 1 }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button"
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate("/my-account");
                      setAnchorElUser(null);
                    }}
                  >
                    My account
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      logoutHandler();
                      setAnchorElUser(null);
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              <Button
                variant="contained"
                size="small"
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            )}
          </Box>
        </Box>
      </Box>
      <Box className="si-hide-web" sx={{ width: "100%" }}>
        <MobileNavbar
          user={user}
          logoutHandler={logoutHandler}
          navigate={navigate}
          anchorElUser={anchorElUser}
          setAnchorElUser={setAnchorElUser}
          logo={LOGO}
        />
      </Box>
    </div>
  );
}

export default Navbar;
