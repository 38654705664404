import React from "react";
import "./index.scss";
import { Typography, Grid } from "@mui/material";
import IMG1 from "../../resources/abt-side2.jpg";
import IMG2 from "../../resources/abt-side3.jpg";
import Footer from "../../component/footer";
const AboutUs = () => {
  return (
    <>
      <div className="about-content page-load">
        <Grid container md={12}>
          <Grid item md={10} sx={{ pr: { md: 10, xs: 0, sm: 0 } }} xs={12}>
            <Typography
              variant="h4"
              sx={{
                borderBottom: "1px solid #ded8d7",
                paddingBottom: 2,
                fontSize: { md: "2rem", xs: "1.5rem" },
                mb: 3,
              }}
              className="primary-text"
            >
              About Us
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }} className="drop-in-2">
              The story of Simpsons dates back to 1840. A Scotsman by name Mr
              Simpson foreseeing the potential for developing India as a major
              centre for building coaches and carriages established the company.
              With a past marked by a rare display of responding and meeting the
              needs of the consumers, Simpsons took the same role in the
              changing industrial environment of post-independent India and
              ushered in the diesel era in 1951. Through an agreement with
              Perkins Ltd., Peterborough, UK, Simpsons took up the sale and
              distribution of Perkins diesel engines and in 1955 became the
              first Indian company to indigenously manufacture diesel engines
              for surface transport vehicles. It also became the first company
              in India to revolutionize farm mechanisation.
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }} className="drop-in-2">
              The company took constant efforts to enhance its presence in all
              spheres of industrial activity. Planned investment in research and
              development, quality assurance programmes and production have
              enabled the company to stand the test of time. The pervading
              philosophy at Simpsons that is sure to propel the company into the
              forefront to meet the never-receding demand for diesel power.
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }} className="drop-in-2">
              Simpsons today is a leading manufacturer of high performance
              diesel engines and is the preferred choice in the 10 – 100.5 hp
              range for agricultural, industrial, power generation and marine
              applications.
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }} className="drop-in-2">
              Simpsons is a unit company of Amalgamations Group involved in
              manufacturing, trading & services, plantations having 46 companies
              and 52 manufacturing plants. Core values of the Group are deep
              commitment, self -belief, mutual trust and enduring relationship.
            </Typography>
          </Grid>
          <Grid md={2} item xs={12}>
            <div className="cont-right drop-in">
              <img src={IMG1} />
              <img src={IMG2} />
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
