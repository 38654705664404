import React, { useEffect, useState } from "react";
import "./index.scss";
import MuiTable from "../../component/mui-table";
import { ORDER_STATUS, getHeaderConfig } from "./config";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import { getOfflineData } from "../../utils/offline-services";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CustomModal from "../../component/modal";
import { exportToExcel } from "../../utils/export-excel";
import { DownloadBtn } from "../../component/download-btn";
import OrderDetails from "./order-details";

const Orders = () => {
  const [data, setData] = useState({});
  const [orderDetails, setOrderDetails] = useState({
    isOpen: false,
    data: {},
  });
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    sortBy: "-createdAt",
    page: 1,
    limit: 10,
  });
  const [orderFilters, setOrderFilers] = useState({
    status: "OPEN",
    startDate: null,
    endDate: null,
  });
  const user = getOfflineData("user");
  const getData = (filter) => {
    if (orderFilters.status) {
      filter["status"] = orderFilters.status;
    }
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.ORDER}`, null, filter)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setData(res);
        }
      })
      .catch((err) => console.error(err));
  };
  const updateOrder = (id) => {
    invokeApi(HTTP_METHODS.PUT, `${HOSTNAME}${REST_URLS.ORDER}/${id}`, {
      status: "COMPLETED",
    })
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          toast.success("Status Updated Successfully", { autoClose: 1000 });
          setOrderDetails({
            isOpen: false,
            data: {},
          });
          getData({ ...filters, page: 1 });
        }
      })
      .catch((err) => console.error(err));
  };
  const downloadData = () => {
    const payload = { ...filters, page: 1, limit: data?.totalResults };
    if (orderFilters.startDate) {
      payload["startDate"] = orderFilters.startDate;
    }
    if (orderFilters.endDate) {
      payload["endDate"] = orderFilters.endDate;
    }
    if (orderFilters.endDate) {
      payload["status"] = orderFilters.endDate;
    }
    if (orderFilters.status) {
      payload["status"] = orderFilters.status;
    }
    invokeApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.ORDER}`,
      null,
      payload
    ).then((res) => {
      exportToExcel(res.results, "Orders");
    });
  };
  const updateStatusHandler = (data, type) => {
    switch (type) {
      case "UPDATE_STATUS":
        updateOrder(data.id || data._id);
        break;
      case "SHOW_ORDERS":
        setOrderDetails({
          isOpen: true,
          data: data,
        });
        break;
      case "DOWNLOAD_EXCEL":
        exportToExcel([data], "Orders");
        break;
      default:
        break;
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setOrderFilers((prevVal) => ({ ...prevVal, [name]: value }));
  };
  const searchHandler = () => {
    const payload = { ...filters, page: 1 };
    if (orderFilters.startDate) {
      payload["startDate"] = orderFilters.startDate;
    }
    if (orderFilters.endDate) {
      payload["endDate"] = orderFilters.endDate;
    }
    if (orderFilters.endDate) {
      payload["status"] = orderFilters.endDate;
    }
    getData(payload);
  };
  useEffect(() => {
    if (user?.role === "user") {
      navigate("/");
    } else {
      getData(filters);
    }
  }, []);
  return (
    <div className="page-load">
      {orderDetails?.isOpen ? (
        <OrderDetails
          orderDetails={orderDetails}
          setOrderDetails={setOrderDetails}
          updateOrder={updateOrder}
        />
      ) : (
        <div className="orders-con">
          <Typography variant="h5" sx={{ mb: 2 }} className="primary-text">
            Orders
          </Typography>
          <Grid container md={12} gap={2} sx={{ mb: 0.5 }} alignItems="center">
            <Grid md={2} item>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={orderFilters?.status}
                  label="Status"
                  onChange={handleChange}
                  name="status"
                  size="small"
                >
                  {ORDER_STATUS.map((ele) => (
                    <MenuItem value={ele?.value}>{ele.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid md={2} item>
              <TextField
                label="From"
                type="date"
                size="small"
                name="startDate"
                fullWidth
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid md={2} item>
              <TextField
                label="To"
                type="date"
                size="small"
                name="endDate"
                fullWidth
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
            <Grid md={2} item>
              <Button variant="outlined" onClick={searchHandler}>
                Search
              </Button>
            </Grid>
            <Grid md={2} item>
              <DownloadBtn onClick={downloadData} />
            </Grid>
          </Grid>

          <MuiTable
            columnsList={getHeaderConfig()}
            dataList={data.results || []}
            filters={filters}
            pageCount={data.totalPages}
            onClick={updateStatusHandler}
            onChange={(page) => {
              setFilters({
                ...filters,
                page,
              });
              getData({
                ...filters,
                page,
              });
            }}
          ></MuiTable>
          {data?.results?.length === 0 && (
            <Typography variant="h4" sx={{ textAlign: "center" }}>
              No Pending Orders
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default Orders;
