import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getOfflineData } from "../utils/offline-services";
import AppLayout from "../container/layout";

const PrivateRoute = () => {
  return getOfflineData("user") ? (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
