import React from "react";
import "./index.scss";
import { Grid, Typography } from "@mui/material";
const EngineCards = ({ onClick }) => {
  const onImageError = event => {
    event.target.src = require("../../resources/images/engine.png");
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{
        // height: { xs: "100vh", sm: "auto" }, // Full height only on mobile (xs)
        justifyContent: { xs: "center", sm: "flex-start" }, // Center horizontally on mobile, default for larger screens
        alignItems: { xs: "center", sm: "flex-start" } // Center vertically on mobile, default for larger screens
      }}
    >
      <Grid item md={6} sm={12}>
        <div
          className="visulize-engine-con add-order"
          onClick={() => onClick("SJV436", "7260")}
        >
          <Typography variant="h6" className="primary-text">
            SJV436 | 7260
          </Typography>

          <img
            className="mc-image"
            onError={onImageError}
            src={require("../../resources/images/engine1.png")}
          />
        </div>
      </Grid>
      <Grid item md={6} sm={12}>
        <div
          // style={{
          //   backgroundColor: "#fff",
          //   padding: "10px",
          //   boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          // }}
          className="visulize-engine-con"
          onClick={() => onClick("SJV3259", "4221")}
        >
          <Typography variant="h6" className="primary-text">
            SJV3259 | 4221
          </Typography>
          <img
            className="mc-image"
            onError={onImageError}
            src={require("../../resources/images/engine2.png")}
          />
        </div>
      </Grid>
    </Grid>
  );
};

export default EngineCards;
