import React from "react";
import "./index.scss";
import { createSearchParams, useNavigate } from "react-router-dom";
import EngineCards from "../../component/engine-cards";
import { Typography } from "@mui/material";

const BuyParts = () => {
  const navigate = useNavigate();

  const onCardClick = (engine, listNo) => {
    navigate({
      pathname: "/explore-items",
      search: createSearchParams({
        engine: engine,
        listNo: listNo,
      }).toString(),
    });
  };

  return (
    <div className="buy-parts drop-in">
      <div>
        <Typography
          variant="h4"
          sx={{
            paddingBottom: 0.5,
            fontSize: { md: "2rem", xs: "1.5rem" },
          }}
          className="primary-text"
        >
          Buy Parts
        </Typography>
        <Typography variant="body2">
          Please select an engine to buy its sub assemblies and parts
        </Typography>
      </div>
      <div
        style={{
          margin: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <EngineCards onClick={onCardClick} />
      </div>
    </div>
  );
};

export default BuyParts;
