import { Typography } from "@mui/material";
import React from "react";

const NotFound = () => {
  return (
    <div>
      <Typography variant="h1" sx={{ textAlign: "center" }}>
        Not Found
      </Typography>
    </div>
  );
};

export default NotFound;
