export const setOfflineData = (key, data) => {
  if (data !== "" && data !== null && data !== undefined) {
    localStorage.setItem(`e-parts:${key}`, JSON.stringify(data));
  }
};

export const getOfflineData = (key) => {
  const data = localStorage.getItem(`e-parts:${key}`);
  if (data !== "" && data !== null && data !== undefined) {
    return JSON.parse(data);
  } else {
    return "";
  }
};

export const clearOfflineData = (key) => {
  localStorage.removeItem(`e-parts:${key}`);
};

export const clearTokenDataAndRedirectToLogin = (navigate) => {
  clearOfflineData("user");
  clearOfflineData("tokens");
  if (navigate) {
    navigate("/login");
    return;
  }
  window.location = window.location.origin + "/login";
};
