import { Grid, Typography, Card, CardContent } from "@mui/material";
import React from "react";
import Footer from "../../component/footer";
import SideContent from "../info-for-shareholder/side-content";
import CSR1 from "../../resources/CSR/simpson-csr1.jpg";
import CSR2 from "../../resources/CSR/simpson-csr2.jpg";
import CSR3 from "../../resources/CSR/simpson-csr3.jpg";
import CSR4 from "../../resources/CSR/simpson-csr4.jpg";
import CSR5 from "../../resources/CSR/simpson-csr5.jpg";
import CSR6 from "../../resources/CSR/simpson-csr6.jpg";
import CSR7 from "../../resources/CSR/simpson-csr7.jpg";
import CSR8 from "../../resources/CSR/simpson-csr8.jpg";
import CSR9 from "../../resources/CSR/simpson-csr9.jpg";

const CoporateSocialResponsibility = () => {
  const imageGrid = [CSR1, CSR2, CSR3, CSR4, CSR5, CSR6, CSR7, CSR8, CSR9];
  return (
    <div className="page-load">
      <div className="shareholder-info-con drop-in">
        <Grid container spacing={7}>
          <Grid item md={8} sx={{ gridGap: 10 }}>
            <Typography
              variant="h4"
              sx={{
                borderBottom: "1px solid #ded8d7",
                paddingBottom: 2,
                mb: 3,
                fontSize: { md: "2rem", xs: "1.5rem" },
              }}
              className="primary-text"
            >
              CSR
            </Typography>
            <Typography variant="h6" sx={{ mb: { md: 2, xs: 0 } }}>
              Corporate Social Responsibility
            </Typography>
            <Typography variant="body2">
              Social responsibility is an intrinsic part of the corporate ethos.
              In our own humble way, we have been contributing to the betterment
              of the society at large.
            </Typography>
            <Grid container spacing={4} sx={{ mt: 0.5 }}>
              {imageGrid?.map((ele) => {
                return (
                  <Grid item md={4} xs={12}>
                    <img src={ele} alt="Social responsibility" width="100%" />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item md={2} className="si-hide-mobile">
            <SideContent />
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default CoporateSocialResponsibility;
