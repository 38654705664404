import React from "react";
import CustomModal from "../../component/modal";
import { Button, Typography, Box } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
const DeleteModal = ({ onClose, deleteFunc }) => {
  return (
    <div>
      <CustomModal onClose={onClose}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <HighlightOffIcon
            color="error"
            sx={{ fontSize: "100px", textAlign: "center" }}
          />
          <Typography variant="h5" sx={{ mt: 2, color: "gray" }}>
            Are You Sure?
          </Typography>
          <Typography
            variant="body1"
            sx={{ mt: 1, color: "gray", textAlign: "center" }}
          >
            Do You really want to delete this record? This process Cannot be
            undone
          </Typography>
        </Box>

        <Box
          sx={{ display: "flex", justifyContent: "space-around", mt: 5, mb: 3 }}
        >
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={onClose}
            fullWidth
            sx={{ mr: 2 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="small"
            color="error"
            onClick={deleteFunc}
            fullWidth
          >
            Delete
          </Button>
        </Box>
      </CustomModal>
    </div>
  );
};

export default DeleteModal;
