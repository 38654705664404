import React from "react";
import { Grid, Typography } from "@mui/material";
import SideContent from "../info-for-shareholder/side-content";
import Footer from "../../component/footer";
import RESEARCH1 from "../../resources/research/r-d1.jpg";
import RESEARCH2 from "../../resources/research/r-d2.jpg";
import RESEARCH3 from "../../resources/research/r-d3.jpg";
import RESEARCH4 from "../../resources/research/r-d4.jpg";

const ResearchAndDevelopment = () => {
  const imageList = [
    { label: "Design Department", url: RESEARCH1 },
    { label: "Engine Test Monitoring Cell", url: RESEARCH2 },
    { label: "Emission Lab", url: RESEARCH3 },
    { label: "Engine Testing Cell", url: RESEARCH4 },
  ];
  return (
    <div className="page-load">
      <div className="shareholder-info-con drop-in">
        <Grid container spacing={7}>
          <Grid item md={8} sx={{ gridGap: 10 }}>
            <Typography
              variant="h4"
              sx={{
                borderBottom: "1px solid #ded8d7",
                paddingBottom: 2,
                fontSize: { md: "2rem", xs: "1.5rem" },
                mb: 3,
              }}
              className="primary-text"
            >
              Research & Development
            </Typography>
            <Typography variant="body2">
              As a pioneer in manufacturing diesel engines, Simpson & Company is
              abreast with latest Research & Development practices followed
              world over.
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Simpsons has made significant contribution in reducing carbon
              print , providing green and clean energy by considerably reducing
              emissions without compromise on B10 life. The Common Rail engines
              developed in association with AVL meet Stringent EPA/CARB norms .
            </Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Its R&D centre is registered with Department of Scientific &
              Industrial Research (DSIR) Government of India.
            </Typography>
            <div>
              <Typography variant="h6" sx={{ mt: 4 }} className="primary-text">
                The legacy wing of R&D consists of 7 test cells with modern
                measuring equipment namely
              </Typography>
              <ul className="quality-points">
                <li>
                  Gravimetric fuel flow, torque, speed, ultrasonic air flow and
                  smoke measurement by opacity and reflection from a filter
                  paper that draws a known mass of exhaust gases. In the cells,
                  electronic emission meters for NO, NO2, CO and O2 measurements
                  are used.
                </li>
                <li>
                  Air conditioned console room to ensure dust free ambience and
                  protection for trouble free instrumentation.
                </li>{" "}
                <li>
                  Calculation of emissions is carried out as per ISO-8178 for
                  power generation and tractor applications. An ingenious method
                  is used to estimate particulates.
                </li>{" "}
              </ul>
            </div>
            <div>
              <Typography variant="h6" sx={{ mt: 4 }} className="primary-text">
                New Emission laboratory with AVL dynamometers high tech
                measuring instruments.
              </Typography>
              <ul className="quality-points">
                <li>
                  One of them is AC dynamometer to measure transients to test
                  for newer emissions standards adhered outside India.
                </li>
                <li>
                  For study of engine behaviour, AVL indicating system and noise
                  measurement equipment are available.
                </li>{" "}
                <li>
                  It uses Creo/Pro-E for 3-D modelling of engine parts and full
                  assembly of engines. It is in the process of integrating
                  Wind-chill with its ERP to harness the potential of 3-D
                  models. It has ready-to-fit customised solutions, application
                  wing for installation, commissioning and validation.
                </li>{" "}
              </ul>
            </div>
            <Typography variant="body2" sx={{ mt: 4 }}>
              Common rail engines offered by Simpsons meet stringent emission
              norms for off high way application requirements of global markets
              and proposed emissions beyond year 2020 in India .
            </Typography>
            <Grid container sx={{ mt: 3 }} spacing={3}>
              {imageList?.map((ele, index) => {
                return (
                  <Grid item md={6} key={index}>
                    <img src={ele?.url} width="100%" alt="research" />
                    <Typography
                      sx={{
                        textAlign: "center",
                        mt: 2,
                        mb: 3,
                        color: "#0092CE",
                      }}
                    >
                      {ele?.label}
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item md={2}>
            <div className="si-hide-mobile">
              <SideContent />
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default ResearchAndDevelopment;
