import React, { useState, useEffect } from "react";
import "./index.scss";
import Footer from "../../component/footer";
import { Button, Grid, Pagination, TextField, Typography } from "@mui/material";
import { HTTP_METHODS, invokeApi, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import MultiActionAreaCard from "../explore-items/item-card";
import { toast } from "react-toastify";
import ENGINE_IMG from "../../resources/e1.png";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
const ServiceManual = () => {
  const [manualData, setManualData] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState([]);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filters, setFilters] = useState({
    page: 1,
    limit: 100,
    sortBy: "-createdAt",
  });

  const getSearchParts = (searchTerm) => {
    plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.SEARCH_MANUALS}`, null, {
      searchInput: searchTerm,
    }).then((res) => {
      if (!res.message) {
        const partList = res?.map((ele) => {
          return {
            name: ele?.name,
            id: ele?.id,
            key: ele?.id,
            ...ele,
          };
        });

        setSearchResults(partList);
      }
    });
  };

  const searchInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value.trim() && !value.includes(",")) {
      getSearchParts(value);
    } else {
      setSearchResults([]);
    }
  };

  const selectSearchHandler = (res) => {
    setSelectedResult([res]);
    setSearchTerm(res.name);
    setSearchResults([]);
  };

  const getManualList = (filter) => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.MANUAL}`, null, filter)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          setManualData(res);
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getManualList({ ...filters, page: 1 });
  }, []);

  return (
    <div className="page-load">
      <div className="main-manual-search-con">
        <Typography variant="h5" className="primary-text">
          Service Manuals{" "}
        </Typography>
        <div className="manual-search">
          <TextField
            variant="outlined"
            value={searchTerm}
            onChange={searchInputChange}
            placeholder="Search Manuals by Name and Tags"
            fullWidth
            size="small"
            sx={{
              backgroundColor: "white",
              borderColor: "gray",
              borderRadius: "15px",
            }}
            InputProps={{
              endAdornment: searchTerm ? (
                <CloseIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    setSearchTerm("");
                    setSearchResults([]);
                    setSelectedResult([]);
                  }}
                />
              ) : null,
            }}
          />
          {searchResults.length ? (
            <Grid container className="manual-search-list-con" md={4}>
              {searchResults.map((result) => (
                <Grid item key={result.id} xs={12}>
                  <div
                    className="search-item"
                    onClick={() => selectSearchHandler(result)}
                  >
                    <Typography>{result.name}</Typography>
                  </div>
                </Grid>
              ))}
            </Grid>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="manual-list-con">
        {selectedResult.length > 0 ? (
          <Grid container md={12}>
            {selectedResult.map((data) => (
              <Grid
                item
                md={4}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
                xs={12}
              >
                <div className="service-manual-card">
                  <video
                    src={data?.videoURL}
                    width="100%"
                    controlsList="nodownload"
                    disablePictureInPicture
                    onContextMenu={(e) => e.preventDefault()}
                    poster={data?.thumbnail}
                    className="card-video"
                    onClick={() => {
                      navigate(`/view-manual/${data?.id}`);
                    }}
                  ></video>

                  <p className="desc">
                    {data?.description && data.description.length > 50
                      ? `${data.description.slice(0, 50)}...`
                      : data.description}
                  </p>
                  <p className="manual-name">{data?.name}</p>
                </div>
              </Grid>
            ))}
          </Grid>
        ) : (
          manualData?.results?.length > 0 && (
            <div>
              <Grid container md={11.5} sx={{ margin: "auto" }}>
                {manualData?.results?.map((data) => (
                  <Grid
                    item
                    md={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    xs={12}
                  >
                    <div className="service-manual-card">
                      <video
                        src={data?.videoURL}
                        width="100%"
                        className="card-video"
                        poster={data?.thumbnail}
                        controlsList="nodownload"
                        disablePictureInPicture
                        onContextMenu={(e) => e.preventDefault()}
                        preload="auto"
                        onClick={() => {
                          navigate(`/view-manual/${data?.id}`);
                        }}
                      ></video>
                      <p className="desc">{data?.name}</p>
                      <p className="manual-name">
                        {data?.description && data.description.length > 180
                          ? `${data.description.slice(1, 180)}...`
                          : data.description}
                      </p>
                      <div className="manual-name created-date-con">
                        <div>
                          {moment(data?.createdAt, "YYYYMMDD").fromNow()}
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
              <div className="si-pagination">
                {manualData?.totalPages > 1 && (
                  <Pagination
                    count={manualData.totalPages}
                    color="primary"
                    page={page}
                    onChange={(e, page) => {
                      setPage(page);
                      getManualList({ ...filters, page: page });
                    }}
                  ></Pagination>
                )}
              </div>
            </div>
          )
        )}
      </div>
      <Footer />
    </div>
  );
};

export default ServiceManual;
