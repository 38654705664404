import { useState, useEffect, useRef } from "react";
import "./index.scss";
import MultiActionAreaCard from "./item-card";
import { HTTP_METHODS, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import { Button, Chip, Drawer, IconButton, Pagination } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, TextField, Typography } from "@mui/material";
import Footer from "../../component/footer";
import EngineParts from "../../component/engine-parts";
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from "react-router-dom";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";

const ExploreItems = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [recomData, setRecomData] = useState({});
  const [page, setPage] = useState(1);
  const [partData, setPartData] = useState({});
  const [searchResults, setSearchResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedPart, setSelectedPart] = useState({});
  const [selectedSubpart, setSelectedSubpart] = useState({});
  const [filters, setFilters] = useState({
    page: 1,
    limit: 32,
    sortBy: "-createdAt"
  });
  const partsRef = useRef(null);
  const [open, setOpen] = useState(false);

  const toggleDrawer = newOpen => () => {
    setOpen(newOpen);
  };

  const handleApiError = error => {
    console.error("API Error:", error);
    toast.error("Something went wrong. Please try again later.", {
      autoClose: 2000
    });
  };

  const getMostViewedPartData = filter => {
    plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.PART}`, null, filter)
      .then(res => {
        if (res?.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const listWithRating = res?.results.map(ele => ({
            ...ele,
            rating: Math.ceil(4 + Math.random())
          }));
          setRecomData({ ...res, results: listWithRating });
        }
      })
      .catch(error => handleApiError(error));
  };

  const getPartsList = filter => {
    plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.PART}`, null, filter)
      .then(res => {
        if (res?.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const listWithRating = res?.results.map(ele => ({
            ...ele,
            rating: Math.round(4 + Math.random())
          }));
          setPartData({ ...res, results: listWithRating });
        }
      })
      .catch(error => handleApiError(error));
  };

  const getSearchParts = (searchTerm, mainPartId, partNo) => {
    const params = {
      modelNumber: searchParams.get("engine")
    };
    if (searchTerm) {
      params.searchInput = searchTerm;
    }
    if (mainPartId) {
      params.mainPartId = mainPartId;
    }
    if (partNo) {
      params.partNo = partNo;
    }
    plainApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.SEARCH_PARTS}`,
      null,
      params
    )
      .then(res => {
        if (!res.message) {
          const randomRating = Math.round(4 + Math.random());
          const partList = res?.map(ele => ({
            part: `Part Number- ${
              ele?.partNo || ele?.mainPartId
            }, Model Number- ${ele?.modelNumber}`,
            id: ele?.id,
            key: ele?.id,
            rating: randomRating,
            ...ele
          }));
          setSearchResults(partList);
        }
      })
      .catch(error => handleApiError(error));
  };

  const searchInputChange = value => {
    setSearchTerm(value);
    setSelectedPart({});
    setSelectedSubpart({});
    if (value && value.trim() && !value.includes(",")) {
      getSearchParts(value);
    } else {
      setSearchResults([]);
      setSelectedResult([]);
    }
  };

  const selectSearchHandler = res => {
    const filterPart = searchResults.filter(ele => ele.part === res.part);
    setSelectedResult(filterPart);
    setSearchTerm(res.part);
    setSearchResults([]);
  };

  const executeScroll = () => {
    partsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEffect(() => {
    if (Number.isInteger(parseInt(filters?.page))) {
      setPage(filters.page);
    }
  }, [filters?.page]);

  useEffect(() => {
    if (!searchTerm) {
      setSelectedResult([]);
      setSearchResults([]);
    }
  }, [searchTerm]);

  useEffect(() => {
    getMostViewedPartData({ ...filters, page: 1, sortBy: "-views", limit: 4 });
    getPartsList({ ...filters, page: 1 });
  }, []);

  const onSelectItem = (part, subPart) => {
    setSelectedPart(part);
    setSelectedSubpart(subPart || {});
    getSearchParts("", part?.id, subPart?.partNo);
    setOpen(false);
  };

  const onClearSelection = () => {
    setSearchResults([]);
    setSelectedResult([]);
    setSelectedPart({});
    setSelectedSubpart({});
  };

  const removeSubpart = () => {
    setSearchResults([]);
    setSelectedResult([]);
    setSelectedSubpart({});
    getSearchParts("", selectedPart?.id);
  };

  return (
    <div className="explore-items drop-in">
      <Grid container spacing={1}>
        <Grid
          item
          md={3}
          sm={12}
          sx={{
            display: { xs: "none", sm: "none", md: "flex" }
          }}
        >
          <EngineParts
            selectedPart={selectedPart?.id}
            selectedSubpart={selectedSubpart?.partNo}
            onClick={onSelectItem}
          />
        </Grid>
        <Grid item md={9} sm={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Typography
              variant="h4"
              sx={{
                paddingBottom: 0.5,
                mt: 2,
                mb: 2,
                fontSize: { md: "1.5rem", xs: "1rem" }
              }}
              className="primary-text"
            >
              <IconButton onClick={() => navigate("/buy-parts")}>
                <ArrowBackIosNewIcon />
              </IconButton>{" "}
              SIMPSONS PRODUCTS JUST FOR YOU
            </Typography>
            <Button
              variant="outlined"
              sx={{
                display: { xs: "none", sm: "none", md: "flex" } // Hide on xs (mobile), show on sm and above
              }}
              onClick={() =>
                navigate({
                  pathname: "/visualize-products",
                  search: createSearchParams({
                    engine: searchParams.get("engine"),
                    listNo: searchParams.get("listNo")
                  }).toString()
                })
              }
            >
              Visualize {searchParams.get("engine")} |{" "}
              {searchParams.get("listNo")}
            </Button>
            <div>
              <IconButton
                onClick={toggleDrawer(true)}
                sx={{
                  display: { sm: "flex", md: "none" }
                }}
              >
                <FilterAltOutlinedIcon />
              </IconButton>
              <Drawer open={open} onClose={toggleDrawer(false)}>
                <EngineParts
                  selectedPart={selectedPart?.id}
                  selectedSubpart={selectedSubpart?.partNo}
                  onClick={onSelectItem}
                />
              </Drawer>
            </div>
          </div>

          {!selectedPart?.id ? (
            <div className="search-con">
              <div className="search-options-cards">
                <div>
                  <TextField
                    variant="outlined"
                    value={searchTerm}
                    onChange={e => searchInputChange(e.target.value)}
                    placeholder="Search Engine By Part Number, Model Number, Tags"
                    fullWidth
                    sx={{
                      backgroundColor: "white",
                      borderColor: "gray",
                      borderRadius: "10px"
                    }}
                    InputProps={{
                      endAdornment: searchTerm ? (
                        <CloseIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setSearchResults([]);
                            setSelectedResult([]);
                            setSearchTerm("");
                          }}
                        />
                      ) : null
                    }}
                  />
                  {searchResults.length > 0 && (
                    <Grid container className="search-list-con">
                      {searchResults.map(result => (
                        <Grid item key={result.id} xs={12}>
                          <div
                            className="search-item"
                            onClick={() => selectSearchHandler(result)}
                          >
                            <Typography>{result.part}</Typography>
                          </div>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div style={{ paddingTop: 20, display: "flex", gap: "20px" }}>
              <Chip
                label={selectedPart?.label}
                variant="outlined"
                onDelete={onClearSelection}
              />
              {selectedSubpart?.label && (
                <Chip
                  label={selectedSubpart?.label}
                  variant="outlined"
                  onDelete={removeSubpart}
                />
              )}
            </div>
          )}

          {searchResults.length > 0 ? (
            <Grid container md={12}>
              {searchResults.map(part => (
                <Grid
                  item
                  md={3}
                  sx={{ display: "flex", justifyContent: "center", mt: 4 }}
                  key={part.id}
                >
                  <MultiActionAreaCard details={part} />
                </Grid>
              ))}
            </Grid>
          ) : selectedResult.length > 0 ? (
            <Grid container md={12}>
              {selectedResult.map(part => (
                <Grid
                  item
                  md={3}
                  sx={{ display: "flex", justifyContent: "center", mt: 4 }}
                  key={part.id}
                >
                  <MultiActionAreaCard details={part} />
                </Grid>
              ))}
            </Grid>
          ) : (
            ""
          )}
          {searchResults.length === 0 && selectedResult.length === 0 && (
            <div className="explore-items-container">
              {/* <h2>Recommended for you</h2>
              <Grid container md={12}>
                {recomData?.results?.map(part => (
                  <Grid
                    item
                    md={3}
                    sx={{ display: "flex", justifyContent: "center" }}
                    key={part.id}
                  >
                    <MultiActionAreaCard details={part} />
                  </Grid>
                ))}
              </Grid> */}
              <div className="flex-row">
                <Typography variant="h4" ref={partsRef}>
                  Parts
                </Typography>
                <Button
                  variant="outlined"
                  sx={{
                    display: { sm: "flex", md: "none" } // Hide on xs (mobile), show on sm and above
                  }}
                  onClick={() =>
                    navigate({
                      pathname: "/visualize-products",
                      search: createSearchParams({
                        engine: searchParams.get("engine"),
                        listNo: searchParams.get("listNo")
                      }).toString()
                    })
                  }
                >
                  Visualize {searchParams.get("engine")} |{" "}
                  {searchParams.get("listNo")}
                </Button>
              </div>

              {partData?.results?.length > 0 && (
                <div>
                  <Grid
                    container
                    md={12}
                    spacing={1}
                    sx={{ justifyContent: "center" }}
                  >
                    {partData?.results?.map(part => (
                      <Grid item md={3} sm={12} key={part.id}>
                        <MultiActionAreaCard details={part} isSale={true} />
                      </Grid>
                    ))}
                  </Grid>
                  <div className="si-pagination">
                    {partData?.totalPages > 1 && (
                      <Pagination
                        count={partData.totalPages}
                        color="primary"
                        page={page}
                        onChange={(e, page) => {
                          setPage(page);
                          getPartsList({ ...filters, page: page });
                          executeScroll();
                        }}
                      ></Pagination>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default ExploreItems;
