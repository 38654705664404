import { Button } from "@mui/material";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";

export const getHeaderConfig = () => {
  const config = [
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Email",
      id: "email",
    },
    {
      label: "Mobile Number",
      id: "phoneNumber",
    },
    {
      label: "Role",
      id: "role",
      render: (data) => {
        return <div style={{ textTransform: "capitalize" }}>{data?.role}</div>;
      },
    },
    {
      label: "Created At",
      id: "createdAt",
      render: (data) => {
        const date = moment(data.createdAt).format("DD-MM-YYYY, hh:mm a");
        return date;
      },
    },
    {
      label: "Action",
      id: "action",
      render: (data, onClick) => {
        return (
          <div>
            <EditIcon
              sx={{ cursor: "pointer" }}
              onClick={() => onClick(data)}
            />
          </div>
        );
      },
    },
  ];
  return config;
};

export const userRoles = [
  { label: "User", value: "user" },
  { label: "Admin", value: "admin" },
];
