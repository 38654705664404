export const REST_URLS = {
  LOGIN: "v1/auth/login",
  REFRESH_TOKEN: "v1/auth/refresh-tokens",
  REGISTER: "v1/auth/register",
  PART: "v1/part",
  CART: "v1/cart",
  ORDER: "v1/orders",
  SEARCH_PARTS: "v1/part/search-by-regex",
  SEARCH_MANUALS: "v1/manual/search-by-regex",
  INCREASE_VIEWS: "v1/part/increase-views",
  USERS: "v1/users",
  MANUAL: "v1/manual",
  NEWS: "v1/news",
  GET_CUSTOM_PART_LIST: "v1/part/custom-part-list",
  KNOWLEDGE_BASE: "v1/knowledge-base",
  RELEASE_NOTES: "v1/release-note",
  GET_MY_ORDERS: "v1/orders/get-my-orders",
};
// export const HOSTNAME = "http://localhost:5001/";
export const HOSTNAME =
  "https://eparts-api.kindpebble-ba7eb818.centralindia.azurecontainerapps.io/";
