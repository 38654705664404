import React from "react";
import "./index.scss";
import { Typography, Grid } from "@mui/material";
import SideContent from "../info-for-shareholder/side-content";
import Footer from "../../component/footer";
import CERT1 from "../../resources/quality/1quality-certification-9001.jpg";
import CERT2 from "../../resources/quality/2quality-certification-9001.jpg";
import CERT3 from "../../resources/quality/3quality-certification-14001.jpg";
import CERT4 from "../../resources/quality/4quality-certification-14001.jpg";

const Quality = () => {
  return (
    <div className="page-load">
      <div className="shareholder-info-con drop-in">
        <Grid container spacing={7}>
          <Grid item md={8} sx={{ gridGap: 10 }}>
            <Typography
              variant="h4"
              sx={{
                borderBottom: "1px solid #ded8d7",
                paddingBottom: 2,
                fontSize: { md: "2rem", xs: "1.5rem" },
                mb: 3,
              }}
              className="primary-text"
            >
              Quality
            </Typography>
            <Typography variant="body2">
              It is Simpsons’ policy to offer highly reliable products to
              customers. Its quality programs focus on process control,
              inspection through integrated approach and effective supplier
              quality assurance programme.
            </Typography>
            <ul className="quality-points">
              <li>State-of-the-art inspection / testing facilities</li>
              <li>Computerized Measuring Machines (CMM)</li>{" "}
              <li>Effective Supplier Quality Assurance Programmes</li>{" "}
              <li>Surface finish Analysers</li>{" "}
            </ul>
            <Typography variant="h5" sx={{ mt: 5, mb: 1 }}>
              Quality Policy
            </Typography>
            <Typography variant="body2">
              It is our policy to meet and maintainleadership in the market we
              serve by providing products and services which meet / exceed
              customer expectations ,statutory and regulatory requirements with
              emphasis on continuous improvement in all our processeswith total
              commitment and involvement of all employees.
            </Typography>
            <div>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  mt: 2,
                  mb: 3,
                  color: "#0092CE",
                }}
              >
                Quality Management System
              </Typography>
              <Grid container spacing={5}>
                <Grid item md={6}>
                  <img src={CERT1} width="100%" />
                </Grid>
                <Grid item md={6}>
                  <img src={CERT2} width="100%" />
                </Grid>
              </Grid>
            </div>
            <div>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  mt: 3,
                  mb: 3,
                  color: "#0092CE",
                }}
              >
                Environment Management System
              </Typography>
              <Grid container spacing={5}>
                <Grid item md={6}>
                  <img src={CERT3} width="100%" />
                </Grid>
                <Grid item md={6}>
                  <img src={CERT4} width="100%" />
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item md={2}>
            <div className="si-hide-mobile">
              <SideContent />
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default Quality;
