import React from "react";
import "./index.scss";
import ENGINE_IMG from "../../resources/e1.png";
import ENGINE_SPARES from "../../resources/engine-spares.jpg";
import PAINTS from "../../resources/paints.jpg";

import { useNavigate } from "react-router-dom";

const SideContent = () => {
  const navigate = useNavigate();
  return (
    <div className="products-page">
      <div className="box1">
        <p>
          <img src={ENGINE_IMG} alt="engine" />
        </p>
        <div className="page-link">Engine</div>
      </div>
      <div className="box1">
        <p>
          <img src={ENGINE_SPARES} alt="engine" />
        </p>
        <div className="page-link" onClick={() => navigate("/spare-parts")}>
          Spare Parts
        </div>
      </div>
      <div className="box1">
        <p>
          <img src={PAINTS} alt="engine" />
        </p>
        <div className="page-link" onClick={() => navigate("/paints")}>
          Paints
        </div>
      </div>
    </div>
  );
};

export default SideContent;
