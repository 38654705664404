import { Button, IconButton, TextField } from "@mui/material";
import "./index.scss";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
const ItemQty = ({ qty, onAdd, onSubtract, maxOrderQuantity }) => {
  return (
    <div className="item-qty">
      <div>
        <Button onClick={onSubtract} size="small">
          <RemoveIcon fontSize="small" />
        </Button>
      </div>
      <div className="count-btn-con">{qty}</div>

      <div>
        <Button onClick={onAdd} disabled={qty >= maxOrderQuantity} size="small">
          <AddIcon fontSize="small" />
        </Button>
      </div>
    </div>
  );
};

export default ItemQty;
