import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Card,
  Divider,
  Step,
  StepLabel,
  Stepper,
  Typography,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { useSelector } from "react-redux";
import { HTTP_METHODS, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import Footer from "../../component/footer";
import userProfile from "../../resources/user-profile.png";
import { getOfflineData } from "../../utils/offline-services";
import { toast } from "react-toastify";
import changePassword from "../../resources/change-password.png";
const steps = ["Order Confirmed", "Shipped", "Out For Delivery", "Delivered"];

const MyAccount = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    id: "",
  });
  const [confirmPassword, setConfirmPassword] = useState("");
  const handleChange = (e) => {
    setUserDetails((prevVal) => ({
      ...prevVal,
      [e.target.name]: e.target.value,
    }));
  };
  const changePasswordHandler = () => {
    if (!userDetails.password) {
      toast.error("Please enter password", { autoClose: 2000 });
      return;
    }
    if (userDetails.password !== confirmPassword) {
      toast.error("Password and confirm password do not match", {
        autoClose: 2000,
      });
    } else {
      const id = userDetails?.id;
      plainApi(HTTP_METHODS.PUT, `${HOSTNAME}${REST_URLS.USERS}/${id}`, {
        password: userDetails.password,
      })
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            toast.success("Password Reset Successfully", { autoClose: 2000 });
            setUserDetails((prevVal) => ({ ...prevVal, password: "" }));
            setConfirmPassword("");
          }
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    const userInfo = getOfflineData("user");
    setUserDetails({
      name: userInfo?.name,
      email: userInfo?.email,
      role: userInfo?.role,
      id: userInfo?.id,
    });
  }, []);

  return (
    <div>
      <div className="user-account-con">
        <div className="profile-con">
          <h3>My Profile</h3>

          <Grid md={12} container>
            <Grid item md={6}>
              <Grid
                item
                md={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  <img
                    src={userProfile}
                    className="profile-picture si-hide-mobile"
                  />
                </div>
              </Grid>
              <Grid item md={12} sx={{ paddingLeft: "30%" }}>
                <Grid
                  item
                  md={12}
                  sx={{
                    display: "flex",
                    gridGap: "2rem",
                    alignItems: "center",
                  }}
                >
                  <h3>Name</h3>
                  <h3>{userDetails?.name}</h3>
                </Grid>
                <Grid
                  item
                  md={12}
                  sx={{
                    display: "flex",
                    gridGap: "2rem",
                    alignItems: "center",
                  }}
                >
                  <h3>Email</h3>
                  <h3 className="title">{userDetails?.email}</h3>
                </Grid>
                <Grid
                  item
                  md={12}
                  sx={{
                    display: "flex",
                    gridGap: "2rem",
                    alignItems: "center",
                  }}
                >
                  <h3>Role</h3>
                  <h3 style={{ textTransform: "capitalize" }}>
                    {userDetails?.role}
                  </h3>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              md={6}
              sm={12}
              sx={{
                display: "flex",
                alignItems: "center",
                paddingX: "5rem",
                flexDirection: "column",
                justifyContent: "space-between",
                borderLeft: "1px solid black",
                gridGap: "10px",
              }}
            >
              <div>
                <img
                  src={changePassword}
                  className="profile-picture si-hide-mobile"
                />
              </div>
              <h4 style={{ marginTop: "0px" }}>Change Password</h4>
              <TextField
                label="Password"
                value={userDetails.password}
                size="small"
                type="password"
                name="password"
                onChange={handleChange}
                fullWidth
                sx={{ mb: 1 }}
              />
              <TextField
                fullWidth
                id="confirmPassword"
                label="Confirm Password"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                variant="outlined"
                value={confirmPassword}
                type="password"
                size="small"
                sx={{ mb: 1 }}
              />
              <Button
                fullWidth
                variant="contained"
                onClick={changePasswordHandler}
                sx={{ mb: 5 }}
              >
                Save
              </Button>
            </Grid>
          </Grid>

          <br />
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default MyAccount;
