import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./index.scss";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { setOfflineData, getOfflineData } from "../../utils/offline-services";
import { toast } from "react-toastify";

const Register = () => {
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({});
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (getOfflineData("user")) {
      navigate("/");
    }
  }, []);

  const onInputChange = (event) => {
    setLoginDetails({
      ...loginDetails,
      [event.target.name]: event.target.value,
    });
  };

  const login = () => {
    let payload = {
      name: loginDetails.name,
      email: loginDetails.email,
      password: loginDetails.password,
    };
    invokeApi(
      HTTP_METHODS.POST,
      `${HOSTNAME}${REST_URLS.REGISTER}`,
      payload
    ).then((response) => {
      if (response.message) {
        toast.error(response.message);
        setLoginDetails({
          ...loginDetails,
          errorMsg: response.message,
        });
      } else {
        // response.user && setOfflineData("user", response.user);
        // response.tokens && setOfflineData("tokens", response.tokens);
        navigate("/login");
      }
    });
  };

  return (
    <div className="register-container">
      <div>
        <Typography variant="h5" className="title">
          Electronic Parts Catalogue Register
        </Typography>
      </div>

      <div className="welcome-info-container">
        <div className="left-section">
          <div className="w-image-container">
            <img
              src={require("../../resources/login-image.png")}
              className="info-image"
            />
          </div>
          <div className="w-message-container">
            <Typography className="warning-text">LOGON WARNING</Typography>
            <Typography>
              {" "}
              UNAUTHORISED ACCESS TO THIS SYSTEM IS STRICTLY PROHIBITED{" "}
            </Typography>
            <Typography>
              All data and information held or on in or generated by this system
              is proprietery and confidential.Any unauthorised access to or use
              or disclosure of,such information is strictly prohibited and may
              result in disciplinary action,up to including termination against
              you,including but not limited to criminal or civil prosecution, to
              the full extend permitted by applicable law. All use of this
              system is subject to monitoring, retention and disclosure to the
              extent permitted or required by applicable law without further
              notice to you.
            </Typography>
            <Typography>
              {" "}
              IF YOU ARE NOT AN AUTHORISED USER DISCONNECT IMMEDIATELY.
            </Typography>
          </div>
        </div>
        <div className="right-section">
          <div className="login-card">
            <form className="login-form">
              <div className="logo-container">
                <img
                  src={require("../../resources/log.png")}
                  className="sim-logo"
                />
              </div>

              <Typography variant="h5">Register</Typography>
              <TextField
                size="small"
                label="Name"
                name="name"
                value={loginDetails.name}
                onChange={onInputChange}
              />
              <TextField
                size="small"
                label="Email"
                name="email"
                value={loginDetails.email}
                onChange={onInputChange}
              />
              <TextField
                size="small"
                label="Password"
                type={showPassword ? "text" : "password"}
                name="password"
                value={loginDetails.password}
                onChange={onInputChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={login}
                disabled={
                  !loginDetails.email ||
                  !loginDetails.password ||
                  !loginDetails.name
                }
              >
                Register
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
              {loginDetails.errorMsg && (
                <span className="error-msg">{loginDetails.errorMsg}</span>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
