import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useSearchParams } from "react-router-dom";
import {
  CHILD_PART_LIST,
  ENGINE2_CHILD_PART_LIST,
  ENGINE2_MAIN_PART_LIST,
  MAIN_PART_LIST,
} from "../../utils/parts_data";
import { convertToCamelCaseFromUnderScore } from "../../utils/helper";
const parts = {
  SJV436: MAIN_PART_LIST,
  SJV3259: ENGINE2_MAIN_PART_LIST,
};

const childParts = {
  SJV436: CHILD_PART_LIST,
  SJV3259: ENGINE2_CHILD_PART_LIST,
};

export default function EngineParts({
  selectedPart,
  selectedSubpart,
  onClick,
}) {
  const [searchParams] = useSearchParams();
  const [open, setOpen] = React.useState("");

  React.useEffect(() => {
    if (selectedPart == open) {
      setOpen("");
    } else {
      setOpen(selectedPart || "");
    }
  }, [selectedPart]);

  const handleClick = (item) => {
    onClick(item);
  };

  return (
    <List sx={{ width: "100%", bgcolor: "background.paper" }} component="nav">
      {parts[searchParams.get("engine")]?.map((part) => {
        return (
          <>
            <ListItemButton
              selected={selectedPart === part.id}
              onClick={() => handleClick(part)}
            >
              <ListItemText primary={part.label} />
              {part.id == open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={part.id == open} timeout="auto" unmountOnExit>
              {childParts[searchParams.get("engine")].map((subPart) => {
                if (subPart.parent === part.id) {
                  return (
                    <List component="div" disablePadding sx={{ pl: 2 }}>
                      <ListItemButton
                        sx={{ pl: 2 }}
                        selected={selectedSubpart === subPart.partNo}
                        onClick={() => onClick(part, subPart)}
                      >
                        <ListItemText
                          sx={{ fontSize: "12px" }}
                          primary={`${
                            subPart.partNo
                          } ${convertToCamelCaseFromUnderScore(subPart.label)}`}
                        />
                      </ListItemButton>
                    </List>
                  );
                }
              })}
            </Collapse>
          </>
        );
      })}
    </List>
  );
}
