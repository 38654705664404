import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { HTTP_METHODS, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { toast } from "react-toastify";
import MultiActionAreaCard from "../explore-items/item-card";

const RecommandedProducts = () => {
  const [recomData, setRecomData] = useState({});

  const getMostViewedPartData = (filter) => {
    plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.PART}`, null, filter)
      .then((res) => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const listWithRating = res?.results.map((ele) => {
            const randomRating = Math.ceil(4 + Math.random());
            return { ...ele, rating: randomRating };
          });
          setRecomData({ ...res, results: listWithRating });
        }
      })
      .catch((err) => console.error(err));
  };
  useEffect(() => {
    getMostViewedPartData({ page: 1, sortBy: "-views", limit: 12 });
  }, []);
  return (
    <div>
      <Typography variant="h4" sx={{ m: 3, mt: 5 }}>
        Recommend Products
      </Typography>
      <Grid container md={12} sx={{justifyContent:'center'}} spacing={1}>
        {recomData?.results?.length > 0 &&
          recomData?.results?.map((part) => {
            return (
              <Grid
                item
                md={3}
                sm={12}
              >
                <div>
                  <MultiActionAreaCard details={part} />
                </div>
              </Grid>
            );
          })}
      </Grid>
    </div>
  );
};

export default RecommandedProducts;
