import { saveAs } from "file-saver";
import moment from "moment";
import * as XLSX from "xlsx";

const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";

export const processOrderData = (orders) => {
  let combinedData = [];

  orders.forEach((order, index) => {
    combinedData.push({
      "Order Id": order.orderId,
      Status: order.status,
      User: order.userId.name,
      "Created At": moment(order.createdAt).format("DD-MM-YYYY, hh:mm a"),
      "Updated At": moment(order.updatedAt).format("DD-MM-YYYY, hh:mm a"),
      "Part Number": order.orderItems[0].partId.partNo,
      "Model Number": order.orderItems[0].partId.modelNumber,
      "List Number": order.orderItems[0].partId.listNumber,
      Quantity: order.orderItems[0].quantity,
      "Total Price": order.orderItems[0].totalPrice,
    });
    const updatedArr = order?.orderItems?.splice(0, 1);
    const orderItemsData = updatedArr?.map((item) => ({
      "Part Number": item.partId.partNo,
      "Model Number": item.partId.modelNumber,
      "List Number": item.partId.listNumber,
      Quantity: item.quantity,
      "Total Price": item.totalPrice,
    }));

    combinedData.push(...orderItemsData);

    // Add two empty rows after each object, except for the last one
    if (index < orders.length - 1) {
      combinedData.push({});
      combinedData.push({});
    }
  });

  return combinedData;
};

export const exportToExcel = (orders, name) => {
  const wb = {
    Sheets: {},
    SheetNames: [],
  };

  const combinedData = processOrderData(orders);

  const ws = XLSX.utils.json_to_sheet(combinedData);
  wb.Sheets["Order Data"] = ws;
  wb.SheetNames.push("Order Data");

  const eb = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const blob = new Blob([eb], { type: EXCEL_TYPE });
  saveAs(blob, `${name}_order_data${EXCEL_EXTENSION}`);
};
