import React from "react";
import "./index.scss";
import { Box, Button, Chip, IconButton, Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { getItemHeaderConfig, ORDER_STATUS } from "./config";
import moment from "moment";
import MuiTable from "../../component/mui-table";
import { DownloadBtn } from "../../component/download-btn";
import { exportToExcel } from "../../utils/export-excel";
const OrderDetails = ({ orderDetails, setOrderDetails, updateOrder }) => {
  const { data } = orderDetails;

  const orderStatus = ORDER_STATUS.find(
    (ele) => ele.value === data?.status
  )?.label;

  return (
    <div className="order-details-con">
      <div className="order-id-con">
        <Typography variant="h6">
          <IconButton
            onClick={() => {
              setOrderDetails({
                isOpen: false,
                data: {},
              });
            }}
          >
            <ArrowBackIosIcon fontSize="small" />
          </IconButton>
          {data?.orderId}
        </Typography>
        <Chip
          label={orderStatus || data?.status}
          size="small"
          sx={{ ml: 2 }}
          variant="outlined"
          color={data?.status === "COMPLETED" ? "success" : "warning"}
        />
      </div>

      <div className="details-body">
        <div className="order-user-details-con">
          <Typography sx={{ fontSize: "14px" }}>
            Updated By:
            <Typography
              sx={{ color: "gray", ml: 1, fontSize: "14px" }}
              component="span"
            >
              {data?.updatedBy?.name || data?.userId?.name}
            </Typography>
            <br />
            Updated At:{" "}
            <Typography
              sx={{ color: "gray", ml: 1, fontSize: "14px" }}
              component="span"
            >
              {moment(data?.updatedAt).format("MMMM Do YYYY, h:mma")}
            </Typography>
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <DownloadBtn onClick={() => exportToExcel([data], "Orders")} />
            {data?.status !== "COMPLETED" && (
              <Button
                size="small"
                variant="outlined"
                sx={{ ml: 2 }}
                onClick={() => updateOrder(data.id || data?._id)}
              >
                mark completed
              </Button>
            )}
          </div>
        </div>
        <div className="order-items-details-con">
          <Typography variant="h6" className="primary-text">
            User Details
          </Typography>
          <div className="order-user-details-con">
            <div>
              <Typography className="default-font">Name</Typography>
              <Typography className="default-font" sx={{ color: "gray" }}>
                {data?.userId?.name}
              </Typography>
              <Typography
                className="default-font"
                sx={{ mt: { md: 2, xs: 0 } }}
              >
                Email
              </Typography>
              <Typography className="default-font" sx={{ color: "gray" }}>
                {data?.userId?.email}
              </Typography>
            </div>
            <div>
              <Typography className="default-font">Address</Typography>
              <Typography className="default-font" sx={{ color: "gray" }}>
                {data?.userId?.address?.addressLine1},{" "}
                {data?.userId?.address?.addressLine2}
              </Typography>{" "}
              <Typography className="default-font" sx={{ color: "gray" }}>
                {data?.userId?.address?.city}, {data?.userId?.address?.state}
              </Typography>{" "}
              <Typography className="default-font" sx={{ color: "gray" }}>
                {data?.userId?.address?.pinCode}
              </Typography>
            </div>
            <div>
              <Typography className="default-font">Ordered Date</Typography>
              <Typography className="default-font" sx={{ color: "gray" }}>
                {moment(data?.createdAt).format("MMMM Do YYYY")}
              </Typography>
              <Typography
                className="default-font"
                sx={{ mt: { md: 2, xs: 0 } }}
              >
                Mobile Number
              </Typography>
              <Typography className="default-font" sx={{ color: "gray" }}>
                {data?.userId?.phoneNumber}
              </Typography>
            </div>
          </div>
        </div>
        <div className="order-items-details-con">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h6" className="primary-text">
              Item Details
            </Typography>
            <Typography className="default-font">
              Total Price : INR {data?.totalSum}
            </Typography>
          </Box>

          <MuiTable
            columnsList={getItemHeaderConfig()}
            dataList={data.orderItems || []}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
