import React, { useEffect, useState } from "react";
import "./index.scss";
import { Button, Grid, Typography } from "@mui/material";
import { HTTP_METHODS, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { RELEASE_NOTES_CATEGORIES } from "../../utils/constants";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import Footer from "../../component/footer";
const releaseCategoryButtonColor = [
  { label: "BUGFIX", colr: "red-btn" },
  { label: "FEATURE", colr: "green-btn" },
  { label: "IMPROVEMENT", colr: "purple-btn" }
];

const ReleaseNotes = () => {
  const [releaseNotes, setReleaseNotes] = useState([]);
  const naviagte = useNavigate();
  const getReleaseNotes = () => {
    plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.RELEASE_NOTES}`, null, {
      limit: 1000,
      page: 1,
      sortBy: "-createdAt"
    })
      .then(res => {
        if (!res.message) {
          setReleaseNotes(res.results);
        }
      })
      .catch(err => console.error(err));
  };
  useEffect(() => {
    getReleaseNotes();
  }, []);

  return (
    <>
      <div className="release-notes-con page-load">
        <Grid container spacing={{ md: 5, xs: 2 }}>
          <Grid item md={6} sx={{ pr: 2, pl: 2 }}>
            <div>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: { md: 600, xs: 500 },
                  fontSize: { md: "3rem", xs: "1.5rem" }
                }}
              >
                New Feature updates released & Product Release Notes
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "gray",
                  mt: { md: 2, xs: 1 },
                  mb: { md: 2, xs: 1 }
                }}
              >
                Read on for a monthly recap of all things Simpsons, including
                product updates, new tutorials, upcoming events and more.
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "gray",
                  mt: { md: 2, xs: 1 },
                  mb: { md: 5, xs: 2 }
                }}
              >
                It is a 175-year long journey through ceaseless changes,
                economic transitions and technological transformations. A
                tribute to the leadership, spirit of enterprise and wisdom of
                Shri. S. Anantharamakrishnan, the Founder of the Amalgamations
                Group, who envisioned the future of Simpsons.
              </Typography>
              <Button
                variant="contained"
                onClick={() => naviagte("/visualize-products")}
                className="si-hide-mobile"
              >
                Explore Our Products
              </Button>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div className="notes-con">
              <Typography
                sx={{
                  textAlign: "center",
                  pt: 1,
                  pb: 1,
                  borderBottom: "1px solid #ebedeb",
                  color: "#6d6e6d"
                }}
                variant="h5"
              >
                Latest Updates
              </Typography>
              <div className="notes-card-con">
                {releaseNotes?.map(ele => {
                  return (
                    <div className="note-card">
                      <div className="cat-con">
                        {ele?.changes?.map(cat => {
                          const btnClr = releaseCategoryButtonColor.find(
                            ele => ele.label === cat.type
                          )?.colr;

                          return (
                            <div className={`category-btn ${btnClr}`}>
                              <Typography
                                sx={{
                                  color: "white",
                                  textTransform: "uppercase"
                                }}
                              >
                                {RELEASE_NOTES_CATEGORIES[cat?.type] ||
                                  cat?.type}
                              </Typography>
                            </div>
                          );
                        })}
                      </div>
                      <div className="title-con">
                        <Typography
                          variant="h5"
                          sx={{
                            fontWeight: { md: 600, xs: 500 },
                            color: "#6d6e6d",
                            mt: 1,
                            mb: 1,
                            fontSize: { md: "1.8rem", xs: "1.2rem" }
                          }}
                        >
                          {ele?.title}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "gray" }}>
                          {moment(ele?.releaseDate).fromNow()}
                        </Typography>
                      </div>
                      <Typography
                        variant="h6"
                        sx={{
                          color: "gray",
                          lineHeight: 1.2,
                          fontSize: { md: "1.3rem", xs: "1rem" }
                        }}
                      >
                        {ele?.description}
                      </Typography>
                    </div>
                  );
                })}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default ReleaseNotes;
