import React from "react";
import "./index.scss";
import { Grid, Typography } from "@mui/material";
import Footer from "../../component/footer";
import CSR_POLICY from "../../resources/CSR/csr-policy.pdf";
import SR_IMG from "../../resources/CSR/scl-rpy.jpg";
const SocialResponsibility = () => {
  return (
    <div className="page-load">
      <div className="shareholder-info-con drop-in">
        <Grid container spacing={7} sx={{ mb: 15 }}>
          <Grid item md={8} sx={{ gridGap: 10 }}>
            <Typography
              variant="h4"
              sx={{
                borderBottom: "1px solid #ded8d7",
                paddingBottom: 2,
                mb: 3,
                fontSize: { md: "2rem", xs: "1.5rem" },
              }}
              className="primary-text"
            >
              Social Responsibility
            </Typography>
            <Typography variant="body2" sx={{ mb: { md: 2, xs: 0 } }}>
              Social responsibility is an integral part of Simpson & Co.’s
              corporate values.
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              The company contributes to the continuous betterment of the
              quality of life of employees, their families and to the
              society.Educational institutions in rural districts to cater to
              more than 3500 students are functioning for nearly five decades.
              Besides educational needs, marriage hall and hospital have been
              established to meet the needs of the indigent public.
            </Typography>
            <Typography variant="body2" sx={{ mb: 1 }}>
              Rehabilitation centre to empower orthopaedically challenged women
              to become financially independent and a research centre that
              promotes modern agricultural practices for the benefit of farmers
              are the other corporate social responsibilities cared for by the
              Company/Group.
            </Typography>
            <Typography variant="body2">
              <a href={CSR_POLICY} target="_blank" className="file-link">
                {">"}
                {">"} To View CSR Policy
              </a>
            </Typography>
          </Grid>
          <Grid item md={4}>
            <div className="photo-box">
              <img src={SR_IMG} alt="Social Responsibility" width="100%" />
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default SocialResponsibility;
