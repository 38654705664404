import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import CHECK_MARK_IMG from "../../resources/images/check-mark.png";
import "./order-confirm.scss";

const OrderConfirmed = ({ orderDetails }) => {
  const navigate = useNavigate();
  return (
    <div className="order-confirmed">
      <img
        src={CHECK_MARK_IMG}
        alt="order confirm"
        className="check-mark-img"
      />
      <div className="text-container">
        <Typography
          variant="h2"
          className="thank-you-text"
          sx={{
            fontWeight: { md: 700, xs: 500 },
            fontSize: { md: "4rem", xs: "2rem" },
          }}
        >
          THANK YOU FOR
        </Typography>
        <Typography
          variant="h2"
          className="thank-you-text"
          sx={{
            fontWeight: { md: 700, xs: 500 },
            fontSize: { md: "4rem", xs: "2rem" },
          }}
        >
          YOUR ORDER
        </Typography>
      </div>
      <Typography
        variant="body1"
        className="confirmation-text"
        sx={{ mt: 3, mb: 3 }}
      >
        We've submitted your inquiry to Simpsons. <br />
        Our team will connect with you shortly.
      </Typography>

      <Button variant="outlined" onClick={() => navigate("/")}>
        Continue Shopping
      </Button>
    </div>
  );
};

export default OrderConfirmed;
