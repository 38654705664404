import React from "react";
import { Typography, Grid, Button, Box, IconButton } from "@mui/material";
import ItemQty from "../../component/item-qty";
import { useDispatch } from "react-redux";
import { addItem } from "../../slice/cart-slice";
import { convertToCamelCaseFromUnderScore } from "../../utils/helper";
import { DeleteOutline } from "@mui/icons-material";

const MobileMyCart = ({ cart = [], updateCart, itemsTotalAmount }) => {
  const dispatch = useDispatch();
  const onDelete = (item) => {
    updateCart(item);
  };
  return (
    <div>
      <Typography variant="h6" sx={{ ml: 2 }}>
        My Cart
      </Typography>
      {cart.length > 0 ? (
        <div>
          <Box sx={{ maxHeight: "80vh", overflow: "auto", minHeight: "50vh" }}>
            {cart?.map((item, index) => {
              const { partId } = item;
              return (
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: 1,
                    borderBottom: "1px solid gray",
                  }}
                  spacing={1}
                >
                  <Grid item xs={4}>
                    <img src={partId?.img} width="100px" />
                  </Grid>
                  <Grid item xs={5.5}>
                    <Typography>
                      {" "}
                      {convertToCamelCaseFromUnderScore(partId.label)}
                    </Typography>
                    <Typography variant="body2" sx={{ color: "gray" }}>
                      {partId?.modelNumber} - {partId?.partNo}
                    </Typography>
                    {/* <Typography variant="body1">
                      Total Price : &#x20B9;
                      {(partId.unitPrice * item.quantity)?.toFixed(2)}
                    </Typography> */}
                    <IconButton onClick={() => onDelete(partId.id)}>
                      <DeleteOutline />
                    </IconButton>
                  </Grid>
                  <Grid item xs={2.5}>
                    <ItemQty
                      onAdd={() => {
                        const updatedList = cart.map((ele) => {
                          if (ele.partId.id === item.partId.id) {
                            return {
                              ...ele,
                              quantity: item.quantity + 1,
                            };
                          } else {
                            return ele;
                          }
                        });
                        dispatch(addItem(updatedList));
                      }}
                      onSubtract={() => {
                        const updatedList = cart
                          .map((ele) => {
                            if (ele.partId.id === item.partId.id) {
                              if (item.quantity - 1 < 1) {
                                onDelete(partId.id);
                              } else {
                                return {
                                  ...ele,
                                  quantity: item.quantity - 1,
                                };
                              }
                            } else {
                              return ele;
                            }
                          })
                          .filter((ele) => ele && ele);
                        dispatch(addItem(updatedList));
                      }}
                      qty={item.quantity || 1}
                      maxOrderQuantity={partId.maxOrderQuantity || 5}
                    />
                  </Grid>
                </Grid>
              );
            })}
          </Box>
          <Button
            variant="contained"
            fullWidth
            sx={{ width: "80%", m: 4, ml: 5, mb: 10 }}
            onClick={() => updateCart(null, true)}
          >
            Send Enquiry
          </Button>
        </div>
      ) : (
        <div>
          <Typography variant="h6" sx={{ mt: 10, mb: 40, ml: 7, color: "red" }}>
            {" "}
            No Item Added in the cart
          </Typography>
        </div>
      )}
    </div>
  );
};

export default MobileMyCart;
