import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./private-route";
import Login from "../container/login";
import ExploreItems from "../container/explore-items";
import ItemDetails from "../container/item-details";
import Register from "../container/register";
import MyAccount from "../container/my-account";
import MyCart from "../container/my-cart";
import VisualizeProducts from "../container/visualize-products";
import AboutUs from "../container/about-us";
import History from "../container/history";
import FAQ from "../container/FAQ";
import Orders from "../container/orders";
import Users from "../container/users";
import NotFound from "../container/not-found";
import ServiceManual from "../container/service-manual";
import ViewVideo from "../container/service-manual/view-video";
import NewHome from "../container/new-home";
import KnowledgeBase from "../container/knowledge-base";
import AppLayout from "../container/layout";
import ReleaseNotes from "../container/release-notes";
import InformationForShareholder from "../container/info-for-shareholder";
import SpareParts from "../container/spare-parts";
import Milestones from "../container/milestones";
import Leadership from "../container/leadership";
import Manufacturing from "../container/manufacturing";
import Quality from "../container/quality";
import ResearchAndDevelopment from "../container/research-and-development";
import Others from "../container/others";
import BuyParts from "../container/buy-parts";
import Paints from "../container/paints";
import CoporateSocialResponsibility from "../container/social-responsibility";
import SocialResponsibility from "../container/social-responsibility/social-responsibility";
import MyOrders from "../container/orders/my-orders";
import Safety from "../container/safety";
import AdminKnowledgeBase from "../container/set-up/admin-knowledge-base";
import AdminReleaseNotes from "../container/set-up/admin-release-notes";
import AdminNews from "../container/set-up/admin-news";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/register" element={<Register />}></Route>
        <Route exact path="/" element={<PrivateRoute />}>
          <Route exact path="/" element={<NewHome />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/history" element={<History />} />
          <Route exact path="/faq" element={<FAQ />} />
          <Route exact path="/knowledge-base" element={<KnowledgeBase />} />
          <Route exact path="/service-manual" element={<ServiceManual />} />
          <Route exact path="/view-manual/:id" element={<ViewVideo />} />
          <Route exact path="/milestones" element={<Milestones />} />
          <Route exact path="/spare-parts" element={<SpareParts />} />
          <Route exact path="/leadership" element={<Leadership />} />
          <Route exact path="/manufacturing" element={<Manufacturing />} />
          <Route exact path="/quality" element={<Quality />} />
          <Route exact path="/others" element={<Others />} />
          <Route exact path="/paints" element={<Paints />} />
          <Route exact path="/safety" element={<Safety />} />

          <Route
            exact
            path="/cop-social-responsibility"
            element={<CoporateSocialResponsibility />}
          />
          <Route
            exact
            path="/social-responsibility"
            element={<SocialResponsibility />}
          />
          <Route
            exact
            path="/research-and-development"
            element={<ResearchAndDevelopment />}
          />

          <Route
            exact
            path="/info-for-shareholder"
            element={<InformationForShareholder />}
          />
          <Route exact path="/item-details/:itemId" element={<ItemDetails />} />

          <Route
            exact
            path="/visualize-products"
            element={<VisualizeProducts />}
          />

          <Route exact path="/release-notes" element={<ReleaseNotes />} />
          <Route exact path="/explore-items" element={<ExploreItems />} />
          <Route exact path="/my-account" element={<MyAccount />} />
          <Route exact path="/my-cart" element={<MyCart />} />
          <Route exact path="/buy-parts" element={<BuyParts />} />
          <Route exact path="/orders" element={<Orders />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/my-orders" element={<MyOrders />} />
          <Route
            exact
            path="/a-knowledge-base"
            element={<AdminKnowledgeBase />}
          />
          <Route
            exact
            path="/a-release-notes"
            element={<AdminReleaseNotes />}
          />
          <Route exact path="/a-news" element={<AdminNews />} />
          <Route exact path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRoutes;
