import { configureStore } from "@reduxjs/toolkit";
import cartReducer from "./slice/cart-slice";
import userReducer from "./slice/user-slice";

export default configureStore({
  reducer: {
    cart: cartReducer,
    user: userReducer,
  },
});
