import axios from "axios";

const convertCamelCaseToDisplayText = (txt) => {
  let strTmp = txt.replace(/([a-z])([A-Z])/g, "$1 $2");
  if (strTmp && strTmp.length > 0) {
    strTmp = `${strTmp[0].toUpperCase()}${strTmp.slice(1)}`;
  }
  return strTmp;
};

export const convertToCamelCaseFromUnderScore = (str) => {
  return convertCamelCaseToDisplayText(
    (str || "").toLowerCase().replace(/_+([a-z])/g, (m, w) => w.toUpperCase())
  );
};
export function removeUnderscoreAndAfter(str) {
  return str.split("_")[0];
}
export const openOrRefreshTab = (path) => {
  // Construct the full URL
  const fullUrl = `${window.location.origin}${path}`;

  // Attempt to open a new tab with a specific name
  let newTab = window.open(fullUrl);

  // Check if the tab was opened successfully
  if (newTab) {
    // If the tab is already open and it has the same name, refresh it
    if (newTab.location.href === fullUrl) {
      newTab.location.reload(); // Refresh the tab
    }
  }
};
