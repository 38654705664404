import React from "react";
import "./index.scss";
import { Grid, Typography } from "@mui/material";
import Footer from "../../component/footer";
import TERM_PDF from "../../resources/termsConditions.pdf";
import ANNUAL_RETURN_2020_21 from "../../resources/annualReturn-2020-21.pdf";
import ANNUAL_RETURN_2021_22 from "../../resources/annualReturn-2021-22.pdf";
import ANNUAL_RETURN_2022_23 from "../../resources/annualReturn-2022-23.pdf";
import ANNUAL_RETURN_2023_24 from "../../resources/annualReturn-2023-24.pdf";
import ANNUAL_REPORT_2019_20 from "../../resources/annualReport-2019-20.pdf";
import ANNUAL_REPORT_2020_21 from "../../resources/annualReport-2020-21.pdf";
import ANNUAL_REPORT_2021_22 from "../../resources/annualReport-2021-22.pdf";
import ANNUAL_REPORT_2022_23 from "../../resources/annualReport-2022-23.pdf";
import ANNUAL_REPORT_2023_24 from "../../resources/annualReport-2023-24.pdf";
import SRUTINIZER_REPORT from "../../resources/srutinizerReport.pdf";
import SRUTINIZER_REPORT96 from "../../resources/96thAGM_SCRUTINIZERS REPORT.pdf";
import SRUTINIZER_REPORT97 from "../../resources/97th AGM Evoting results.pdf";
import SRUTINIZER_REPORT98 from "../../resources/98th AGM Evoting results.pdf";
import NOTICE_OF_EXTRA_ORDINARY_MEETING from "../../resources/noticeofExtraOrdinaryGeneralMeeting.pdf";
import SRUTINIZER_REPORT_EGM from "../../resources/scrutinizerReport-EGM.pdf";
import SideContent from "./side-content";
import NODAL from "../../resources/Nodal.jpg";

const InformationForShareholder = () => {
  return (
    <div className="page-load">
      <div className="shareholder-info-con drop-in">
        <Grid container spacing={7}>
          <Grid item md={8} sx={{ gridGap: 10 }}>
            <Typography
              variant="h4"
              sx={{
                borderBottom: "1px solid #ded8d7",
                paddingBottom: 2,
                fontSize: { md: "2rem", xs: "1.5rem" },
                mb: 3,
              }}
              className="primary-text"
            >
              Information for Shareholder
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mb: { md: 2, xs: 1 },
                fontSize: { md: "1.5rem", xs: "1rem" },
              }}
            >
              Unpaid Dividend Account
            </Typography>
            <Typography
              variant="h6"
              sx={{
                mb: { md: 2, xs: 1 },
                fontSize: { md: "1.5rem", xs: "1rem" },
              }}
            >
              Code for Independent Directors
            </Typography>
            <Typography variant="h6">
              <a href={TERM_PDF} target="_blank" className="file-link">
                Terms &amp; Conditions of Appointment
              </a>
            </Typography>
            <Typography variant="h6">
              <a
                href={ANNUAL_RETURN_2020_21}
                target="_blank"
                className="file-link"
              >
                Annual Return-2020-21
              </a>
            </Typography>
            <Typography variant="h6">
              <a
                href={ANNUAL_RETURN_2021_22}
                target="_blank"
                className="file-link"
              >
                Annual Return-2021-22
              </a>
            </Typography>
            <Typography variant="h6">
              <a
                href={ANNUAL_RETURN_2022_23}
                target="_blank"
                className="file-link"
              >
                Annual Return-2022-23
              </a>
            </Typography>
            <Typography variant="h6">
              <a
                href={ANNUAL_RETURN_2023_24}
                target="_blank"
                className="file-link"
              >
                Annual Return-2023-24
              </a>
            </Typography>

            <Typography
              variant="h6"
              sx={{ mb: 2, mt: 2, fontSize: { md: "1.5rem", xs: "1rem" } }}
            >
              Notice to the Annual General Meeting and Annual Report
            </Typography>
            <Typography variant="h6">
              <a
                href={ANNUAL_REPORT_2019_20}
                target="_blank"
                className="file-link"
              >
                2019-2020
              </a>
            </Typography>
            <Typography variant="h6">
              <a
                href={ANNUAL_REPORT_2020_21}
                target="_blank"
                className="file-link"
              >
                2020-2021
              </a>
            </Typography>
            <Typography variant="h6">
              <a
                href={ANNUAL_REPORT_2021_22}
                target="_blank"
                className="file-link"
              >
                2021-2022
              </a>
            </Typography>
            <Typography variant="h6">
              <a
                href={ANNUAL_REPORT_2022_23}
                target="_blank"
                className="file-link"
              >
                2022-2023
              </a>
            </Typography>
            <Typography variant="h6">
              <a
                href={ANNUAL_REPORT_2023_24}
                target="_blank"
                className="file-link"
              >
                2023-2024
              </a>
            </Typography>
            <Typography variant="h6">
              <a href={SRUTINIZER_REPORT} target="_blank" className="file-link">
                95th Annual General Meeting-Evoting Results
              </a>
            </Typography>
            <Typography variant="h6">
              <a
                href={SRUTINIZER_REPORT96}
                target="_blank"
                className="file-link"
              >
                96th Annual General Meeting-Evoting Results
              </a>
            </Typography>
            <Typography variant="h6">
              <a
                href={SRUTINIZER_REPORT97}
                target="_blank"
                className="file-link"
              >
                97th Annual General Meeting-Evoting Results
              </a>
            </Typography>
            <Typography variant="h6">
              <a
                href={SRUTINIZER_REPORT98}
                target="_blank"
                className="file-link"
              >
                98th Annual General Meeting-Evoting Results
              </a>
            </Typography>
            <Typography variant="h6">
              <a
                href={NOTICE_OF_EXTRA_ORDINARY_MEETING}
                target="_blank"
                className="file-link"
              >
                Notice of Extra-Ordinary General Meeting
              </a>
            </Typography>
            <Typography variant="h6">
              <a
                href={SRUTINIZER_REPORT_EGM}
                target="_blank"
                className="file-link"
              >
                Extra-ordinary General Meeting-E-voting results
              </a>
            </Typography>
          </Grid>
          <Grid item md={2}>
            <img
              src={NODAL}
              alt="nodal-officer"
              width="320px"
              style={{ marginBottom: "30px" }}
            />
            <div className="si-hide-mobile">
              <SideContent />
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default InformationForShareholder;
