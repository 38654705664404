import React, { useState } from "react";
import "./nav.scss";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
  Collapse,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { getNavList } from "./config";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useLocation } from "react-router-dom";

const Navbar = ({
  user,
  logoutHandler,
  navigate,
  anchorElUser,
  setAnchorElUser,
  logo,
}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState({});
  const { pathname } = useLocation();
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleMenuClick = (label) => {
    setOpenMenu((prevOpenMenu) => ({
      ...prevOpenMenu,
      [label]: !prevOpenMenu[label],
    }));
  };

  const renderSubMenu = (submenu) => (
    <List component="div" disablePadding>
      {submenu.map((subItem) => (
        <div
          className={pathname === subItem.url && "active-list"}
          onClick={() => redirectToRoute(subItem.url)}
        >
          <ListItem key={subItem.label} sx={{ padding: 0.5, pl: 5, pr: 1.5 }}>
            <ListItemText primary={subItem.label} />
          </ListItem>
        </div>
      ))}
    </List>
  );
  const redirectToRoute = (url) => {
    navigate(url);
    setDrawerOpen(false);
  };
  const navList = getNavList();

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", padding: "2px 1rem" }}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
        >
          <MenuIcon />
        </IconButton>
        <div className="logo-bg">
          <img src={logo} alt="LOGO" height="30px" width="100px" />
        </div>

        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          {Object.keys(user).length ? (
            <div>
              <AccountCircleIcon
                onClick={(event) => setAnchorElUser(event.currentTarget)}
                sx={{ cursor: "pointer" }}
                color="primary"
              />
              <Menu
                id="basic-menu"
                anchorEl={anchorElUser}
                open={Boolean(anchorElUser)}
                onClose={() => setAnchorElUser(null)}
                sx={{ mt: 1 }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => {
                    navigate("/my-account");
                    setAnchorElUser(null);
                  }}
                >
                  My Account
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    logoutHandler();
                    setAnchorElUser(null);
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </div>
          ) : (
            <Button
              variant="text"
              color="primary"
              size="small"
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
          )}
        </Box>
      </Box>
      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerToggle}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            margin: 2,
          }}
        >
          <img src={logo} alt="LOGO" height="30px" width="100px" />
          <IconButton onClick={handleDrawerToggle}>
            <CloseIcon />
          </IconButton>
        </Box>
        <List>
          {navList.map((item) => (
            <div
              key={item.label}
              className={pathname === item?.url && "active-list"}
              onClick={
                item.submenu
                  ? () => handleMenuClick(item.label)
                  : () => redirectToRoute(item?.url)
              }
            >
              <ListItem href={item.url} sx={{ padding: 0.5, pl: 1.5, pr: 1.5 }}>
                {item.icon && item.icon}
                <ListItemText primary={item.label} />
                {item.submenu && (
                  <IconButton edge="end">
                    {openMenu[item.label] ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </IconButton>
                )}
              </ListItem>
              {item.submenu && (
                <Collapse in={openMenu[item.label]}>
                  {renderSubMenu(item.submenu)}
                </Collapse>
              )}
            </div>
          ))}
        </List>
      </Drawer>
    </>
  );
};

export default Navbar;
