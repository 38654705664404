import React, { useState, useEffect } from "react";
import "./index.scss";
import {
  Grid,
  Button,
  IconButton,
  Card,
  TextField,
  CardContent
} from "@mui/material";
import {
  convertToCamelCaseFromUnderScore,
  removeUnderscoreAndAfter
} from "../../utils/helper";
import { ENGINE_LIST, findInChildParts } from "./config";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useDispatch, useSelector } from "react-redux";
import { HTTP_METHODS, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { useLocation } from "react-router-dom";
import { addItem } from "../../slice/cart-slice";
import { setOfflineData } from "../../utils/offline-services";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

const PartsDetailView = ({
  parentList,
  childList,
  activeEngine,
  activeListNo,
  goBackHandler,
  selectParts
}) => {
  const [selectedPartId, setSelectedPartId] = useState("");
  const [isExpanded, setExpanded] = useState(false);
  const [isPartExpanded, setPartExpanded] = useState(false);
  const [selectedChildPartId, setSelectedChildPartId] = useState("");
  const [selectedEngineDetails, setSelectedEngineDetails] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredParentList, setFilteredParentList] = useState(parentList);
  const [filteredChildList, setFilteredChildList] = useState(childList);

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const cartDetails = useSelector(state => state.cart.items || []);

  useEffect(() => {
    if (selectParts && selectParts.objectName) {
      // TO DO enabled parent click event
      // const foundParent = parentList.find(
      //   p => p.id === selectParts.collectionName
      // );
      // if (foundParent && !isPartExpanded) {
      //   if (document.getElementById(selectParts.collectionName)) {
      //     document.getElementById(selectParts.collectionName).scrollIntoView({
      //       behavior: "smooth", // Enables smooth scrolling
      //       block: "start" // Aligns to the top of the element
      //     });
      //     setSelectedPartId(selectParts.collectionName);
      //   }
      // }

      const fondInChild = findInChildParts(childList, selectParts);
      if (fondInChild) {
        setSelectedChildPartId(fondInChild.partNo);
      }
    }
  }, [selectParts]);

  const onToggleExplodeEngine = () => {
    setExpanded(prev => !prev);
    setSelectedPartId("");
    setPartExpanded(false);
    setSelectedChildPartId("");
  };

  useEffect(() => {
    const MyActiveEngine = ENGINE_LIST.find(
      engine => engine.label === activeEngine
    );
    setSelectedEngineDetails(MyActiveEngine || {});
    setExpanded(false);
  }, [activeEngine]);

  useEffect(() => {
    const filterList = parentList.filter(
      part =>
        part?.label?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        part?.partNo?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        part?.id?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
    setFilteredParentList(
      filterList.sort((x, y) => x.label.localeCompare(y.label))
    );
  }, [searchTerm, parentList]);

  useEffect(() => {
    const filterList = childList.filter(
      part =>
        part?.label?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        part?.partNo?.toLowerCase().includes(searchTerm?.toLowerCase()) ||
        part?.id?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
    setFilteredChildList(
      filterList.sort((x, y) => x.label.localeCompare(y.label))
    );
  }, [searchTerm, childList]);

  const partDetailsByPartNo = (part, partType = "child") => {
    const payload = { limit: 1 };
    if (partType === "parent") {
      payload.mainPartId = part?.id;
    } else {
      payload.partNo = part?.partNo;
    }

    plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.PART}`, null, payload)
      .then(res => {
        if (res.message) {
          toast.error(res.message, { autoClose: 2000 });
        } else {
          const resultArr = res?.results || [];
          onAddToCart(resultArr[0]);
        }
      })
      .catch(err =>
        toast.error(`Error fetching part details: ${err.message}`, {
          autoClose: 2000
        })
      );
  };

  const onAddToCart = part => {
    if (!part) return;
    const updatedCart = cartDetails.map(ele => ({
      partId: ele?.partId.id,
      quantity: ele.quantity
    }));
    const payload = {
      cartItems: [
        ...updatedCart,
        {
          partId: part?.id,
          quantity: 1
        }
      ]
    };

    plainApi(HTTP_METHODS.PUT, `${HOSTNAME}${REST_URLS.CART}`, payload)
      .then(response => {
        if (response.message) {
          if (response.message.includes("duplicate")) {
            toast.info("Part is already in the cart", { autoClose: 2000 });
          } else if (response.code === 401) {
            setOfflineData("redirectRoute", pathname);
            setOfflineData("visualEngine", {
              engineName: selectedEngineDetails?.value,
              listNo: activeListNo.value
            });
          } else {
            toast.error(response.message, { autoClose: 2000 });
          }
        } else {
          getMyCart();
          toast.success("Part Added to Cart", { autoClose: 2000 });
          // openOrRefreshTab("/my-cart");
        }
      })
      .catch(err =>
        toast.error(`Error adding to cart: ${err.message}`, { autoClose: 2000 })
      );
  };

  const getMyCart = () => {
    plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.CART}`)
      .then(response => {
        const { cartItems = [] } = response;
        dispatch(addItem(cartItems));
      })
      .catch(err =>
        toast.error(`Error fetching cart: ${err.message}`, { autoClose: 2000 })
      );
  };

  const movedToView = partNo => {
    const element = document.getElementById(partNo);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth", 
        block: "start" 
      });
    }
  };

  return (
    <div className="page-load">
      <div className="visualizeProductsCon">
        <div className="sub-parts-btn-con">
          <div className="engine-list-label-con">
            <IconButton
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
              onClick={goBackHandler}
            >
              <ArrowBackIosIcon color="black" />
            </IconButton>
            <p>
              {selectedEngineDetails?.label} <span>|</span> {activeListNo}
            </p>
          </div>
          <div className="si-hide-mobile">
            <div
              id="sub-part-buttons"
              className={selectedPartId ? "" : "hidden-part"}
              style={{
                display: selectedPartId && isExpanded ? "block" : "none"
              }}
            >
              <Button
                id="explod_sub"
                variant="contained"
                size="small"
                sx={{ mr: 1 }}
                disabled={isPartExpanded}
                onClick={() => {
                  setPartExpanded(true);
                  setSelectedChildPartId("");
                  if (searchTerm) {
                    setSearchTerm("");
                  }
                }}
              >
                Explode Part
              </Button>
              <Button
                id="assemble_sub"
                variant="contained"
                size="small"
                disabled={!isPartExpanded}
                onClick={() => {
                  setPartExpanded(false);
                  setSelectedChildPartId("");
                  setSearchTerm("");
                }}
              >
                Assemble Part
              </Button>
            </div>
          </div>
        </div>
        <Grid container>
          <Grid item md={9} xs={12}>
            <div className="v3-image-container">
              {activeEngine === "SJV436" ? (
                <iframe
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  src="./engine_parts/Engine 1_Draft/render.html"
                ></iframe>
              ) : (
                <iframe
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  src="./engine_parts/Engine_2_Draft/render.html"
                ></iframe>
              )}

              <div className="button-list">
                <Button
                  id="explode"
                  variant="contained"
                  size="small"
                  disabled={isExpanded}
                  onClick={onToggleExplodeEngine}
                >
                  Explode engine
                </Button>
                <Button
                  id="assemble"
                  variant="contained"
                  size="small"
                  disabled={!isExpanded}
                  onClick={() => setExpanded(false)}
                >
                  Assemble engine
                </Button>
              </div>
            </div>
          </Grid>

          <Grid
            item
            md={3}
            sx={{ pl: { md: 2, xs: 0 }, mt: { xs: 2, md: 0 } }}
            xs={12}
          >
            <Card sx={{ height: "100%", maxHeight: "80vh" }}>
              <CardContent sx={{ display: isExpanded ? "block" : "none" }}>
                <div className="part-search-con">
                  <p className="radio-buttons-group-label">
                    {isPartExpanded ? "Select Sub Part" : "Select Part"}
                  </p>
                  <div>
                    <TextField
                      fullWidth
                      placeholder="Search"
                      variant="outlined"
                      size="small"
                      value={searchTerm}
                      InputProps={{
                        endAdornment: searchTerm && (
                          <IconButton onClick={() => setSearchTerm("")}>
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        ),
                        startAdornment: (
                          <SearchIcon sx={{ mr: 2 }} fontSize="small" />
                        ),
                        sx: { borderRadius: "20px", mb: 1 }
                      }}
                      onChange={e => {
                        setSearchTerm(e?.target?.value);
                      }}
                    />
                  </div>
                </div>
                {/* parts Buttons for mobile */}
                <div className="si-hide-web sm-mb">
                  <div
                    id="sub-part-buttons"
                    className={selectedPartId ? "" : "hidden-part"}
                    style={{
                      display: selectedPartId && isExpanded ? "block" : "none"
                    }}
                  >
                    <Button
                      id="explod_sub"
                      variant="contained"
                      size="small"
                      sx={{ mr: 1 }}
                      disabled={isPartExpanded}
                      onClick={() => {
                        setPartExpanded(true);
                        if (searchTerm) {
                          setSearchTerm("");
                        }
                      }}
                    >
                      Explode Part
                    </Button>
                    <Button
                      id="assemble_sub"
                      variant="contained"
                      size="small"
                      disabled={!isPartExpanded}
                      onClick={() => {
                        setPartExpanded(false);
                        setSearchTerm("");
                      }}
                    >
                      Assemble Part
                    </Button>
                  </div>
                </div>
                <div style={{ maxHeight: "60vh", overflow: "auto" }}>
                  <Grid container>
                    <Grid item md={12} xs={12}>
                      <div
                        className="parts-con"
                        style={{
                          display:
                            isExpanded && !isPartExpanded ? "block" : "none"
                        }}
                      >
                        <div id="main-parts">
                          {filteredParentList.map(mainPart => {
                            const isActive = mainPart.id === selectedPartId;
                            return (
                              <li
                                className={
                                  isActive
                                    ? "list-item active-item part"
                                    : "list-item part"
                                }
                                id={mainPart.id}
                                key={mainPart.id}
                                onClick={() => {
                                  setSelectedPartId(mainPart.id);
                                  setSelectedChildPartId("");
                                  setPartExpanded(false);
                                }}
                                title={mainPart.id}
                              >
                                {mainPart.id} - {mainPart.label}
                                {isActive && (
                                  <span title="Add to cart">
                                    <IconButton
                                      onClick={e => {
                                        partDetailsByPartNo(mainPart, "parent");
                                      }}
                                      size="small"
                                      className="si-hide-mobile"
                                    >
                                      <ShoppingCartIcon
                                        fontSize="small"
                                        color="light"
                                      />
                                    </IconButton>
                                  </span>
                                )}
                              </li>
                            );
                          })}
                        </div>
                      </div>
                    </Grid>

                    <Grid
                      item
                      md={12}
                      style={{ display: isExpanded ? "block" : "none" }}
                      xs={12}
                    >
                      <div className="grid-item">
                        <div
                          className="sub-part-con"
                          style={{ display: isPartExpanded ? "block" : "none" }}
                        >
                          <div id="child-parts">
                            {filteredChildList.map(childPart => {
                              const isActive =
                                (selectedChildPartId &&
                                  childPart.partNo.includes(
                                    selectedChildPartId
                                  )) ||
                                false;
                              if (isActive) {
                                movedToView(childPart.partNo);
                              }
                              return (
                                <div
                                  id={childPart.partNo}
                                  key={childPart.partNo}
                                  className={
                                    childPart.parent === selectedPartId
                                      ? isActive
                                        ? "display-part part list-item active-item"
                                        : "display-part part"
                                      : "hidden-part part"
                                  }
                                  onClick={e => {
                                    setSelectedChildPartId(childPart?.partNo);
                                  }}
                                  title={removeUnderscoreAndAfter(
                                    childPart.partNo
                                  )}
                                >
                                  {removeUnderscoreAndAfter(childPart.partNo)} -{" "}
                                  {convertToCamelCaseFromUnderScore(
                                    childPart.label
                                  )}
                                  {isActive && (
                                    <span title="Add to cart">
                                      <IconButton
                                        onClick={e => {
                                          partDetailsByPartNo(
                                            childPart,
                                            "child"
                                          );
                                        }}
                                        size="small"
                                        className="si-hide-mobile"
                                      >
                                        <ShoppingCartIcon
                                          fontSize="small"
                                          color="light"
                                        />
                                      </IconButton>
                                    </span>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PartsDetailView;
