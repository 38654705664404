import "./index.scss";
export const DownloadBtn = ({ onClick, className }) => {
  return (
    <div className={`download-btn-cmp ${className ? className : ""}`}>
      <img
        onClick={onClick}
        src="https://idev.blob.core.windows.net/technetv2/main-download-icon.png" // Replace with your SVG URL
        alt="Icon"
      />
    </div>
  );
};
