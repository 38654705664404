import React, { useEffect, useState } from "react";
import "./index.scss";
import { IconButton } from "@mui/material";
import ReactImageZoom from "react-image-zoom";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const smallImage = {
  width: 500,
  height: 840,
};

const largeImage = {
  width: 900,
  height: 900,
};

const props = { width: 400, height: 250, zoomWidth: 500, img: "1.jpg" };

const PhotoViewer = ({
  labels,
  photos,
  clsName,
  enableZoom,
  smallImageConfig,
}) => {
  const [selectedPhoto, setSelectedPhoto] = useState({});
  const [startIndex, setStartIndex] = useState(0);

  useEffect(() => {
    if (photos && photos.length > 0) {
      setSelectedPhoto(photos[0]);
    } else {
      setSelectedPhoto({});
    }
  }, [photos]);

  const openImage = (url) => {
    window.open(url, "_blank");
  };

  const onLeft = () => {
    const newStartIndex = startIndex - 1;
    setStartIndex(newStartIndex);
    setSelectedPhoto(photos[newStartIndex]);
  };
  const onRight = () => {
    const newStartIndex = startIndex + 1;
    setStartIndex(newStartIndex);
    setSelectedPhoto(photos[newStartIndex]);
  };

  const getDisplayPhotos = () => {
    if (photos.length > 5) {
      return photos.slice(startIndex, startIndex + 5);
    }
    return photos;
  };

  return (
    <div className={`photo-viewer-container ${clsName}`}>
      {selectedPhoto.imageSrc && (
        <div className="photo-preview">
          <div
            className="photo-preview-body"
            // onClick={() => openImage(selectedPhoto.imageSrc)}
          >
            <Zoom>
              <img
                alt="Product image"
                src={selectedPhoto.imageSrc}
                width="100%"
                style={{ objectFit: "contain" }}
              />
            </Zoom>
          </div>
        </div>
      )}
      {photos.length > 0 ? (
        <div className="photo-list">
          <div>
            {photos.length > 5 && (
              <IconButton onClick={onLeft} disabled={startIndex === 0}>
                <ArrowLeft fontSize="large" />
              </IconButton>
            )}
          </div>
          {getDisplayPhotos().map((photo) => {
            return (
              <img
                src={photo.imageSrc}
                className={
                  selectedPhoto.id === photo.id ? "active list-img" : "list-img"
                }
                onClick={() => {
                  setSelectedPhoto(photo);
                }}
              />
            );
          })}
          <div>
            {photos.length > 5 && (
              <IconButton
                onClick={onRight}
                disabled={startIndex + 5 === photos.length}
              >
                <ArrowRight fontSize="large" />
              </IconButton>
            )}
          </div>
        </div>
      ) : (
        <div className="no-image">Images Not Found </div>
      )}
    </div>
  );
};

export default PhotoViewer;
