import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@mui/material";

const ChangeTypes = [
  { label: "Feature", value: "FEATURE" },
  { label: "Bug Fix", value: "BUGFIX" },
  { label: "Improvement", value: "IMPROVEMENT" },
];

const ChangesSection = ({ changes, setChanges }) => {
  const [selectedChanges, setSelectedChanges] = useState({});
  const [descriptions, setDescriptions] = useState({});

  useEffect(() => {
    const initialSelectedChanges = {};
    const initialDescriptions = {};

    changes.forEach((change) => {
      initialSelectedChanges[change.type] = true; // Mark the checkbox as selected
      initialDescriptions[change.type] = change.description; // Set the description
    });

    setSelectedChanges(initialSelectedChanges);
    setDescriptions(initialDescriptions);
  }, [changes]);

  const handleCheckboxChange = (type) => {
    const isChecked = !selectedChanges[type];
    const updatedSelectedChanges = {
      ...selectedChanges,
      [type]: isChecked,
    };

    setSelectedChanges(updatedSelectedChanges);

    // Update the changes array based on the checkbox selection
    if (isChecked) {
      // Checkbox checked: add to changes
      setChanges((prev) => [
        ...prev,
        { type, description: descriptions[type] || "" },
      ]);
    } else {
      // Checkbox unchecked: remove from changes
      setChanges((prev) => prev.filter((change) => change.type !== type));
    }
  };

  const handleDescriptionChange = (type, value) => {
    const updatedDescriptions = {
      ...descriptions,
      [type]: value,
    };

    setDescriptions(updatedDescriptions);

    // Update the changes array with the new description
    setChanges((prev) => {
      const updatedChanges = prev.map((change) => {
        if (change.type === type) {
          return { ...change, description: value };
        }
        return change;
      });
      return updatedChanges;
    });
  };

  return (
    <Box sx={{ mb: 2 }}>
      {ChangeTypes.map((type) => (
        <Box
          key={type.value}
          sx={{ display: "flex", alignItems: "center", mb: 2 }}
        >
          <Grid container spacing={1}>
            <Grid item md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!selectedChanges[type.value]}
                    onChange={() => handleCheckboxChange(type.value)}
                  />
                }
                label={type.label}
              />
            </Grid>
            <Grid item md={8}>
              <TextField
                label={`Description for ${type.label}`}
                value={descriptions[type.value] || ""}
                onChange={(e) =>
                  handleDescriptionChange(type.value, e.target.value)
                }
                fullWidth
                size="small"
                multiline
                minRows={2}
                disabled={!selectedChanges[type.value]} // Disable input if checkbox is not checked
              />
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default ChangesSection;
