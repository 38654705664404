import { USER_ROLES } from "../../utils/constants";
import { getOfflineData } from "../../utils/offline-services";
import HomeIcon from "@mui/icons-material/Home";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import VideoChatIcon from "@mui/icons-material/VideoChat";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import GroupIcon from "@mui/icons-material/Group";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import InfoIcon from "@mui/icons-material/Info";
import SupportIcon from "@mui/icons-material/Support";
const defaultTabs = [
  {
    label: "Home",
    url: "/",
    icon: <HomeIcon fontSize="small" sx={{ mr: 1 }} />,
  },

  // {
  //   label: "Service Manual",
  //   url: "/service-manual",
  //   icon: <VideoChatIcon fontSize="small" sx={{ mr: 1 }} />,
  // },
  // {
  //   label: "Profile",
  //   icon: <InfoIcon sx={{ mr: 1 }} />,
  //   submenu: [
  //     { label: "About Us", url: "/about-us" },
  //     { label: "History", url: "/history" },
  //     { label: "Info For Shareholder", url: "/info-for-shareholder" },
  //     {
  //       label: "Milestones",
  //       url: "/milestones"
  //     },
  //     {
  //       label: "Leadership",
  //       url: "/leadership"
  //     }
  //   ]
  // },

  // {
  //   label: "Facilities",
  //   icon: <InfoIcon sx={{ mr: 1 }} />,
  //   submenu: [
  //     { label: "Manufacturing", url: "/manufacturing" },

  //     { label: "Quality", url: "/quality" },
  //     { label: "Research & Development", url: "/research-and-development" },
  //     { label: "Others", url: "/others" }
  //   ]
  // },

  // {
  //   label: "CSR",
  //   icon: <InfoIcon sx={{ mr: 1 }} />,
  //   submenu: [
  //     {
  //       label: "Corporate Social Responsibility",
  //       url: "/cop-social-responsibility"
  //     },
  //     {
  //       label: "Social Responsibility",
  //       url: "/social-responsibility"
  //     },
  //     {
  //       label: "Safety",
  //       url: "/safety"
  //     }
  //   ]
  // },
  {
    label: "Support",
    icon: <SupportIcon sx={{ mr: 1 }} />,
    submenu: [
      { label: "Knowledge Base", url: "/knowledge-base" },
      { label: "Release Notes", url: "/release-notes" },
      { label: "FAQ", url: "/faq" },
    ],
  },
];
const adminTabs = [
  {
    label: "Users",
    url: "/users",
    icon: <ViewModuleIcon fontSize="small" sx={{ mr: 1 }} />,
  },
  {
    label: "Orders",
    url: "/orders",
    icon: <ViewModuleIcon fontSize="small" sx={{ mr: 1 }} />,
  },

  {
    label: "Knowledge Base",
    url: "/a-knowledge-base",
    icon: <ViewModuleIcon fontSize="small" sx={{ mr: 1 }} />,
  },
  {
    label: "Release Notes",
    url: "/a-release-notes",
    icon: <ViewModuleIcon fontSize="small" sx={{ mr: 1 }} />,
  },
  {
    label: "News",
    url: "/a-news",
    icon: <ViewModuleIcon fontSize="small" sx={{ mr: 1 }} />,
  },
];
const userTabs = [
  ...defaultTabs,
  {
    label: "Visualize",
    url: "/visualize-products",
    icon: <VisibilityIcon fontSize="small" sx={{ mr: 1 }} />,
  },
  {
    label: "Buy Parts",
    url: "/buy-parts",
    activeRoutes: ["buy-parts", "explore-items", "item-details"],
    icon: <Inventory2Icon fontSize="small" sx={{ mr: 1 }} />,
  },
  // {
  //   label: "Service Manual",
  //   url: "/service-manual",
  //   icon: <VideoChatIcon fontSize="small" sx={{ mr: 1 }} />,
  //   activeRoutes: ["view-manual"]
  // },
  {
    label: "Cart",
    url: "/my-cart",
    icon: <ShoppingCartIcon fontSize="small" sx={{ mr: 1 }} />,
  },
  {
    label: "My Orders",
    url: "/my-orders",
    icon: <ShoppingCartIcon fontSize="small" sx={{ mr: 1 }} />,
  },
];
export const getNavList = () => {
  const { role } = getOfflineData("user");
  switch (role) {
    case USER_ROLES.ADMIN:
      return adminTabs;
    case USER_ROLES.USER:
      return userTabs;
    default:
      return [];
  }
};
