import React from "react";
import "./index.scss";
import { Grid, Typography } from "@mui/material";

import Footer from "../../component/footer";
import SideImgGallery from "./side-img-gallery";
const History = () => {
  return (
    <div className="page-load">
      <div className="about-content drop-in">
        <Grid container md={12}>
          <Grid item md={10} sx={{ pr: { md: 10, xs: 0, sm: 0 } }}>
            <Typography
              variant="h4"
              sx={{
                borderBottom: "1px solid #ded8d7",
                paddingBottom: 2,
                fontSize: { md: "2rem", xs: "1.5rem" },
                mb: 3,
              }}
              className="primary-text"
            >
              History
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Simpson & Co was founded in 1840 by Mr A.M. Simpson & Mr Hide to
              engage in a business in “coach-building, harness and boot making”
              and emerged as the leading coach manufacturing outfit in India. It
              had the honour of being appointed as the Coach Manufacturer to the
              Prince of Wales in 1876.
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              In the first decade of the 20th century, Simpson entered the
              “motor car business”, by building bodies on car chassis for
              manufacturers such as Rolls Royce, Hotchkiss and Napier. This led
              to body building for passenger and public utility vehicles such as
              buses and ambulances. This business grew to such an extent that
              the main business of Simpson & Co at one point was the building of
              bus bodies, tractors, and bowsers and articulated tank trailers.
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Ushering in the diesel era in India in the year 1951, through an
              agreement with Perkins Ltd., Peterborough, UK, Simpsons became
              dealers for sales and distribution of Perkins diesel engines. In
              1955, Simpsons was the first Indian company to indigenously
              manufacture diesel engines for surface transport vehicles.
            </Typography>
            <Typography variant="h6">
              Simpson & Co has many ‘firsts’ to its credit:
            </Typography>
            <ul>
              <li>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  The first hydraulic operated passenger ladder built for Air
                  India in 1953
                </Typography>
              </li>
              <li>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  The first sandwich type dumper body and cab for Leyland Comet
                </Typography>
              </li>
              <li>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  All metal luxury bus body for Leyland in 1958
                </Typography>
              </li>
              <li>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Air Conditioned coach body in collaboration with Leyland
                </Typography>
              </li>
              <li>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  The first company to indigenously manufacture diesel engines
                  in India
                </Typography>
              </li>
              <li>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  The first company to revolutionize farm mechanisation in India
                </Typography>
              </li>
            </ul>
            <Typography variant="body2" sx={{ mt: 5 }}>
              Today leading OE manufacturers in India and abroad are integrating
              Simpsons'engine with their equipment to stay ahead of competition.
            </Typography>
          </Grid>
          <Grid md={2} item>
            <SideImgGallery />
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default History;
