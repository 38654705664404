import * as React from "react";
import { Button, IconButton, Typography, Card, Grid, Box } from "@mui/material";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOutline } from "@mui/icons-material";
import ItemQty from "../../component/item-qty";
import { addItem } from "../../slice/cart-slice";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import OrderConfirmed from "./order-confirm";
import Footer from "../../component/footer";
import { convertToCamelCaseFromUnderScore } from "../../utils/helper";
import EMPTY_CART_IMG from "../../resources/images/empty-cart.png";
import RecommandedProducts from "../item-details/recom-products";
import { toast } from "react-toastify";
import MobileMyCart from "./mobile-my-cart";
const MyCart = () => {
  const dispatch = useDispatch();
  const [orderDetails, setOrderDetails] = React.useState(null);
  const [itemsTotalAmount, setItemsTotalAmount] = React.useState(0);

  const cartDetails = useSelector(
    (state) => (state.user && state.cart.items) || []
  );

  const onDelete = (item) => {
    updateCart(item);
  };
  const updateCart = (id, createOrder = false) => {
    const partList = cartDetails
      .map((ele) => {
        if (ele?.partId.id !== id) {
          return {
            partId: ele?.partId?.id,
            quantity: ele.quantity,
          };
        }
      })
      .filter((ele) => ele !== undefined);

    let payload = {
      cartItems: partList,
    };
    invokeApi(HTTP_METHODS.PUT, `${HOSTNAME}${REST_URLS.CART}`, payload)
      .then((response) => {
        if (response.message) {
          toast.error(response.message, { autoClose: 2000 });
        } else {
          if (createOrder) {
            onCreateOrder();
          }
          getMyCart();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onCreateOrder = () => {
    invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.ORDER}`)
      .then((response) => {
        if (response.message) {
          toast.error(response.message, { autoClose: 2000 });
        } else {
          setOrderDetails(response);
          getMyCart();
        }
      })
      .catch((err) => {});
  };
  const getMyCart = () => {
    invokeApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.CART}`)
      .then((response) => {
        const { cartItems = [] } = response;
        dispatch(addItem(cartItems));
      })
      .catch(() => {});
  };
  React.useEffect(() => {
    const totalPrice = cartDetails.reduce((total, item) => {
      const { partId, quantity } = item;
      const itemTotal = partId.unitPrice * quantity;
      return total + itemTotal;
    }, 0);

    setItemsTotalAmount(totalPrice);
  }, [cartDetails]);

  React.useEffect(() => {
    getMyCart();
  }, []);
  if (orderDetails) {
    return <OrderConfirmed orderDetails={orderDetails} />;
  }

  return (
    <div className="page-load">
      <div className="si-hide-mobile">
        <Grid container md={12} sx={{ padding: "20px" }} spacing={2}>
          <Grid item md={8}>
            <Card
              sx={{
                maxHeight: "1000px",
                overflow: "auto",
                borderRadius: "20px",
              }}
            >
              <div className="cart-con">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography variant="h5" sx={{ p: 2 }}>
                    Your Product List
                  </Typography>
                </div>
                <div>
                  {cartDetails.length > 0 ? (
                    <div>
                      {cartDetails.map((item) => {
                        const { partId = {} } = item;
                        return (
                          <div>
                            <Grid
                              container
                              md={11.5}
                              sx={{
                                m: 2,
                                mb: 8,
                                pb: 4,
                                borderBottom: "1px solid #dce0de",
                              }}
                            >
                              <Grid item md={4} sx={{ mr: 3 }}>
                                <div>
                                  <img
                                    src={partId.img}
                                    className="cart-img"
                                    alt="part-img"
                                  />
                                </div>
                              </Grid>
                              <Grid item md={7.5}>
                                <div className="cart-items-detail-con">
                                  <div style={{ width: "100%" }}>
                                    <Typography variant="h5" sx={{ mb: 1 }}>
                                      {convertToCamelCaseFromUnderScore(
                                        partId.label
                                      )}
                                    </Typography>
                                    <Typography variant="body1" color="gray">
                                      Model No.: {partId?.modelNumber}
                                    </Typography>
                                    <Typography variant="body1" color="gray">
                                      Part No.: {partId?.partNo}
                                    </Typography>
                                    <Typography variant="body1" color="gray">
                                      Serial No.: {partId?.serialNumber}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        alignItems: "center",
                                        display: "flex",
                                        mt: 6.5,
                                      }}
                                      color="gray"
                                    >
                                      <IconButton
                                        onClick={() => onDelete(partId.id)}
                                      >
                                        <DeleteOutline />
                                      </IconButton>
                                      Remove
                                    </Typography>
                                  </div>

                                  <div className="item-toal-price-con">
                                    <ItemQty
                                      onAdd={() => {
                                        const updatedList = cartDetails.map(
                                          (ele) => {
                                            if (
                                              ele.partId.id === item.partId.id
                                            ) {
                                              return {
                                                ...ele,
                                                quantity: item.quantity + 1,
                                              };
                                            } else {
                                              return ele;
                                            }
                                          }
                                        );
                                        dispatch(addItem(updatedList));
                                      }}
                                      onSubtract={() => {
                                        const updatedList = cartDetails
                                          .map((ele) => {
                                            if (
                                              ele.partId.id === item.partId.id
                                            ) {
                                              if (item.quantity - 1 < 1) {
                                                onDelete(partId.id);
                                              } else {
                                                return {
                                                  ...ele,
                                                  quantity: item.quantity - 1,
                                                };
                                              }
                                            } else {
                                              return ele;
                                            }
                                          })
                                          .filter((ele) => ele && ele);
                                        dispatch(addItem(updatedList));
                                      }}
                                      qty={item.quantity || 1}
                                      maxOrderQuantity={
                                        partId.maxOrderQuantity || 1
                                      }
                                    />
                                    {/* <Typography variant="h6">
                                      Total Price : &#x20B9;
                                      {(
                                        partId.unitPrice * item.quantity
                                      )?.toFixed(2)}
                                    </Typography> */}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={EMPTY_CART_IMG}
                        alt="empty-cart"
                        width="300px"
                      />
                      <Typography
                        variant="h6"
                        sx={{ mb: 2, color: "red", textAlign: "center" }}
                      >
                        No Item Added in Cart
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          </Grid>
          <Grid item md={4}>
            {cartDetails.length > 0 && (
              <Card
                sx={{
                  borderRadius: "15px",
                }}
              >
                <div className="shipping-order-con">
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ borderRadius: "15px", padding: "10px", mt: 3 }}
                    onClick={() => updateCart(null, true)}
                  >
                    Send Enquiry
                  </Button>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "center", mt: 2, color: "gray" }}
                  >
                    Submit your enquiry, and our team will get back to you as
                    soon as possible
                  </Typography>
                </div>

                {/* <div className="shipping-order-con">
                  <Typography variant="h5" sx={{ fontWeight: 500 }}>
                    Detail Summary
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 30,
                      borderBottom: "1px solid #dce0de",
                      paddingBottom: 20,
                    }}
                  >
                    <div>
                      <Typography variant="h6" color="GrayText" sx={{ mb: 1 }}>
                        Total Products
                      </Typography>
                      <Typography variant="h6" color="GrayText" sx={{ mb: 1 }}>
                        Price Delivery
                      </Typography>
                      <Typography variant="h6" color="GrayText" sx={{ mb: 1 }}>
                        Discount
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        &#x20B9;{itemsTotalAmount?.toFixed(2)}
                      </Typography>
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        Free
                      </Typography>
                      <Typography variant="h6" sx={{ mb: 1 }}>
                        &#x20B9;0.00
                      </Typography>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: "20px",
                      paddingBottom: "20px",
                    }}
                  >
                    <Typography variant="h6" sx={{ mb: 1 }} color="gray">
                      Total (VAT Included)
                    </Typography>
                    <Typography variant="h6" sx={{ mb: 1 }}>
                      &#x20B9;{itemsTotalAmount?.toFixed(2)}
                    </Typography>
                  </div>
                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ borderRadius: "15px", padding: "10px", mt: 3 }}
                    onClick={() => updateCart(null, true)}
                  >
                    Place Order
                  </Button>
                </div> */}
              </Card>
            )}
          </Grid>
        </Grid>
        {/* <Box sx={{ ml: 2 }}>
          {cartDetails.length > 0 && <RecommandedProducts />}
        </Box> */}
      </div>
      <div className="si-hide-web">
        <MobileMyCart
          cart={cartDetails}
          updateCart={updateCart}
          itemsTotalAmount={itemsTotalAmount}
        />
      </div>
      <Footer />
    </div>
  );
};
export default MyCart;
