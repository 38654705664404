import React, { useEffect, useState } from "react";
import "./index.scss";
import Footer from "../../component/footer";
import { Grid, TextField, Typography } from "@mui/material";
import { HTTP_METHODS, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import moment from "moment";

const ViewVideo = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [selectedResult, setSelectedResult] = useState([]);
  const [manualLists, setManualLists] = useState([]);
  const [activeVideo, setActiveVideo] = useState({});
  const [searhTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    page: 1,
    limit: 100,
    sortBy: "-createdAt",
  });

  const { id } = useParams();
  const getManuals = () => {
    plainApi(
      HTTP_METHODS.GET,
      `${HOSTNAME}${REST_URLS.MANUAL}`,
      null,
      filters
    ).then((res) => {
      if (res.message) {
        toast.error(res.message, { autoClose: 2000 });
      } else {
        setManualLists(res);
      }
    });
  };
  const selectSearchHandler = (res) => {
    const filterPart = searchResults.filter((ele) => ele.id === res.id);
    setSelectedResult(filterPart);
    setSearchTerm(res.name);
    setSearchResults([]);
  };
  const getSearchParts = (searchTerm) => {
    let timeoutId = null;
    clearTimeout(timeoutId);
    setTimeout(() => {
      plainApi(
        HTTP_METHODS.GET,
        `${HOSTNAME}${REST_URLS.SEARCH_MANUALS}`,
        null,
        {
          searchInput: searchTerm,
        }
      ).then((res) => {
        if (!res.message) {
          const partList = res?.map((ele) => {
            return {
              name: ele?.name,
              id: ele?.id,
              key: ele?.id,
              ...ele,
            };
          });

          setSearchResults(partList);
        }
      });
    }, 300);
  };
  const searchInputChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value) {
      if (value.trim() && !value.includes(",")) {
        getSearchParts(value);
      }
    } else {
      setSearchResults([]);
      setSelectedResult([]);
    }
  };
  const getVideoById = () => {
    if (id) {
      plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.MANUAL}/${id}`)
        .then((res) => {
          if (res.message) {
            toast.error(res.message, { autoClose: 2000 });
          } else {
            setActiveVideo(res);
          }
        })
        .catch((err) => console.error(err));
    }
  };
  const handleClick = (data) => {
    setActiveVideo(data);
    navigate(`/view-manual/${data?.id}`);
  };
  const VideoCard = ({ data }) => {
    return (
      <div>
        <div className="small-video-con" onClick={() => handleClick(data)}>
          <video
            src={data?.videoURL}
            width="100%"
            poster={data?.thumbnail}
            controlsList="nodownload"
            disablePictureInPicture
            onContextMenu={(e) => e.preventDefault()}
            className={
              activeVideo?.id === data?.id
                ? " preview-video small-active-video"
                : "preview-video"
            }
          ></video>
          <div>
            <p className="small-video-title">{data?.name}</p>
            <p className="video-desc">
              {data?.description && data.description.length > 65
                ? `${data.description.slice(0, 65)}...`
                : data.description}
            </p>
            <div className="video-desc video-created-time">
              {moment(data?.createdAt, "YYYYMMDD").fromNow()}
            </div>
          </div>
        </div>
      </div>
    );
  };
  useEffect(() => {
    getVideoById();
    getManuals();
  }, []);
  return (
    <div>
      <Grid
        md={12}
        container
        sx={{ margin: "auto", width: "90%", mt: { md: 3, xs: 0 } }}
      >
        <Grid item md={8} sx={{ display: "flex", alignItems: "center" }}>
          <div className="top-name-con">
            <ArrowBackIosIcon
              sx={{ cursor: "pointer", mr: 1 }}
              onClick={() => navigate("/service-manual")}
            />
            <Typography variant="h5">{activeVideo?.name}</Typography>
          </div>
        </Grid>
        <Grid item md={3.8}>
          <div>
            <p
              style={{ marginTop: "0px", marginBottom: "10px" }}
              className="si-hide-mobile"
            >
              OTHER MANUALS
            </p>
            <div>
              <TextField
                variant="outlined"
                value={searhTerm}
                onChange={searchInputChange}
                placeholder="Search Manuals by Name and Tags"
                fullWidth
                size="small"
                sx={{
                  backgroundColor: "white",
                  borderColor: "gray",
                  borderRadius: "15px",
                }}
                InputProps={{
                  endAdornment: searhTerm ? (
                    <CloseIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setSearchResults([]);
                        setSelectedResult([]);
                        setSearchTerm("");
                      }}
                    />
                  ) : null,
                }}
              />
              {searchResults.length ? (
                <Grid container className="manual-search-list-con" md={3.5}>
                  {searchResults.map((result) => (
                    <Grid item key={result.id} xs={12}>
                      <div
                        className="search-item"
                        onClick={() => {
                          selectSearchHandler(result);
                        }}
                      >
                        <Typography>{result.name}</Typography>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                ""
              )}
            </div>
          </div>
        </Grid>
      </Grid>

      <div className="manual-con">
        <Grid container md={12} spacing={3}>
          <Grid md={8} item>
            <div className="video-con">
              <video
                src={activeVideo?.videoURL}
                poster={activeVideo?.thumbnail}
                style={{
                  width: "100%",
                  maxHeight: "500px",
                  objectFit: "cover",
                }}
                controls
                autoPlay
                controlsList="nodownload"
                disablePictureInPicture
                onContextMenu={(e) => e.preventDefault()}
              ></video>
            </div>
            <p className="active-video-desc">{activeVideo?.description}</p>
            <div>{moment(activeVideo?.createdAt, "YYYYMMDD").fromNow()} </div>
          </Grid>
          <Grid md={4} item>
            <div>
              <div className="side-video-con">
                {selectedResult.length > 0
                  ? selectedResult?.map((ele) => {
                      return <VideoCard data={ele} />;
                    })
                  : manualLists?.results?.map((ele) => {
                      return <VideoCard data={ele} />;
                    })}
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </div>
  );
};

export default ViewVideo;
