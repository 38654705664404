import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Typography,
  Grid,
  styled,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import "./index.scss";
import { HTTP_METHODS, invokeApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import {
  setOfflineData,
  getOfflineData,
  clearOfflineData,
} from "../../utils/offline-services";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setUserDetails } from "../../slice/user-slice";
import Email from "../../resources/email.svg";
import Lock from "../../resources/lock.svg";
import { RemoveRedEye, VisibilityOff } from "@mui/icons-material";
import ENGINE_IMG from "../../resources/login-engine.png";

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#A0AAB4",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#B2BAC2",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#E0E3E7",
    },
    "&:hover fieldset": {
      borderColor: "#B2BAC2",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#6F7E8C",
      color: "#fff",
    },
  },
});
const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loginDetails, setLoginDetails] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const redirectRoute = getOfflineData("redirectRoute");
  useEffect(() => {
    if (getOfflineData("user")) {
      navigate("/");
    }
  }, []);
  const onImageError = (event) => {
    event.target.src = require("../../resources/images/engine.png");
  };
  const onInputChange = (event) => {
    setLoginDetails({
      ...loginDetails,
      [event.target.name]: event.target.value,
    });
  };

  const login = async () => {
    let payload = {
      email: loginDetails.email,
      password: loginDetails.password,
    };
    invokeApi(HTTP_METHODS.POST, `${HOSTNAME}${REST_URLS.LOGIN}`, payload).then(
      (response) => {
        if (response.message) {
          toast.error(response.message);
          setLoginDetails({
            ...loginDetails,
            errorMsg: response.message,
          });
        } else {
          response.user && setOfflineData("user", response.user);
          response.tokens && setOfflineData("tokens", response.tokens);
          dispatch(setUserDetails(response.user));
          if (redirectRoute && response.user?.role !== "admin") {
            navigate(redirectRoute);
          } else {
            if (response.user?.role === "admin") {
              navigate("/users");
            } else {
              navigate("/");
            }
          }
          clearOfflineData("redirectRoute");
        }
      }
    );
  };

  return (
    <div className="login-container oem-login-image">
      <div className="overlay"></div>
      <div className="app-logo-container si-hide-mobile">
        <img
          src={require("../../resources/log.png")}
          className="app-logo"
        />
        <Typography
              variant="h4"
              className="primary-text"
              sx={{
                mb: 2,
                // borderBottom: "1px solid #dbdbd9",
                paddingBottom: "0.5rem",
                paddingLeft: "1rem",
                fontSize: { md: "2.5rem", xs: "2rem" },
              }}
            >
              E-parts catalogue
            </Typography>
      </div>
      <div className="login-box">
        <div className="login-card-container">
          <div className="card-title">Login</div>
          <div className="login-form">
            <div>
              <Typography sx={{ paddingBottom: "5px" }}>Email</Typography>
              <CssTextField
                size="small"
                fullWidth
                name="email"
                value={loginDetails.email}
                onChange={onInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={Email} sx={{ color: "#fff" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div>
              <Typography sx={{ color: "#000", paddingBottom: "5px" }}>
                Password
              </Typography>
              <CssTextField
                size="small"
                name="password"
                value={loginDetails.password}
                type={showPassword ? "text" : "password"}
                onChange={onInputChange}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={Lock} sx={{ color: "#000" }} />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <VisibilityOff sx={{ color: "#000" }} />
                        ) : (
                          <RemoveRedEye sx={{ color: "#000" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            <Button
              variant="contained"
              size="large"
              onClick={login}
              sx={{ mt: 3 }}
            >
              Log In
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
