import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  Grid,
  TextField,
  Typography,
  IconButton,
  Modal,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { HTTP_METHODS, plainApi } from "../../utils/http-service";
import { HOSTNAME, REST_URLS } from "../../utils/endpoints";
import { usePromiseTracker } from "react-promise-tracker";
import CardSkeleton from "../../core/card-skeleton";
import Footer from "../../component/footer";
import { getOfflineData } from "../../utils/offline-services";
const KnowledgeBase = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [knowledgeFilteredList, setKnowledgeFilteredList] = useState([]);
  const [loadingApi, setLoadingApi] = useState(false);
  const [ogList, setOgList] = useState([]);
  const [description, setDescription] = useState({
    isOpen: false,
    data: {},
  });
  const getKnowledgeBaseList = () => {
    setLoadingApi(true);
    plainApi(HTTP_METHODS.GET, `${HOSTNAME}${REST_URLS.KNOWLEDGE_BASE}`, null, {
      limit: 1000,
      page: 1,
    })
      .then((res) => {
        setLoadingApi(false);
        if (!res.message) {
          // setKnowledgeFilteredList(res.results);
          setOgList(res.results);
        }
      })
      .catch((err) => console.log(err));
  };
  const openDescHandler = (item) => {
    setDescription({
      isOpen: true,
      data: item,
    });
  };
  const handleModalDescClose = () => {
    setDescription({
      isOpen: false,
      data: "",
    });
  };
  useEffect(() => {
    getKnowledgeBaseList();
  }, []);
  useEffect(() => {
    setKnowledgeFilteredList(
      ogList.filter((ele) =>
        ele?.topic?.toLowerCase()?.includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm, ogList]);

  return (
    <div className="knowledge-base page-load">
      <div className="search-con knowledge-search-con">
        <Typography
          variant="h4"
          sx={{
            mb: { md: 2, xs: 0 },
            fontSize: { md: "2.5rem", xs: "1rem" },
            color: "white",
            textAlign: "center",
          }}
        >
          We're here to help
        </Typography>
        <div className="know-search">
          <TextField
            fullWidth
            placeholder="Search our articles"
            variant="outlined"
            size="small"
            value={searchTerm}
            InputProps={{
              endAdornment: searchTerm && (
                <IconButton onClick={() => setSearchTerm("")}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              ),
              startAdornment: <SearchIcon sx={{ mr: 2 }} fontSize="small" />,
              sx: {
                borderRadius: "20px",
                mb: 1,
                background: "white",
                height: { xs: "30px", md: "2.5rem" },
              },
            }}
            onChange={(e) => {
              setSearchTerm(e?.target?.value);
            }}
          />
        </div>
      </div>
      <div>
        <Grid container spacing={{ md: 8, xs: 2 }} sx={{ p: { md: 8, xs: 1 } }}>
          {knowledgeFilteredList?.map((ele) => {
            return (
              <Grid
                item
                md={4}
                key={ele.id}
                onClick={() => openDescHandler(ele)}
                xs={6}
                sx={{ mb: { md: 0, xs: 2 } }}
              >
                <div className="knowledge-card">
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      fontWeight: { md: 600, xs: 500 },
                      fontSize: { md: "1.5rem", xs: "1rem" },
                      mb: 1,
                    }}
                    className="primary-text"
                  >
                    {ele?.topic}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "center", color: "gray" }}
                  >
                    {ele?.summary}
                  </Typography>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
      <CardSkeleton isLoadingApi={loadingApi} />

      <Modal open={description?.isOpen} onClose={handleModalDescClose}>
        <Box className="desc-modal ">
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
            }}
          >
            {description?.data?.topic}
          </Typography>
          <Typography
            variant="body1"
            sx={{ mt: 2, textAlign: "center", color: "gray" }}
          >
            {description?.data?.description}
          </Typography>
        </Box>
      </Modal>
      <Footer />
    </div>
  );
};

export default KnowledgeBase;
