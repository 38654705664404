import React, { useState, useEffect } from "react";
import "./index.scss";
import Footer from "../../component/footer";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
const faqData = [
  {
    question: "How do I place an order on your website?",
    answer: "To place an order on our website, follow these simple steps:",
    list: [
      "Browse our catalog and select the desired item.",
      "Click on the item to view detailed information.",
      "Choose the quantity and any applicable options (e.g., size, color).",
      'Click the "Add to Cart" button.',
      'Review your shopping cart and click "Proceed to Checkout" when ready.',
    ],
  },
  {
    question: "Do I need an account to place an order?",
    answer:
      "You can choose to either create an account or checkout as a guest. Creating an account allows you to track your orders and save your preferences for future purchases.",
  },
  {
    question: "How can I review my order before finalizing the purchase?",
    answer:
      "Before finalizing your purchase, you'll have the opportunity to review your order in the shopping cart. This includes checking the item quantity, applied discounts, and the total cost. You can make any necessary adjustments before proceeding to checkout.",
  },
  {
    question: "What payment methods do you accept?",
    answer:
      "We accept a variety of payment methods, including credit/debit cards and other secure payment options. Select your preferred payment method during the checkout process.",
  },
  {
    question: "Is it safe to enter my payment information on your website?",
    answer:
      "Yes, it's safe to enter your payment information on our website. We prioritize the security of our customers and use encryption technology to protect your sensitive data.",
  },
  {
    question: "Can I modify or cancel my order after it's been placed?",
    answer:
      "Once an order has been placed, it is processed quickly to ensure prompt delivery. Therefore, we recommend reviewing your order carefully before completing the purchase. Unfortunately, we cannot guarantee modifications or cancellations once the order is confirmed.",
  },
  {
    question: "How can I track my order?",
    answer:
      "After your order has been shipped, you will receive a confirmation email with a tracking number. You can use this tracking number to monitor the status and location of your package.",
  },
  {
    question: "What should I do if I encounter issues while placing an order?",
    answer:
      "If you encounter any issues during the ordering process, please contact our customer support team immediately. We're here to assist you and ensure a smooth purchasing experience.",
  },
  {
    question: " Do you offer international shipping?",
    answer:
      "Yes, we offer international shipping to selected countries. During checkout, provide your shipping address to see if international shipping is available for your location.",
  },
  {
    question: " How can I reach customer support for further assistance?",
    answer:
      "If you have any questions or need assistance, our customer support team is ready to help. Visit our Contact Us page for information on reaching out to our support team via email or phone.",
  },
];
const FAQ = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const [filteredList, setFilteredList] = useState(faqData);

  const toggleAnswer = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  useEffect(() => {
    setFilteredList(
      faqData.filter((ele) => ele?.question?.includes(searchTerm.toLowerCase()))
    );
  }, [searchTerm]);

  return (
    <div className="page-load">
      <div className="search-con knowledge-search-con">
        <Typography
          variant="h4"
          sx={{
            mb: { md: 2, xs: 1 },
            fontSize: { md: "2.5rem", xs: "1.5rem" },
            color: "white",
            textAlign: "center",
          }}
        >
          Frequently asked questions
        </Typography>
        <Typography sx={{ mb: 2, color: "#e8eaed", textAlign: "center" }}>
          Have Questions? We're here to help
        </Typography>
        <div className="faq-search">
          <TextField
            fullWidth
            placeholder="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            InputProps={{
              endAdornment: searchTerm && (
                <IconButton onClick={() => setSearchTerm("")}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              ),
              startAdornment: <SearchIcon sx={{ mr: 2 }} fontSize="small" />,
              sx: {
                borderRadius: "20px",
                mb: 1,
                background: "white",
                height: { xs: "30px", md: "2.5rem" },
              },
            }}
            sx={{ mt: 4, mb: 2 }}
            onChange={(e) => {
              setSearchTerm(e?.target?.value);
            }}
          />
        </div>
      </div>
      <div className="faq-con">
        {filteredList.map((item, index) => (
          <div key={index} className="faq-item">
            <div
              className={`question ${activeIndex === index ? "active" : ""}`}
              onClick={() => toggleAnswer(index)}
            >
              <p>{item.question}</p>
              {activeIndex === index ? (
                <RemoveIcon sx={{ mr: { md: 5, xs: 0 } }} />
              ) : (
                <AddIcon sx={{ mr: { md: 5, xs: 0 } }} />
              )}
            </div>

            {activeIndex === index && (
              <div className="ans-div">
                <p className="answer">{item.answer}</p>
                {item.list && (
                  <ul>
                    {item.list.map((ele) => {
                      return <li className="list-tem">{ele}</li>;
                    })}
                  </ul>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default FAQ;
