import * as React from "react";
import { Button, Typography } from "@mui/material";
import {
  createSearchParams,
  useNavigate,
  useSearchParams
} from "react-router-dom";
import {
  convertToCamelCaseFromUnderScore,
  removeUnderscoreAndAfter
} from "../../utils/helper";
import StarRatings from "react-star-ratings";

export default function MultiActionAreaCard({ details = {}, isSale = false }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const viewDetails = pathname => {
    navigate({
      pathname,
      search: createSearchParams({
        engine: searchParams.get("engine"),
        listNo: searchParams.get("listNo")
      }).toString()
    });
  };

  return (
    <div className="item-product-con">
      {details.img && <img src={details.img} className="sale-img" />}
      <div>
        <Typography variant="h6" sx={{ mt: 1 }}>
          {convertToCamelCaseFromUnderScore(details?.label)}
        </Typography>
        <Typography variant="body1" sx={{ color: "gray" }}>
          {details?.partNo || details?.mainPartId
            ? `${removeUnderscoreAndAfter(
                details?.partNo || details?.mainPartId
              )}  - ${details?.modelNumber}`
            : details?.modelNumber}
        </Typography>
      </div>

      <div>
        {/* <p>
          {details?.description && details.description.length > 100
            ? `${details.description.slice(1, 200)}....`
            : details.description}
        </p> */}
        {details.discount && (
          <div className="discount">Save({details.discount})</div>
        )}
        {/* <div>
          <StarRatings
            rating={details.rating}
            starRatedColor="#f0b81f"
            numberOfStars={5}
            name="rating"
            starDimension="25px"
            starSpacing="2px"
          />
          <sub>
            <Typography
              gutterBottom
              variant="body2"
              component="span"
              color="gray"
            >
              {"  "} Customer Rating
            </Typography>
          </sub>
        </div> */}
      </div>
      {/* <div className="product-prize-con">
        <Typography variant="h5" sx={{ display: "flex" }}>
          <sup>&#x20B9;</sup> {details.unitPrice}
        </Typography>
       
      </div> */}
      <div className="ic-footer">
        <Button
          variant="outlined"
          color="error"
          size="small"
          fullWidth
          onClick={() => viewDetails(`/item-details/${details.id}`)}
        >
          View Details
        </Button>
      </div>
    </div>
  );
}
